export function dateFormat(date, formatString) {
  const targetDate = new Date(date);
  const zf = str => {
    return String.prototype.padStart.call(str, 2, "0");
  };
  const hours = targetDate.getHours();
  return formatString
    .replace("YYYY", targetDate.getFullYear())
    .replace("yy", targetDate.getFullYear().toString().substr(-2))
    .replace("MM", zf(targetDate.getMonth() + 1))
    .replace("DD", zf(targetDate.getDate()))
    .replace("HH", zf(hours))
    .replace("hh", zf(hours > 12 ? hours - 12 : hours))
    .replace("mm", zf(targetDate.getMinutes()))
    .replace("ss", zf(targetDate.getSeconds()));
}

export const generateRandomString = (length = 4) => {
  let result = "";
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export default function objectComparer(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const sleep = ms => {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise(resolve => setTimeout(resolve, ms));
};

export function compareFunction(a, b, key, check = () => {}) {
  if (check) {
    if (check(a) && !check(b)) {
      return -1;
    }
    if (check(b) && !check(a)) {
      return 1;
    }
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return -1;
}

export function handleEnter(e, callback = () => {}) {
  const ENTER_KEY_CODE = 13;
  if (e.keyCode === ENTER_KEY_CODE) {
    callback();
  }
}

export function parsedMessage(body) {
  const bytes = new Uint8Array(body.binaryBody);
  const decoder = new TextDecoder("utf-8");
  const decoded = decoder.decode(bytes);
  const message = JSON.parse(decoded);
  return message;
}

export function swapPositions(arr, idx1, idx2) {
  [arr[idx1], arr[idx2]] = [arr[idx2], arr[idx1]];
}

export const generateRandomPossibility = () => {
  return generateRandomNumber(8.0, 13.0);
};

export const generateRandomNumber = (min, max) => {
  return Math.random() * (max - min) + min;
};
