const initialState = {
  auth: {},
};

export default {
  state: initialState,
  reducers: {
    setCarInfo(state, payload) {
      state.auth = payload;
    },
  },
  effects: {},
};
