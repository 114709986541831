/* eslint-disable class-methods-use-this */
import { engineClassName, noop, device } from "constants";
import AbstractEngine from "./abstractEngine";
import { splitBase64String, upload, uploadStatusTemp } from "utils/upload";
import { localInstance as apiClient } from "apiClient";

let instance;
class RealEngine extends AbstractEngine {
  constructor(...args) {
    super(...args, device.GPS);
    this.getCurrentPositionWithLocalGPS(position => {
      this.prevResult = position;
    });
    if (instance && instance.reader) {
      instance.reader.releaseLock();
    }
    this.timeOutCount = 0;
    instance = this;
  }

  // Override
  getName() {
    return engineClassName.real;
  }

  sendPreviousPosition(callback = noop) {
    callback(this.prevResult);
  }

  async openSerialPort() {
    const success = true;
    try {
      if (!("serial" in navigator)) {
        throw new Error("no port detected");
      }
      const ports = await navigator.serial.getPorts();
      if (ports.length === 0) {
        throw new Error("no port detected");
      }
      [this.port] = ports;
      await this.port.open({ baudRate: 115200 });
      // this.reader = this.port.readable.getReader();
      return success;
    } catch (e) {
      if (e.message.includes("already open")) {
        // this.reader = this.port.readable.getReader();
        return success;
      }
      throw new Error(e.message);
    }
  }

  // Override
  async getCurrentPosition(callback = noop, errCallback = noop) {
    try {
      const goAhead = await this.openSerialPort();
      if (goAhead) {
        const result = await this.getPositionFromRTK();
        if (result !== null) {
          this.device = device.RTK;
          callback(result);
        }
      }
    } catch (e) {
      if (e.message.includes("no port detected") || this.timeOutCount >= 5) {
        this.device = device.GPS;
        this.getCurrentPositionWithLocalGPS(callback, errCallback);
      } else {
        this.sendPreviousPosition(callback);
      }
    }
  }

  async getCurrentPositionFromRTK() {
    apiClient
      .get(`/api/v1/rtk`)
      .then(res => {
        if (res.status === 200) {
          let { data } = res;
          if (data.latitude === 0 || data.longitude === 0) {
            data = this.rtkPrevPosition;
          } else {
            this.rtkPrevPosition = data;
          }
          this.publishPosition(data);
        }
      })
      .catch(err => {
        // use local gps
        if (err.response.status === 404) {
          this.getCurrentPosition(position => {
            this.publishPosition(position);
          });
        }
        this.publishPosition(this.rtkPrevPosition);
      });
  }

  async startTimeOut() {
    const timer = await setTimeout(async () => {
      try {
        console.log("timeout");
        console.log("current reader: ", this.reader);
        this.timeOutCount += 1;
        if (this.reader) {
          console.log("cleanup reader");
          this.reader.releaseLock();
        }
        await this.port.close();
      } catch (e) {
        // if (!e.message.includes("already closed")) {
        throw new Error(e);
        // }
      }
    }, 1000);
    return timer;
  }

  resetTimeOut(timer) {
    clearTimeout(timer);
    this.timeOutCount = 0;
  }

  async getPositionFromRTK() {
    let searchInput = "";
    let remainder = "";
    let parsedResult;
    let found = false;
    let done = false;
    this.reader = this.port.readable.getReader();
    const decoder = new TextDecoder();
    const timer = await this.startTimeOut();
    // extract {latitude, longitude, speed, heading} from rtk
    try {
      while (!done) {
        // eslint-disable-next-line no-await-in-loop
        const { value } = await this.reader.read();
        const curLine = decoder.decode(value);
        searchInput = remainder + curLine;
        ({ parsedResult, found } = this.getParsedResult(searchInput));
        if (found) {
          this.resetTimeOut(timer);
          break;
        }
        remainder = searchInput;
        done = found;
      }
    } catch (error) {
      throw new Error(error.message);
    } finally {
      this.reader.releaseLock();
    }
    return parsedResult;
  }

  getParsedResult(searchInput) {
    let found = false;
    const startIndex = searchInput.indexOf("$GNRMC");
    const endIndex = searchInput.indexOf("*", startIndex);
    const serialResult = searchInput.substring(startIndex, endIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      found = true;
      const parsedResult = this.nmeaParser(serialResult);
      this.prevResult = parsedResult;
      return { parsedResult, found };
    }
    return { undefined, found };
  }

  nmeaParser(serialResult) {
    const temp = serialResult.split(",");
    const result = {
      latitude: Math.floor(temp[3] / 100) + (temp[3] % 100) / 60,
      longitude: Math.floor(temp[5] / 100) + (temp[5] % 100) / 60,
      speed: Math.floor(temp[7] * 1.8),
      heading: temp[8],
    };
    if (temp[3] === "") {
      result.latitude = this.prevResult ? this.prevResult.latitude : "";
    } else {
      result.latitude = Math.floor(temp[3] / 100) + (temp[3] % 100) / 60;
    }
    if (temp[5] === "") {
      result.longitude = this.prevResult ? this.prevResult.longitude : "";
    } else {
      result.longitude = Math.floor(temp[5] / 100) + (temp[5] % 100) / 60;
    }
    if (temp[8] === "") {
      // result.heading = (Date.now() % 4) * 90;
      result.heading = this.prevResult ? this.prevResult.heading : "";
    } else {
      result.heading = temp[8] * 1;
    }
    return result;
  }

  // Override
  notifyCurrentPosition(callback = noop, errCallback = noop) {
    this.getCurrentPosition(position => {
      // console.log(position);
      this.publishPosition(position);
    }, errCallback);
    callback(this.device);
    // this.getCurrentPositionFromRTK();
  }

  // Override
  async getCurrentImage(props = {}) {
    const snapshotData = props.data;
    if (!props.id) {
      // eslint-disable-next-line
      return () => { };
    }
    const total = snapshotData.length;

    // first upload
    const body = {
      subPath: `${props.id}.png`,
      total,
      data: "",
      dataLength: 0,
      status: uploadStatusTemp,
    };
    const { data: firstData } = await upload({ urlPath: props.urlPath, body });

    const status = firstData;
    let offset = 0;

    // chunk uploading
    splitBase64String(String(snapshotData)).map(async chunkData => {
      const uploadBody = {
        subPath: `${props.id}.png`,
        total,
        data: chunkData,
        dataLength: chunkData.length,
        status: {
          ...status,
          offset,
        },
      };
      offset += chunkData.length;
      await upload({
        urlPath: props.urlPath,
        body: uploadBody,
      });
      return null;
    });
    return null;
  }
}

export default RealEngine;
