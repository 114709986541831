import { useEffect, useState } from "react";
import { Box, TextField } from "@material-ui/core";
import {
  CheckCircle,
  Mouse,
  PriorityHigh,
  SwapVerticalCircle,
} from "@material-ui/icons";
import { green } from "@material-ui/core/colors";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "./CustomButton";
import { getLatLng } from "utils/naverMaps";
import { handleEnter } from "utils";

function removeBoldTags(str) {
  return str.replace(/<\/?b>/g, "");
}

function AddressTextField({
  classes,
  id,
  point,
  setPoint,
  errors,
  addGeocodeListener,
  handleUpdatePointByClick,
  searchAddress,
  handleChangeAddress,
  endAdornmentIcon: EndAdornmentIcon = SwapVerticalCircle,
  endAdornment,
  waypointActive = false,
  placeholder = "",
}) {
  const dispatch = useDispatch();
  const [addressList, setAddressList] = useState([]);
  const { vehicle } = useSelector(state => state.vehicle);
  const { paths, navigatingWaypoint } = useSelector(state => state.path);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({
    title: "",
    address: "",
  });

  const isNavigating = () => !!paths[vehicle.id];

  const onClickAddressList = (e, newValue) => {
    setIsSelected(true);
    if (!newValue) {
      return;
    }
    const latLng = getLatLng({ mapx: newValue.mapx, mapy: newValue.mapy });
    handleChangeAddress(
      id,
      {
        address: newValue.roadAddress || newValue.address,
        latitude: latLng.y,
        longitude: latLng.x,
        verified: true,
      },
      setPoint,
    );
    setAddressList([]);
    setSelectedAddress({
      title: removeBoldTags(newValue.title),
      address: newValue.roadAddress || newValue.address,
    });
  };

  const renderOption = options => (
    <Box>
      <Box>{removeBoldTags(options.title)}</Box>
      <Box>{options.roadAddress || options.address}</Box>
    </Box>
  );

  useEffect(() => {
    if (point.address !== selectedAddress.address) {
      setIsSelected(false);
    }
    const activeStatus =
      document.getElementById(`${id}`) === document.activeElement;
    if (!activeStatus) {
      return null;
    }
    const { address } = point;

    if (!address) {
      setAddressList([]);
      return null;
    }
    const timer = setTimeout(async () => {
      if (!point.verified) {
        const response = await dispatch.location.searchAddress(address);
        setAddressList(response.data.items);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [point.address]);

  return (
    <Autocomplete
      id={`${id}`}
      freeSolo
      options={addressList}
      getOptionLabel={option =>
        option?.roadAddress || option?.address || option
      }
      value={isSelected ? selectedAddress.title : point.address}
      onChange={onClickAddressList}
      renderOption={renderOption}
      renderInput={params => (
        <TextField
          {...params}
          error={errors[`${id}`].error}
          className="textInput"
          variant="outlined"
          placeholder={placeholder}
          InputLabelProps={{ shrink: !!point }}
          InputProps={{
            ...params.InputProps,
            endAdornment: null,
            style: { paddingRight: "9px" },
            ...((isNavigating() && id !== "waypoint") || {
              startAdornment: (
                <CustomButton
                  classes={classes.startInputs}
                  title="출발지 지도에서 직접 선택"
                  onClick={() =>
                    addGeocodeListener(handleUpdatePointByClick, `${id}`)
                  }
                >
                  <Mouse className="mouseButton" />
                </CustomButton>
              ),
            }),
            ...{
              endAdornment: (
                <>
                  {(isNavigating() && id !== "waypoint") ||
                    (point.verified ? (
                      <CustomButton title="검증 완료">
                        <CheckCircle
                          fontSize="small"
                          style={{ color: green[500] }}
                        />
                      </CustomButton>
                    ) : (
                      <CustomButton
                        title="주소 검증"
                        onClick={() => {
                          searchAddress(`${id}`, point);
                        }}
                      >
                        <PriorityHigh fontSize="small" color="secondary" />
                      </CustomButton>
                    ))}
                  {(isNavigating() &&
                    (id === "start" ||
                      (id === "waypoint" &&
                        navigatingWaypoint?.address !== "") ||
                      (id === "goal" && waypointActive))) || (
                    <CustomButton
                      title={endAdornment.comment}
                      onClick={endAdornment.function}
                    >
                      <EndAdornmentIcon className={endAdornment.style} />
                    </CustomButton>
                  )}
                </>
              ),
            },
          }}
          onChange={e =>
            handleChangeAddress(
              `${id}`,
              {
                address: e.target.value,
                latitude: null,
                longitude: null,
                verified: false,
              },
              setPoint,
            )
          }
          helperText={errors[`${id}`].label}
          onKeyDown={e =>
            handleEnter(e, () => {
              if (addressList.length > 0) {
                onClickAddressList(e, addressList[0]);
                e.target.blur();
                return;
              }
              searchAddress(`${id}`, point);
              e.target.blur();
            })
          }
          disabled={id !== "waypoint" && isNavigating()}
        />
      )}
    />
  );
}

export default AddressTextField;
