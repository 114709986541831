import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles(theme => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    paddingBottom: "2rem",
  },
}));

function BreadCrumbItem({ data }) {
  const { label, path, active } = data;
  if (active) {
    return <Typography color="textPrimary">{label}</Typography>;
  }
  return (
    <Link color="inherit" href={path}>
      {label}
    </Link>
  );
}

export default function BreadCrumbs({ items }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {items &&
          items.map(item => (
            <BreadCrumbItem key={`breadcrumb-item-${item.id}`} data={item} />
          ))}
      </Breadcrumbs>
    </div>
  );
}
