import { useEffect, useState } from "react";
import { snackbarOption } from "constants";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Box, Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import produce from "immer";
import SearchIcon from "@material-ui/icons/Search";
import { useNavigate } from "react-router-dom";
import { AlertDialog, HeaderTitle, StickyHeadTable } from "components";
import { vehicleColumns } from "utils/dataSetting";
import { handleEnter } from "utils";
import { isAdmin } from "utils/naverMaps";

const initVehicle = { id: "" };
function Register() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { vehicle, vehicles } = useSelector(state => state.vehicle);

  const [myVehicle, setMyVehicle] = useState(initVehicle);
  const [isClickRow, setIsClickRow] = useState(false);
  const [selectDialog, setSelectDialog] = useState(false);

  const handleChangeVehicle = (inputKey, inputValue) => {
    const changed = produce(myVehicle, draft => {
      draft[inputKey] = inputValue;
    });
    setMyVehicle(changed);
    setIsClickRow(false);
  };

  const handleRegisterVehicle = () => {
    const foundVehicle = vehicles.find(item => item.id === myVehicle.id);
    if (!foundVehicle) {
      enqueueSnackbar("차량명을 확인해주세요.", {
        variant: "error",
        ...snackbarOption,
      });
      return;
    }
    localStorage.setItem("myVehicleType", foundVehicle.type);
    localStorage.setItem("myVehicleId", myVehicle.id);
    dispatch.vehicle.getVehicle({
      vehicleId: myVehicle.id,
    });
  };

  const onClickRow = item => {
    setMyVehicle(item);
    setIsClickRow(true);
    setSelectDialog(true);
  };

  useEffect(() => {
    dispatch.vehicle.getVehicles();
  }, []);

  useEffect(() => {
    if (!vehicle.id) {
      const textArea = document.getElementById("myVehicle");
      textArea.focus();
    }
    if (vehicle.id) {
      if (isAdmin(vehicle.id)) {
        navigate("/accident");
      } else {
        navigate("/participant");
      }
    }
  }, [vehicle]);

  useEffect(() => {
    if (isClickRow) {
      return () => {};
    }
    const timer = setTimeout(() => {
      dispatch.vehicle.getVehicles({ search: myVehicle.id });
    }, 500);
    return () => clearTimeout(timer);
  }, [myVehicle, isClickRow]);

  return (
    <>
      <HeaderTitle>
        <Typography variant="h4">내 차량 선택</Typography>
      </HeaderTitle>
      <Box m={4}>
        <Grid container direction="row" className={classes.vehicleSearch}>
          <h3>차량 검색</h3>
          <Box className={classes.vehicleSearch}>
            <SearchIcon className={classes.searchIcon} />
            <TextField
              id="myVehicle"
              value={myVehicle.id}
              onChange={e => handleChangeVehicle("id", e.target.value)}
              onKeyDown={e => handleEnter(e, handleRegisterVehicle)}
              className="vehicle-number"
            />
          </Box>
        </Grid>
        <StickyHeadTable
          columns={[vehicleColumns[1], vehicleColumns[3]]}
          rows={vehicles}
          onClickRow={onClickRow}
        />
      </Box>
      <AlertDialog
        title="차량 선택"
        description={`${myVehicle.id} 을 선택하시겠습니까?`}
        onClose={() => {
          setSelectDialog(false);
        }}
        open={selectDialog}
        onAgree={handleRegisterVehicle}
      />
    </>
  );
}

const useStyles = makeStyles({
  vehicleNumber: {
    display: "flex",
    margin: "1.5rem",
    alignItems: "center",
    justifyContent: "right",
    "& #vehicle-label": {
      fontWeight: 600,
      marginRight: "1rem",
      minWidth: "7rem",
    },
    "& .vehicle-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .vehicle-number": {
      marginRight: "1rem",
    },
  },
  vehicleSearch: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  searchIcon: {
    fontSize: "medium",
    paddingTop: "10px",
    marginRight: "5px",
  },
});

export default Register;
