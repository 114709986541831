import { instance as apiClient } from "apiClient";

const initialState = {
  jurisdiction: null,
  jurisdictions: [],
  jurisdictionVehicles: [],
  jurisdictionAccidents: [],
};

export default {
  state: initialState,
  reducers: {
    setJurisdictions(state, payload) {
      state.jurisdictions = payload;
    },
    setJurisdiction(state, payload) {
      state.jurisdiction = payload;
    },
    setJurisdictionVehicles(state, payload) {
      state.jurisdictionVehicles = payload;
    },
    setJurisdictionAccidents(state, payload) {
      state.jurisdictionAccidents = payload;
    },
  },
  effects: () => ({
    async getJurisdictions() {
      const { data } = await apiClient.get(`/api/v1/jurisdictions`);
      this.setJurisdictions(data);
    },
    async createJurisdiction(payload) {
      try {
        const response = await apiClient.post(`/api/v1/jurisdictions`, payload);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async getJurisdiction(payload) {
      const { data } = await apiClient.get(`/api/v1/jurisdictions/${payload}`);
      this.setJurisdiction(data);
    },
    async updateJurisdiction(payload) {
      try {
        const response = await apiClient.put(`/api/v1/jurisdictions`, payload);
        this.setJurisdiction(response.data);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    // 관할서에 할당된 차량, 재난 조회 api 추가 시 변경
    getJurisdictionVehicles(payload) {
      this.setJurisdictionVehicles(payload);
    },
    getJurisdictionAccidents(payload) {
      this.setJurisdictionAccidents(payload);
    },
    async deleteJurisdiction(payload) {
      await apiClient.delete(`/api/v1/jurisdictions/${payload}`);
    },
  }),
};
