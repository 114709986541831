import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import JurisdictionDialog from "./JurisdictionDialog";
import { BreadCrumbs, StickyHeadTable } from "components";
import { jurisdictionColumns } from "utils/dataSetting";

const breadCrumbs = [
  {
    id: "jurisdiction",
    label: "관할서",
    path: "/admin/jurisdiction",
    active: false,
  },
];

function Jurisdiction() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jurisdictions } = useSelector(state => state.jurisdiction);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const onClickAdd = () => {
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const onClickInfo = jurisdiction => {
    navigate(`/admin/jurisdiction/info/${jurisdiction.id}`);
  };

  useEffect(() => {
    dispatch.jurisdiction.getJurisdictions();
  }, []);

  return (
    <Box>
      <BreadCrumbs items={breadCrumbs} />
      <Grid container>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Box m={1} pb={3}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <Typography variant="h5" gutterBottom>
                      관할서 목록
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={onClickAdd}
                        >
                          관할서 추가
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box m={1}>
                <StickyHeadTable
                  columns={jurisdictionColumns}
                  rows={jurisdictions}
                  onClickRow={onClickInfo}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <JurisdictionDialog open={isOpenDialog} onClose={handleCloseDialog} />
    </Box>
  );
}

export default Jurisdiction;
