/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-await-in-loop */
import React, { useEffect } from "react";
import { defaultPolylineStyle } from "constants";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import { AlertDialog } from "components";
import {
  getRediscoverWaypoints,
  checkOverlap,
  reverseGeocode,
  DrawPolylineList,
  getInfoWindow,
  removePathList,
  setCenterEffect,
  createWaypointMarker,
  deleteWaypointMarker,
} from "utils/naverMaps";
import { swapPositions } from "utils";
import { publish } from "utils/socket";

function RediscoverDialog({
  interruptedSection,
  interruptedSnackbarId,
  open,
  setOpen,
}) {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const {
    clearPath,
    setIsPathLoading,
    setStartPoint,
    setPathList,
    addPaths,
    removePaths,
  } = dispatch.path;
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { vehicle } = useSelector(state => state.vehicle);
  const { paths, goalPoint } = useSelector(state => state.path);
  const { accident } = useSelector(state => state.accident);
  const { blockers } = useSelector(state => state.blocker);
  const { map, markers } = useSelector(state => state.location);
  const { engine } = useSelector(state => state.engine);

  const handleUpdatePointByClick = response => {
    const payload = {
      address: response.result.items[0].address,
      latitude: response.latitude,
      longitude: response.longitude,
      verified: true,
    };
    setStartPoint(payload);
  };

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      engine.getCurrentPosition(
        position => {
          let currentPosition = position;
          if (currentPosition === null) {
            const myVehicleMarker = markers[vehicle.id];
            currentPosition = {
              latitude: myVehicleMarker.position.y,
              longitude: myVehicleMarker.position.x,
            };
          }
          reverseGeocode(
            currentPosition.latitude,
            currentPosition.longitude,
            handleUpdatePointByClick,
            "start",
          );
          resolve();
        },
        err => {
          reject(err);
        },
      );
    });
  };

  const createPathElement = (pathData, id, selected = false) => ({
    id,
    pathData,
    polylines: DrawPolylineList(pathData, map, defaultPolylineStyle),
    infoWindow: getInfoWindow(pathData, map, id + 1, selected),
  });

  const handleRediscoveryPath = async () => {
    setIsPathLoading(true);
    // 탐색된 경로 초기화
    clearPath();
    const snackbarId = enqueueSnackbar("우회경로 탐색중...", {
      variant: "info",
      // autoHideDuration: "6000",
      persist: true,
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
    getCurrentPosition()
      .then(() => {
        const myVehicleMarker = markers[vehicle.id];
        reverseGeocode(
          myVehicleMarker.position.y,
          myVehicleMarker.position.x,
          async response => {
            const currentPosition = {
              address: response.result.items[0].address,
              latitude: response.latitude,
              longitude: response.longitude,
            };
            await rediscoveryPath(
              interruptedSection.sectionStart,
              interruptedSection.sectionEnd,
              currentPosition,
            );
            // navigator("/directions");
            setIsPathLoading(false);
            closeSnackbar(snackbarId);
          },
        );
      })
      .catch(err => {
        console.log("rediscover", err);
        closeSnackbar(snackbarId);
        handleRediscoveryPath();
      });
  };

  const getRediscoverPayload = (startPosition, waypoint) => {
    return {
      id: vehicle.id,
      paths: { start: startPosition, goal: vehicle.accident.location },
      waypoint,
    };
  };

  const isPathOverlap = (pathData, blockerList) => {
    const pathCode = pathData.code;
    return (
      pathCode === 0 &&
      !blockerList.every(
        bk => checkOverlap(pathData.route.trafast[0].path, bk) === false,
      )
    );
  };

  const navStart = async (selectedPath, currentPosition) => {
    setStartPoint(currentPosition);
    addPaths({
      id: vehicle.id,
      paths: { pathData: selectedPath },
      start: currentPosition,
      goal: goalPoint,
    });
    // const marker = dispatch.location.getMarkerById({ id: vehicle.id });
    // setCenterEffect(map, marker);
    // dispatch.location.setFocusId({ id: vehicle.id });
    const stringPath = JSON.stringify({
      path: selectedPath.path,
      start: currentPosition,
      goal: goalPoint,
    });
    await dispatch.vehicle.updateVehicle({
      ...vehicle,
      path: stringPath,
    });
    publish(
      JSON.stringify(
        {
          type: "pathStart",
          topicId: accident.id,
          contents: "",
          sender: vehicle.id,
          createdAt: Date.now(),
        },
        "/pub/message",
      ),
    );
  };

  const retryAction = snackbarId => (
    <>
      <Button
        onClick={() => {
          closeSnackbar(snackbarId);
          handleRediscoveryPath();
        }}
      >
        재시도
      </Button>
      <Button
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        닫기
      </Button>
    </>
  );

  const rediscoveryPath = async (sectionStart, sectionEnd, currentPosition) => {
    let selectedPath = null;
    const newPathList = [];
    const pathIdx = 0;
    for (let i = 0; i < 20; i += 1) {
      console.log("rediscover try", i);
      const {
        waypoint1,
        waypoint2,
        waypoint3,
        waypoint4,
        waypoint5,
        waypoint6,
        waypoint7,
        waypoint8,
      } = getRediscoverWaypoints(sectionStart, sectionEnd, i + 1);

      // createWaypointMarker(map, waypoint1);
      // createWaypointMarker(map, waypoint2);
      // createWaypointMarker(map, waypoint3);
      // createWaypointMarker(map, waypoint4);
      // createWaypointMarker(map, waypoint5);
      // createWaypointMarker(map, waypoint6);
      // createWaypointMarker(map, waypoint7);
      // createWaypointMarker(map, waypoint8);

      const pay1 = getRediscoverPayload(currentPosition, waypoint1);
      const pay2 = getRediscoverPayload(currentPosition, waypoint2);
      const pay3 = getRediscoverPayload(currentPosition, waypoint3);
      const pay4 = getRediscoverPayload(currentPosition, waypoint4);
      const pay5 = getRediscoverPayload(currentPosition, waypoint5);
      const pay6 = getRediscoverPayload(currentPosition, waypoint6);
      const pay7 = getRediscoverPayload(currentPosition, waypoint7);
      const pay8 = getRediscoverPayload(currentPosition, waypoint8);

      const path1 = await dispatch.path.getPaths(pay1);
      const path2 = await dispatch.path.getPaths(pay2);
      const path3 = await dispatch.path.getPaths(pay3);
      const path4 = await dispatch.path.getPaths(pay4);
      const path5 = await dispatch.path.getPaths(pay5);
      const path6 = await dispatch.path.getPaths(pay6);
      const path7 = await dispatch.path.getPaths(pay7);
      const path8 = await dispatch.path.getPaths(pay8);

      const addedBlockers = blockers.map(ref => ({
        position: { x: ref.location.longitude, y: ref.location.latitude },
      }));

      const path1Check = isPathOverlap(path1, addedBlockers);
      const path2Check = isPathOverlap(path2, addedBlockers);
      const path3Check = isPathOverlap(path3, addedBlockers);
      const path4Check = isPathOverlap(path4, addedBlockers);
      const path5Check = isPathOverlap(path5, addedBlockers);
      const path6Check = isPathOverlap(path6, addedBlockers);
      const path7Check = isPathOverlap(path7, addedBlockers);
      const path8Check = isPathOverlap(path8, addedBlockers);

      if (
        !path1Check &&
        !path2Check &&
        !path3Check &&
        !path4Check &&
        !path5Check &&
        !path6Check &&
        !path7Check &&
        !path8Check
      ) {
        // eslint-disable-next-line no-continue
        continue;
      }

      let path1Duration = Number.MAX_SAFE_INTEGER;
      let path2Duration = Number.MAX_SAFE_INTEGER;
      let path3Duration = Number.MAX_SAFE_INTEGER;
      let path4Duration = Number.MAX_SAFE_INTEGER;
      let path5Duration = Number.MAX_SAFE_INTEGER;
      let path6Duration = Number.MAX_SAFE_INTEGER;
      let path7Duration = Number.MAX_SAFE_INTEGER;
      let path8Duration = Number.MAX_SAFE_INTEGER;

      if (path1.code === 0) {
        if (!path1Check) {
          path1Duration = path1.route.trafast[0].summary.duration;
        }
        // newPathList.push(createPathElement(path1.route.trafast[0], pathIdx));
        // pathIdx += 1;
      }
      if (path2.code === 0) {
        if (!path2Check) {
          path2Duration = path2.route.trafast[0].summary.duration;
        }
        // newPathList.push(createPathElement(path2.route.trafast[0], pathIdx));
        // pathIdx += 1;
      }
      if (path3.code === 0) {
        if (!path3Check) {
          path3Duration = path3.route.trafast[0].summary.duration;
        }
        // newPathList.push(createPathElement(path3.route.trafast[0], pathIdx));
        // pathIdx += 1;
      }
      if (path4.code === 0) {
        if (!path4Check) {
          path4Duration = path4.route.trafast[0].summary.duration;
        }
        // newPathList.push(createPathElement(path4.route.trafast[0], pathIdx));
        // pathIdx += 1;
      }
      if (path5.code === 0) {
        if (!path1Check) {
          path5Duration = path5.route.trafast[0].summary.duration;
        }
        // newPathList.push(createPathElement(path1.route.trafast[0], pathIdx));
        // pathIdx += 1;
      }
      if (path6.code === 0) {
        if (!path2Check) {
          path6Duration = path6.route.trafast[0].summary.duration;
        }
        // newPathList.push(createPathElement(path2.route.trafast[0], pathIdx));
        // pathIdx += 1;
      }
      if (path7.code === 0) {
        if (!path3Check) {
          path7Duration = path7.route.trafast[0].summary.duration;
        }
        // newPathList.push(createPathElement(path3.route.trafast[0], pathIdx));
        // pathIdx += 1;
      }
      if (path8.code === 0) {
        if (!path4Check) {
          path8Duration = path8.route.trafast[0].summary.duration;
        }
        // newPathList.push(createPathElement(path4.route.trafast[0], pathIdx));
        // pathIdx += 1;
      }
      if (
        path1?.code !== 2 &&
        !path1Check &&
        path1Duration < path5Duration &&
        path1Duration < path2Duration &&
        path1Duration < path3Duration &&
        path1Duration < path4Duration &&
        path1Duration < path6Duration &&
        path1Duration < path7Duration &&
        path1Duration < path8Duration
      ) {
        // setPath = path1
        // selectedPath = path1.route.trafast[0].path;
        [selectedPath] = path1.route.trafast;
        // swapPositions(newPathList, 0, i * 4);
        break;
      }
      if (
        path2?.code === 0 &&
        !path2Check &&
        path2Duration < path1Duration &&
        path2Duration < path5Duration &&
        path2Duration < path3Duration &&
        path2Duration < path4Duration &&
        path2Duration < path6Duration &&
        path2Duration < path7Duration &&
        path2Duration < path8Duration
      ) {
        // setPath = path2
        // selectedPath = path2.route.trafast[0].path;
        [selectedPath] = path2.route.trafast;
        // swapPositions(newPathList, 0, i * 4 + 1);
        break;
      } else if (
        path3?.code === 0 &&
        !path3Check &&
        path3Duration < path1Duration &&
        path3Duration < path2Duration &&
        path3Duration < path5Duration &&
        path3Duration < path4Duration &&
        path3Duration < path6Duration &&
        path3Duration < path7Duration &&
        path3Duration < path8Duration
      ) {
        // setPath = path3
        [selectedPath] = path3.route.trafast;
        // swapPositions(newPathList, 0, i * 4 + 2);
        break;
      } else if (
        path4?.code === 0 &&
        !path4Check &&
        path4Duration < path1Duration &&
        path4Duration < path2Duration &&
        path4Duration < path3Duration &&
        path4Duration < path5Duration &&
        path4Duration < path6Duration &&
        path4Duration < path7Duration &&
        path4Duration < path8Duration
      ) {
        // setPath = path4
        [selectedPath] = path4.route.trafast;
        // swapPositions(newPathList, 0, i * 4 + 3);
        break;
      } else if (
        path5?.code === 0 &&
        !path5Check &&
        path5Duration < path1Duration &&
        path5Duration < path2Duration &&
        path5Duration < path3Duration &&
        path5Duration < path4Duration &&
        path5Duration < path6Duration &&
        path5Duration < path7Duration &&
        path5Duration < path8Duration
      ) {
        // setPath = path4
        [selectedPath] = path5.route.trafast;
        // swapPositions(newPathList, 0, i * 4 + 3);
        break;
      } else if (
        path6?.code === 0 &&
        !path6Check &&
        path6Duration < path1Duration &&
        path6Duration < path2Duration &&
        path6Duration < path3Duration &&
        path6Duration < path4Duration &&
        path6Duration < path5Duration &&
        path6Duration < path7Duration &&
        path6Duration < path8Duration
      ) {
        // setPath = path4
        [selectedPath] = path6.route.trafast;
        // swapPositions(newPathList, 0, i * 4 + 3);
        break;
      } else if (
        path7?.code === 0 &&
        !path7Check &&
        path7Duration < path1Duration &&
        path7Duration < path2Duration &&
        path7Duration < path3Duration &&
        path7Duration < path4Duration &&
        path7Duration < path5Duration &&
        path7Duration < path6Duration &&
        path7Duration < path8Duration
      ) {
        // setPath = path4
        [selectedPath] = path7.route.trafast;
        // swapPositions(newPathList, 0, i * 4 + 3);
        break;
      } else if (
        path8?.code === 0 &&
        !path8Check &&
        path8Duration < path1Duration &&
        path8Duration < path2Duration &&
        path8Duration < path3Duration &&
        path8Duration < path4Duration &&
        path8Duration < path5Duration &&
        path8Duration < path6Duration &&
        path8Duration < path7Duration
      ) {
        // setPath = path4
        [selectedPath] = path7.route.trafast;
        // swapPositions(newPathList, 0, i * 4 + 3);
        break;
      }
    }
    if (selectedPath === null) {
      enqueueSnackbar("우회 경로를 찾을 수 없습니다.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        persist: true,
        action: snackbarId => {
          return retryAction(snackbarId);
        },
      });
      setPathList([]);
    } else {
      if (paths[vehicle.id]) {
        removePathList(paths[vehicle.id].paths.polylines);
        removePaths({ id: vehicle.id });
      }
      navStart(selectedPath, currentPosition);
    }

    // setPathList(newPathList);

    closeSnackbar(interruptedSnackbarId);
  };

  // 다이얼로그 생기면 바로 실행
  useEffect(() => {
    if (open) {
      (async () => {
        await handleRediscoveryPath();
        setOpen(false);
      })();
    }
  }, [open]);

  return (
    <AlertDialog
      open={open}
      onAgree={async () => {
        await handleRediscoveryPath();
      }}
      onClose={() => {
        setOpen(false);
      }}
      title="경로 재탐색"
      description="현재 진행 중인 경로에 장애물이 있습니다. 경로를 재탐색 하시겠습니까?"
    />
  );
}

export default RediscoverDialog;
