import React from "react";
import { mapConfig } from "constants";
import { Box, Button, Card, Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCenterEffect } from "utils/naverMaps";

function ParticipantItem({
  classes,
  vehicle,
  markers,
  onClickItem,
  onClickButton,
}) {
  const isConnect = markers[vehicle.id];
  const participant = isConnect
    ? classes.participantItem
    : classes.participantItemDiscon;
  const status = isConnect ? classes.connect : classes.disconnect;
  const columnItem = isConnect ? classes.columnItem : classes.columnItemDiscon;
  const columnNumber = isConnect
    ? classes.columnNumber
    : classes.columnNumberDiscon;

  return (
    <Grid container direction="row" className={classes.rowItem}>
      <Grid item xs={9}>
        <Grid
          container
          direction="row"
          onClick={() => isConnect && onClickItem(vehicle.id)}
          className={participant}
        >
          <Grid item xs={1}>
            <Box className={status} />
          </Grid>
          <Grid item xs={5} className={columnItem}>
            {/* {translateVehicle[vehicle.type]} */}
            {vehicle.type}
          </Grid>
          <Grid item xs={6} className={columnNumber}>
            {vehicle.id}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="outlined"
          onClick={() => isConnect && onClickButton(vehicle.id)}
        >
          정보
        </Button>
      </Grid>
    </Grid>
  );
}

function Participants({ vehicles }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { map, markers } = useSelector(state => state.location);

  const handleClickParticipant = participantedId => {
    dispatch.location.setFocusId({ id: participantedId });
    const marker = dispatch.location.getMarkerById({ id: participantedId });
    map.setZoom(mapConfig.zoom);
    setCenterEffect(map, marker);
  };

  const handleClickButton = participantedNumber => {
    const marker = markers[participantedNumber];
    dispatch.location.deleteFocusId();
    setCenterEffect(map, marker);
    navigate(`/participant/detail/${participantedNumber}`);
  };

  return (
    <Grid container direction="row" spacing={1} className={classes.contentRow}>
      <Grid item xs={4}>
        <Box className="accident-label">투입 차량</Box>
      </Grid>
      <Grid item xs={8}>
        <Box className="accident-value">{`${vehicles.length} 대`}</Box>
      </Grid>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <Box m={2}>
            <Grid container direction="column">
              {vehicles.length === 0 ? (
                <Box className={classes.cardText}>투입된 차량이 없습니다.</Box>
              ) : (
                vehicles.map(vehicleInfo => (
                  <ParticipantItem
                    classes={classes}
                    key={vehicleInfo.id}
                    vehicle={vehicleInfo}
                    markers={markers}
                    onClickItem={() => handleClickParticipant(vehicleInfo.id)}
                    onClickButton={() => handleClickButton(vehicleInfo.id)}
                  />
                ))
              )}
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  card: {
    maxHeight: "18vh",
    overflow: "auto",
  },
  rowItem: {
    margin: "0.325rem 0.15rem",
    alignItems: "center",
  },
  participantItemDiscon: {
    textAlign: "center",
    alignItems: "center",
  },
  participantItem: {
    textAlign: "center",
    alignItems: "center",
    "&:hover": {
      opacity: "0.5",
    },
  },
  columnItem: {
    borderRight: "1px solid #DADADA",
  },
  columnItemDiscon: {
    borderRight: "1px solid #DADADA",
    opacity: "0.5",
  },
  columnNumber: {},
  columnNumberDiscon: {
    opacity: "0.5",
  },
  contentRow: {
    paddingTop: "1rem",
  },
  connect: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "green",
  },
  disconnect: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: "red",
  },
  cardText: {
    margin: "0.325rem 0.15rem",
    alignItems: "center",
    padding: "0.5rem",
    textAlign: "center",
  },
});

export default Participants;
