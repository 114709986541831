import { useState } from "react";
import { blockerTypeList, initError } from "constants";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import { addListener, removeListener, reverseGeocode } from "utils/naverMaps";
import { publishBlocker } from "utils/socket";

const initBlockerError = {
  category: initError,
  location: initError,
  reason: { error: false },
};

const initBlockerData = {
  category: "",
  location: {
    latitude: 0,
    longitude: 0,
    address: "",
    updated: false,
  },
  reason: "",
};

function BlockerCreateDialog({
  accidentId,
  open = false,
  onOpen = () => {},
  onClose = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { map } = useSelector(state => state.location);
  const [blockerData, setBlockerData] = useState(initBlockerData);

  const [blockerDataError, setBlockerDataError] = useState(initBlockerError);

  // naver map event listeners
  const listeners = {};

  const handleChangeBlockerData = (inputKey, inputValue) => {
    const changed = produce(blockerData, draft => {
      if (inputKey === "address") {
        draft.location = {
          ...blockerData.location,
          address: inputValue,
        };
        return;
      }
      draft[inputKey] = inputValue;
    });
    setBlockerData(changed);
  };

  const handleAddMarker = event => {
    updateBlockerData(event.coord.y, event.coord.x);
    // remove click listener
    removeListener(listeners.blocker);
    delete listeners.blocker;

    // if updated location reset error message
    if (blockerDataError.location.error) {
      const resetError = produce(blockerDataError, draft => {
        draft.location = initError;
      });
      setBlockerDataError(resetError);
    }

    onOpen();
  };

  const updateBlockerData = (latitude, longitude) => {
    const callback = response => {
      const updatedBlocker = produce(blockerData, draft => {
        draft.location = {
          ...blockerData.location,
          latitude,
          longitude,
          updated: true,
          address: response.result.items[0].address,
        };
      });
      setBlockerData(updatedBlocker);
    };
    reverseGeocode(latitude, longitude, callback);
  };

  // 진입 불가 위치 마커로 표시
  const handleAddBlocker = () => {
    const addBlockerListener = addListener(map, "click", e =>
      handleAddMarker(e),
    );
    listeners.blocker = addBlockerListener;
    // TODO: add listener mouse cursor
    onClose();
  };

  const isValidateBlockerData = () => {
    const error = initBlockerError;
    // eslint-disable-next-line no-unused-vars
    let isValidate = true;

    if (!blockerData.category) {
      isValidate = false;
      error.category = {
        error: true,
        label: "통행 불가 종류를 입력하세요.",
      };
    }
    if (!blockerData.location.updated) {
      isValidate = false;
      error.location = {
        error: true,
        label: "통행 불가 위치를 추가해주세요.",
      };
    }

    if (!isValidate) {
      setBlockerDataError(error);
    }

    return isValidate;
  };

  const handleSubmitBlocker = async () => {
    if (!isValidateBlockerData()) {
      return;
    }
    const payload = {
      accidentId,
      ...blockerData,
    };
    const response = await dispatch.blocker.createBlocker(payload);
    if (response.status === 200) {
      setBlockerData(initBlockerData);
      publishBlocker(accidentId, response.data.id, "blockerCreated");
      onClose();
    }
  };

  const handleClose = () => {
    setBlockerData(initBlockerData);
    setBlockerDataError(initBlockerError);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <Box m={3}>
        <DialogTitle id="max-width-dialog-title">방해 구역 생성</DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <FormLabel component="legend">통행 불가 종류</FormLabel>
              <FormControl
                component="fieldset"
                className={classes.textField}
                error={blockerDataError.category.error}
              >
                <Select
                  id="category"
                  aria-label="통행불가 종류"
                  value={blockerData.category}
                  onChange={e =>
                    handleChangeBlockerData("category", e.target.value)
                  }
                >
                  {blockerTypeList.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {blockerDataError.category.label}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleAddBlocker()}
              >
                진입 불가 위치 선택
              </Button>
            </Grid>
            <Grid item>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <TextField
                    id="location"
                    className={classes.textField}
                    error={blockerDataError.location.error}
                    label="진입 불가 위치 (위도, 경도)"
                    value={`(${blockerData.location.latitude}, ${blockerData.location.longitude})`}
                    helperText={blockerDataError.location.label}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                id="address"
                className={classes.textField}
                label="진입 불가 주소"
                value={blockerData.location.address}
                disabled
              />
            </Grid>
            <Grid item>
              <TextField
                id="reason"
                className={classes.textField}
                label="진입 불가 사유"
                value={blockerData.reason}
                onChange={e =>
                  handleChangeBlockerData("reason", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.formActions}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleSubmitBlocker();
            }}
          >
            저장
          </Button>
          <Button onClick={handleClose} variant="outlined" color="secondary">
            취소
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  formContainer: {
    margin: "1rem",
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  formActions: {
    paddingTop: "2rem",
    justifyContent: "center",
  },
  textField: {
    width: "100%",
  },
}));

export default BlockerCreateDialog;
