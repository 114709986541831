/* eslint-disable prefer-const */
import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { HotkeysProvider } from "react-hotkeys-hook";
import { useSelector } from "react-redux";
import NaverMap from "./NaverMap";
import MainPage from "./MainPage";

function FirePathLayout() {
  const { vehicleButtonVisible, videoVisible } = useSelector(
    state => state.controller,
  );
  const classes = useStyles({ vehicleButtonVisible, videoVisible });

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <HotkeysProvider
          initiallyActiveScopes={[
            "mainPage",
            "mainPageTabs",
            "participantInfo",
            "directions",
          ]}
        >
          <MainPage />
        </HotkeysProvider>
      </Box>
      <HotkeysProvider initiallyActiveScopes={["naverMap"]}>
        <NaverMap width="100vw" height="100vh" />
      </HotkeysProvider>
      <Box id="vehicle-pano" className={classes.pano} />
      <Box id="blocker-pano" className={classes.pano} />
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    position: "relative",
    overflow: "hidden",
  },
  container: {
    position: "relative",
    flex: "1 0 auto",
    display: "block",
  },
  pano: {
    zIndex: "-1000 !important",
    position: "absolute !important",
  },
});
export default FirePathLayout;
