import { useEffect, useState } from "react";
import { mapConfig } from "constants";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { WarningOutlined, MyLocation } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import VehicleController from "./VehicleController";
import CustomButton from "./CustomButton";
import { paintBlueSelectedVehicle, setCenterEffect } from "utils/naverMaps";
import { stopRecord } from "utils/video";

function NaverMapButtonController({ createBlocker }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { clearPath, clearPathMap } = dispatch.path;
  const { map, markers } = useSelector(state => state.location);
  const { vehicle } = useSelector(state => state.vehicle);
  const { accident } = useSelector(state => state.accident);
  const { paths } = useSelector(state => state.path);
  const { inferenceMyResult, blockerPossibility } = useSelector(
    state => state.inference,
  );
  const [showWarning, setShowWarning] = useState(false);

  const handleClickMyLocation = () => {
    const myVehicleId = localStorage.getItem("myVehicleId");
    dispatch.location.setFocusId({ id: myVehicleId });
    paintBlueSelectedVehicle(paths[vehicle.id]);
    const marker = dispatch.location.getMarkerById({ id: myVehicleId });
    map.setZoom(mapConfig.zoom);
    setCenterEffect(map, marker);
  };

  const onClickVehicleDepart = () => {
    if (vehicle.id) {
      dispatch.vehicle.setIsDepart(true);
    }
  };

  const onClickCreateBlocker = () => {
    createBlocker("illegalParking", markers[vehicle.id].position);
  };

  const onClickAccidentAddress = () => {
    if (accident) {
      dispatch.location.deleteFocusId();
      setCenterEffect(map, markers.accident);
    }
  };

  const stopVideoRecording = () => {
    stopRecord("webcam");
    stopRecord("screen");
  };

  const onClickVehicleFinish = () => {
    stopVideoRecording();
    clearPath();
    clearPathMap();
    dispatch.vehicle.updateVehicle({
      ...vehicle,
      path: null,
      finishedAt: new Date().getTime(),
    });
    dispatch.vehicle.setIsDepart(false);
  };

  useEffect(() => {
    if (blockerPossibility >= 70) {
      setShowWarning(true);
    } else {
      setTimeout(() => {
        setShowWarning(false);
      }, 1000);
    }
  }, [blockerPossibility]);

  return (
    <>
      <Box className={classes.vehicleController}>
        <VehicleController
          departOnClick={onClickVehicleDepart}
          accidentOnClick={onClickAccidentAddress}
          finishOnClick={onClickVehicleFinish}
          logined={vehicle?.id}
        />
      </Box>
      <CustomButton
        classes={classes.createBlocker}
        title="블로커 생성"
        onClick={onClickCreateBlocker}
      >
        <Box className="impossible">
          {`${Number(blockerPossibility).toFixed(2)}%`}
        </Box>
        <WarningOutlined
          className={showWarning ? "warningIconAnimate" : "warningIconStatic"}
        />
        <WarningOutlined
          className={
            showWarning ? "warningIconAnimateFront" : "warningIconStaticFront"
          }
        />
      </CustomButton>
      <CustomButton
        classes={classes.myLocationBtn}
        title="현재 위치로 이동"
        onClick={handleClickMyLocation}
      >
        <MyLocation className="myLocationIcon" />
      </CustomButton>
    </>
  );
}

const useStyles = makeStyles({
  vehicleController: {
    position: "sticky",
    height: "fit-content",
    zIndex: 200,
    top: "1%",
    display: "flex",
    margin: "0 auto",
  },
  "@keyframes blinker": {
    from: { opacity: 0.1 },
    "50%": { opacity: 1 },
    to: { opacity: 0.1 },
  },
  myLocationBtn: {
    right: 30,
    bottom: 30,
    position: "absolute",
    zIndex: 9999,
    animationName: "$blinker",
    animationDuration: "5s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
    "&:hover": {
      animation: false,
    },
    "& .myLocationIcon": {
      color: "#3f51b5",
      fontSize: "3rem",
    },
  },
  "@keyframes growAndFade": {
    from: {
      transform: `scale(1)`,
      opacity: 1,
    },
    "50%": {
      transform: `scale(1.5)`,
      opacity: 0.5,
    },
    to: {
      transform: `scale(2)`,
      opacity: 0,
    },
  },
  createBlocker: {
    right: 30,
    bottom: 90,
    position: "absolute",
    zIndex: 9999,
    "& .warningIconAnimate": {
      color: "#f44336",
      fontSize: "3rem",
      animationName: "$growAndFade",
      animationDuration: "0.7s",
      animationTimingFunction: "linear",
      animationIterationCount: "infinite",
      position: "absolute",
      right: 13,
      bottom: 20,
    },
    "& .warningIconStatic": {
      color: "#3f51b5",
      fontSize: "3rem",
      position: "absolute",
      right: 13,
      bottom: 20,
    },
    "& .warningIconAnimateFront": {
      color: "#f44336",
      fontSize: "3rem",
      position: "absolute",
      right: 13,
      bottom: 20,
    },
    "& .warningIconStaticFront": {
      color: "#3f51b5",
      fontSize: "3rem",
      position: "absolute",
      right: 13,
      bottom: 20,
    },
    "& .impossible": {
      color: "black",
      position: "absolute",
      bottom: 5,
      right: 9,
      fontSize: "1rem",
    },
  },
});
export default NaverMapButtonController;
