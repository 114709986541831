import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";

// children: child components, goback: backward page path
function SubPage({ children, goBack = null }) {
  const classes = useStyles();

  const navigator = useNavigate();

  const handleClickClose = () => {
    if (!goBack) {
      navigator(-1);
      return;
    }
    navigator(goBack);
  };

  return (
    <Box className={classes.sub}>
      {children}
      <Box className={classes.entryClose}>
        <Box className="close-button">
          <IconButton onClick={handleClickClose}>
            <Close />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles({
  sub: {
    borderLeft: "1px solid #ddd",
    position: "absolute",
    left: "100%",
    flexDirection: "column",
    width: "24.5rem",
    height: "100%",
    backgroundColor: "#fff",
    boxSizing: "border-box",
  },
  entryClose: {
    position: "absolute",
    right: "-43px",
    top: "24px",
    width: "43px",
    height: "44px",
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderLeft: 0,
    borderTop: 0,
    borderRadius: "0 4px 4px 0",
    fontSize: "1px",
    lineHeight: "1px",
    boxShadow: "1px 1px 0px 0 rgba(0,0,0,.2)",
    cursor: "pointer",
    "& close-button": {
      margin: 0,
      padding: 0,
      appearance: "none",
    },
  },
});
export default SubPage;
