import { instance as apiClient } from "apiClient";

const initialState = {};

export default {
  state: initialState,
  reducers: {},
  effects: {
    async getBlockerImage(payload) {
      const { accidentId, blockerId } = payload;
      const { data } = await apiClient.get(
        `/api/v1/blockers/${accidentId}/${blockerId}/image`,
      );
      return data;
    },
    async uploadBlockerImage(payload) {
      try {
        const { accidentId, blockerId, body } = payload;
        const res = await apiClient.post(
          `/api/v1/blockers/${accidentId}/${blockerId}/image`,
          body,
        );
        return res;
      } catch (err) {
        return err;
      }
    },
    async getVehicleImage(payload) {
      const { vehicleNumber } = payload;
      const { data } = await apiClient.get(
        `/api/v1/vehicles/${vehicleNumber}/image`,
      );
      return data;
    },
    async uploadVehicleImage(payload) {
      try {
        const { vehicleNumber, body } = payload;
        const res = await apiClient.post(
          `/api/v1/vehicles/${vehicleNumber}/image`,
          body,
        );
        return res;
      } catch (err) {
        return err;
      }
    },
  },
};
