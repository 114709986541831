import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CardContent,
  Box,
  Typography,
  Grid,
  Card,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import JurisdictionDialog from "./JurisdictionDialog";
import {
  AlertDialog,
  BreadCrumbs,
  DependencyUpdateDialog,
  StickyHeadTable,
} from "components";
import {
  vehicleColumns,
  accidentColumns,
  vehicleShowList,
  accidentShowList,
} from "utils/dataSetting";

const breadCrumbs = [
  {
    id: "jurisdiction",
    label: "관할서",
    path: "/admin/jurisdiction",
    active: false,
  },
  {
    id: "info",
    label: "관할서 정보",
    active: true,
  },
];

function InfoItem({ label, value }) {
  return (
    <Box m={1} mb={3}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={2}
          style={{ fontWeight: "bold", marginBottom: "6px" }}
        >
          {label}
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          style={{
            overflowWrap: "break-word",
          }}
        >
          {value}
        </Grid>
      </Grid>
    </Box>
  );
}

function InfoList({
  title,
  onClickUpdate,
  columns,
  rows,
  onClickRow = () => {},
}) {
  const classes = useStyle();
  return (
    <Grid item xs={12}>
      <Card variant="outlined">
        <CardContent>
          <Box m={1} pb={3}>
            <Grid
              container
              alignItems="flex-start"
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              <Grid item>
                <Box className={classes.editButtons}>
                  <Box className={classes.editButton}>
                    <Button
                      onClick={onClickUpdate}
                      variant="outlined"
                      color="primary"
                    >
                      변경
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box m={1}>
            <Grid container direction="column">
              <StickyHeadTable
                columns={columns}
                rows={rows}
                onClickRow={onClickRow}
              />
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

function JurisdictionInfo() {
  const classes = useStyle();
  const { jurisdictionId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jurisdiction, jurisdictionVehicles, jurisdictionAccidents } =
    useSelector(state => state.jurisdiction);
  const { vehicles } = useSelector(state => state.vehicle);
  const { accidents } = useSelector(state => state.accident);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isOpenVehicleDialog, setIsOpenVehicleDialog] = useState(false);
  const [isOpenAccidentDialog, setIsOpenAccidentDialog] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const onClickDelete = () => {
    setDeleteAlert(true);
  };

  const onClickDeleteConfirm = () => {
    dispatch.jurisdiction.deleteJurisdiction(jurisdictionId);
    navigate("/admin/jurisdiction");
  };

  const onClickEdit = () => {
    setIsOpenDialog(true);
  };

  const handleCloseDialog = () => {
    dispatch.jurisdiction.getJurisdiction(jurisdictionId);
    dispatch.vehicle.getVehicles();
    setIsOpenDialog(false);
  };

  const handleCloseVehicleDialog = () => {
    dispatch.vehicle.getVehicles();
    setIsOpenVehicleDialog(false);
  };

  const handleCloseAccidentDialog = () => {
    dispatch.accident.getAccidents();
    setIsOpenAccidentDialog(false);
  };

  const onClickAccidentRow = row => {
    navigate(`/admin/accidents/info/${row.id}`);
  };

  const onClickUpdateVehicle = () => {
    setIsOpenVehicleDialog(true);
  };

  const onClickUpdateAccident = () => {
    setIsOpenAccidentDialog(true);
  };

  useEffect(() => {
    dispatch.jurisdiction.getJurisdiction(jurisdictionId);
    dispatch.accident.getAccidents();
    dispatch.vehicle.getVehicles();
  }, []);

  useEffect(() => {
    if (jurisdiction) {
      const data = vehicles.filter(item => {
        if (item.jurisdiction) {
          return item.jurisdiction.name === jurisdiction.name;
        }
        return false;
      });
      dispatch.jurisdiction.getJurisdictionVehicles(data);
    }
  }, [vehicles, jurisdiction]);

  useEffect(() => {
    if (jurisdiction) {
      const data = accidents.filter(item => {
        if (item.jurisdiction) {
          return item.jurisdiction.name === jurisdiction.name;
        }
        return false;
      });
      dispatch.jurisdiction.getJurisdictionAccidents(data);
    }
  }, [accidents, jurisdiction]);

  if (!jurisdiction) {
    return null;
  }

  return (
    <Box>
      <BreadCrumbs items={breadCrumbs} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Box m={1} pb={3}>
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <Typography variant="h5">관할서 정보</Typography>
                  </Grid>
                  <Grid item>
                    <Box className={classes.editButtons}>
                      <Box className={classes.editButton}>
                        <Button
                          onClick={onClickEdit}
                          variant="outlined"
                          color="primary"
                        >
                          수정
                        </Button>
                      </Box>
                      <Box className={classes.editButton}>
                        <Button
                          onClick={onClickDelete}
                          variant="outlined"
                          color="secondary"
                        >
                          제거
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box m={1}>
                <Grid container>
                  <Grid item xs={12}>
                    <InfoItem label="이름" value={jurisdiction.name} />
                    <InfoItem label="전화번호" value={jurisdiction.phone} />
                    <InfoItem
                      label="주소"
                      value={jurisdiction.location.address}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <InfoList
          title="차량 목록"
          onClickUpdate={onClickUpdateVehicle}
          columns={vehicleColumns}
          rows={jurisdictionVehicles}
        />
        <InfoList
          title="재난 목록"
          onClickUpdate={onClickUpdateAccident}
          columns={accidentColumns}
          rows={jurisdictionAccidents}
          onClickRow={onClickAccidentRow}
        />
      </Grid>
      <JurisdictionDialog
        open={isOpenDialog}
        onClose={handleCloseDialog}
        isUpdate
        newJurisdiction={jurisdiction}
      />
      <DependencyUpdateDialog
        open={isOpenVehicleDialog}
        onClose={handleCloseVehicleDialog}
        parentKey="jurisdiction"
        parent={jurisdiction}
        child={jurisdictionVehicles}
        selectList={vehicles}
        itemInfo={vehicleShowList}
        updateFunction={dispatch.vehicle.updateVehicle}
      />
      <DependencyUpdateDialog
        open={isOpenAccidentDialog}
        onClose={handleCloseAccidentDialog}
        parentKey="jurisdiction"
        parent={jurisdiction}
        child={jurisdictionAccidents}
        selectList={accidents}
        itemInfo={accidentShowList}
        updateFunction={dispatch.accident.updateAccident}
      />
      <AlertDialog
        title="관할서 제거"
        description="제거하시겠습니까?"
        onClose={() => {
          setDeleteAlert(false);
        }}
        open={deleteAlert}
        onAgree={onClickDeleteConfirm}
        dis
      />
    </Box>
  );
}

const useStyle = makeStyles({
  editButtons: {
    display: "flex",
  },
  editButton: {
    paddingRight: "0.425rem",
  },
  card: {
    header: {
      width: "100%",
      display: "flex",
    },
  },
});

export default JurisdictionInfo;
