import React from "react";
import { Box, Button, Card, Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router-dom";
import { HelpOutline } from "@material-ui/icons";
import { dateFormat } from "utils";
import { setCenterEffect } from "utils/naverMaps";
import findBlockerType from "utils/blocker";
import { CustomButton } from "components";

function BlockerItem({ classes, blocker, onClickRow, onClickButton }) {
  return (
    <Grid container direction="row" className={classes.rowItem}>
      <Grid item xs={9}>
        <Box
          onClick={() => onClickRow(blocker)}
          className={classes.blockerItem}
        >
          <Grid item xs={6} className={classes.columnItem}>
            {dateFormat(blocker.updatedAt || blocker?.createdAt, "HH:mm:ss")}
          </Grid>
          <Grid item xs={6}>
            {findBlockerType(blocker.category)}
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Button variant="outlined" onClick={onClickButton}>
          정보
        </Button>
      </Grid>
    </Grid>
  );
}

function Blockers({ blockers, handleAddBlocker }) {
  const classes = useStyles();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { map, markers } = useSelector(state => state.location);

  const handleClickBlocker = blockerData => {
    const marker = markers[blockerData.id];
    dispatch.location.deleteFocusId();
    setCenterEffect(map, marker);
  };

  const handleClickButton = blockerData => {
    const marker = markers[blockerData.id];
    dispatch.location.deleteFocusId();
    setCenterEffect(map, marker);
    navigator({
      pathname: `/blocker`,
      search: `?accidentId=${blockerData.accident.id}&blockerId=${blockerData.id}`,
    });
  };

  return (
    <Grid container direction="row" spacing={1} className={classes.contentRow}>
      <Grid item xs={12}>
        <Box className={classes.blockerHeader}>
          <Box className={classes.blockerLabel}>
            <Box>진입 발생 장애 구역</Box>
            <CustomButton
              title="맵에서 마우스 우클릭으로 추가/삭제 가능"
              classes={classes.tooltip}
            >
              <HelpOutline />
            </CustomButton>
          </Box>

          <Button variant="outlined" color="primary" onClick={handleAddBlocker}>
            추가
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Card className={classes.card}>
          {blockers.length !== 0 ? (
            <Box m={2}>
              {blockers.map(blockerInfo => (
                <BlockerItem
                  classes={classes}
                  key={blockerInfo.id}
                  blocker={blockerInfo}
                  onClickRow={() => handleClickBlocker(blockerInfo)}
                  onClickButton={() => handleClickButton(blockerInfo)}
                />
              ))}
            </Box>
          ) : (
            <Box className={classes.cardText}>진입 장애 구역이 없습니다.</Box>
          )}
        </Card>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  card: {
    maxHeight: "18vh",
    overflow: "auto",
  },
  rowItem: {
    margin: "0.325rem 0.15rem",
    alignItems: "center",
  },
  blockerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  blockerItem: {
    display: "flex",
    textAlign: "center",
    "&:hover": {
      opacity: 0.5,
    },
  },
  blockerLabel: { display: "flex", alignItems: "center", fontWeight: 600 },
  blockerAddress: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
  },
  columnItem: {
    borderRight: "1px solid #DADADA",
  },
  contentRow: {
    paddingTop: "1rem",
  },
  tooltip: {
    paddingLeft: "1rem",
  },
  cardText: {
    margin: "0.325rem 0.15rem",
    alignItems: "center",
    padding: "0.5rem",
    textAlign: "center",
  },
});

export default Blockers;
