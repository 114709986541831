import { useEffect, useRef, useState } from "react";
import { accidentCategoryList, accidentStatusList } from "constants";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SnackbarMessage } from "components";
import { findChildren } from "utils/dataSetting";
import { getGeocode } from "utils/naverMaps";
import { handleEnter } from "utils";

const initAccidentData = {
  id: undefined,
  name: undefined,
  category: undefined,
  type: undefined,
  status: undefined,
  location: {
    address: undefined,
    latitude: undefined,
    longitude: undefined,
  },
  description: undefined,
};

const initSnack = {
  isOpen: false,
  severity: "success",
  message: "",
};

function AccidentDialog({
  open = false,
  onClose = () => {},
  newAccident = initAccidentData,
  isUpdate = false,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accidentData, setAccidentData] = useState(newAccident);
  const [snackInfo, setSnackInfo] = useState(initSnack);
  const addressVerified = isUpdate ? useRef("true") : useRef("false");

  const handleChangeAccidentData = (inputKey, inputValue) => {
    setAccidentData(prevData => {
      const updatedData = produce(prevData, draft => {
        draft[inputKey] = inputValue;
        // if (inputKey === "category") {
        //   draft.type = findChildren(inputValue, accidentCategoryList)[0].value;
        // }
      });
      return updatedData;
    });
  };

  const validate = () => {
    if (
      // !accidentData.name ||
      !accidentData.category ||
      !accidentData.type ||
      !accidentData.status ||
      !accidentData.location.address
    ) {
      setSnackInfo({
        isOpen: true,
        severity: "error",
        message: "값을 입력해주세요.",
      });
      return false;
    }
    if (addressVerified.current !== "true") {
      setSnackInfo({
        isOpen: true,
        severity: "error",
        message: "주소를 검증 해주세요.",
      });
      return false;
    }
    return true;
  };

  const onChangeAddr = e => {
    if (addressVerified.current !== "false") {
      addressVerified.current = "false";
    }
    const address = e.target.value;
    const newLocation = {
      ...accidentData.location,
      address,
    };
    handleChangeAccidentData("location", newLocation);
  };

  const searchAddress = () => {
    const { address } = accidentData.location;
    if (!address || address.length === 0) {
      setSnackInfo({
        isOpen: true,
        severity: "error",
        message: "주소를 입력해주세요.",
      });
      return;
    }
    getGeocode(
      accidentData.location,
      "location",
      (inputKey, newState) => {
        handleChangeAccidentData(inputKey, newState);
        addressVerified.current = "true";
        setSnackInfo({
          isOpen: true,
          severity: "success",
          message: "주소 검증을 완료했습니다.",
        });
      },
      () => {
        addressVerified.current = "failed";
        setSnackInfo({
          isOpen: true,
          severity: "error",
          message: "주소를 찾을 수 없습니다.",
        });
      },
    );
  };

  const handleSubmitAccident = async () => {
    if (!validate()) return;
    let response;
    if (isUpdate) {
      response = await dispatch.accident.updateAccident(accidentData);
      if (response.status === 200) {
        handleClose();
      }
    } else {
      response = await dispatch.accident.createAccident(accidentData);
      if (response.status === 200) {
        navigate(`/admin/accidents/info/${response.data.id}`, {
          replace: false,
        });
      }
    }
    if (response.status !== 200) {
      setSnackInfo({
        isOpen: true,
        severity: "error",
        message: "같은 이름의 재난이 이미 있습니다.",
      });
    }
  };

  const handleClose = () => {
    setAccidentData(newAccident);
    onClose();
  };

  useEffect(() => {
    setAccidentData(newAccident);
  }, [newAccident]);

  useEffect(() => {
    dispatch.jurisdiction.getJurisdictions();
  }, []);

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <Box m={3}>
          <DialogTitle id="max-width-dialog-title">
            {isUpdate ? "재난 수정" : "재난 생성"}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <TextField
                  error={!accidentData.id}
                  helperText={!accidentData.id && "값을 입력해주세요."}
                  id="accidentId"
                  label="재난번호"
                  value={accidentData.id || ""}
                  onChange={e => handleChangeAccidentData("id", e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  // error={!accidentData.name}
                  helperText={!accidentData.name && "값을 입력해주세요."}
                  id="accidentName"
                  label="이름"
                  value={accidentData.name || ""}
                  onChange={e =>
                    handleChangeAccidentData("name", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!accidentData.category}
                  helperText={!accidentData.category && "값을 입력해주세요."}
                  id="accidentCategory"
                  label="재해종별"
                  value={accidentData.category || ""}
                  onChange={e =>
                    handleChangeAccidentData("category", e.target.value)
                  }
                />
                {/* <FormControl component="fieldset">
                  <FormLabel component="legend">종류</FormLabel>
                  <RadioGroup
                    row
                    aria-label="Accident Category"
                    name="accidentCategory"
                    value={accidentData.category || ""}
                    defaultValue="화재"
                    onChange={(_, value) => {
                      handleChangeAccidentData("category", value);
                    }}
                  >
                    {accidentCategoryList.map(item => (
                      <FormControlLabel
                        key={item.value}
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl> */}
              </Grid>
              <Grid item>
                <TextField
                  error={!accidentData.type}
                  helperText={!accidentData.type && "값을 입력해주세요."}
                  id="accidentType"
                  label="분류"
                  value={accidentData.type || ""}
                  onChange={e =>
                    handleChangeAccidentData("type", e.target.value)
                  }
                />
                {/* <FormControl component="fieldset">
                  <FormLabel component="legend">분류</FormLabel>
                  <RadioGroup
                    row
                    aria-label="Accident Type"
                    name="accidentType"
                    value={accidentData.type || ""}
                    onChange={(_, value) =>
                      handleChangeAccidentData("type", value)
                    }
                  >
                    {findChildren(
                      accidentData.category,
                      accidentCategoryList,
                    ).map(item => (
                      <FormControlLabel
                        key={item.value}
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl> */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!accidentData.jurisdiction}
                  helperText={
                    !accidentData.jurisdiction && "값을 입력해주세요."
                  }
                  id="jurisdiction"
                  label="관할서"
                  value={accidentData.jurisdiction || ""}
                  onChange={e =>
                    handleChangeAccidentData("jurisdiction", e.target.value)
                  }
                />
                {/* <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <FormLabel component="legend">관할서</FormLabel>
                  <Select
                    aria-label="Jurisdiction"
                    name="accidentJurisdiction"
                    value={
                      accidentData.jurisdiction
                        ? accidentData.jurisdiction.name
                        : "None"
                    }
                    onChange={e => {
                      const selectedJurisdiction = jurisdictions.find(
                        item => item.name === e.target.value,
                      );
                      handleChangeAccidentData(
                        "jurisdiction",
                        selectedJurisdiction,
                      );
                    }}
                  >
                    <MenuItem key="none" value="None">
                      없음
                    </MenuItem>
                    {jurisdictions.map(item => (
                      <MenuItem key={item.name} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={!accidentData.status}
                  helperText={!accidentData.status && "값을 입력해주세요."}
                  id="accidentStatus"
                  label="상태"
                  value={accidentData.status || ""}
                  onChange={e =>
                    handleChangeAccidentData("status", e.target.value)
                  }
                />
                {/* <FormControl component="fieldset">
                  <FormLabel component="legend">상태</FormLabel>
                  <RadioGroup
                    row
                    aria-label="Accident Status"
                    name="accidentStatus"
                    value={accidentData.status || ""}
                    onChange={(_, value) =>
                      handleChangeAccidentData("status", value)
                    }
                  >
                    {accidentStatusList.map(item => (
                      <FormControlLabel
                        key={item.value}
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl> */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ maxWidth: "300px" }}
                  error={
                    !accidentData.location?.address ||
                    addressVerified.current === "failed"
                  }
                  helperText={
                    (!accidentData.location?.address ||
                      addressVerified.current === "failed") &&
                    "주소를 올바르게 입력해주세요."
                  }
                  id="accidentLocation"
                  label="위치"
                  value={accidentData.location?.address || ""}
                  onChange={onChangeAddr}
                  onKeyDown={e => {
                    handleEnter(e, () => {
                      searchAddress();
                      // e.target.blur();
                    });
                  }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={searchAddress}
                  className={classes.verifyButton}
                  disabled={addressVerified.current === "true"}
                >
                  {addressVerified.current === "true"
                    ? "검증 완료"
                    : "주소 검증"}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  style={{ maxWidth: "300px" }}
                  id="accidentDescription"
                  label="설명"
                  multiline
                  minRows={4}
                  value={accidentData.description || ""}
                  onChange={e =>
                    handleChangeAccidentData("description", e.target.value)
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.formActions}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleSubmitAccident();
              }}
            >
              저장
            </Button>
            <Button onClick={handleClose} variant="outlined" color="secondary">
              취소
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <SnackbarMessage
        {...snackInfo}
        setCondition={() => {
          setSnackInfo(initSnack);
        }}
      />
    </>
  );
}

const useStyles = makeStyles({
  formContainer: {
    margin: "1rem",
  },
  formControl: {
    minWidth: "150px",
  },
  formActions: {
    paddingTop: "2rem",
    justifyContent: "center",
  },
  verifyButton: {
    marginTop: "0.5rem",
    marginLeft: "2rem",
  },
});

export default AccidentDialog;
