import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { Pagination } from "@material-ui/lab";
import { TableSortLabel } from "@material-ui/core";

// ref - https://v4.mui.com/components/tables/#fixed-header

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const {
    headCells,
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    useCheckbox = false,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {useCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
        )}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function StickyHeadTable({
  classes = useStyles(),
  rows = [],
  columns = [],
  onClickRow = () => {},
  useCheckbox = false,
  checkBoxList = [],
  setCheckBoxList = () => {},
}) {
  const [allChecked, setAllChecked] = React.useState(false);
  const itemsPerPage = 5;
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const sortedRows = stableSort(rows, getComparator(order, orderBy));
  const pageRows = sortedRows.slice(startIndex, startIndex + itemsPerPage);

  const handleClickRow = row => {
    onClickRow(row);
  };

  const handleClickCheckBox = row => {
    let newCheckBoxList = [...checkBoxList];
    if (newCheckBoxList.some(item => item === row.id)) {
      newCheckBoxList = newCheckBoxList.filter(item => item !== row.id);
    } else {
      newCheckBoxList.push(row.id);
    }
    setCheckBoxList(newCheckBoxList);
  };

  const handleWholeCheckBox = () => {
    if (allChecked) {
      setCheckBoxList([]);
    } else {
      setCheckBoxList([...pageRows.map(item => item.id)]);
    }
    setAllChecked(!allChecked);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(1);
  };

  useEffect(() => {
    setCheckBoxList([]);
  }, [page]);

  useEffect(() => {
    if (checkBoxList.length !== 0 && checkBoxList.length === pageRows.length) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [checkBoxList]);

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <EnhancedTableHead
            classes={classes}
            headCells={columns}
            numSelected={checkBoxList.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleWholeCheckBox}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            useCheckbox={useCheckbox}
          />
          <TableBody>
            {pageRows.map(row => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                {useCheckbox && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={checkBoxList.some(item => item === row.id)}
                      onClick={() => handleClickCheckBox(row)}
                    />
                  </TableCell>
                )}
                {columns.map(column => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      onClick={() => handleClickRow(row)}
                    >
                      {column.format ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <Pagination
          count={Math.ceil(rows.length / itemsPerPage)}
          page={page}
          onChange={handleChangePage}
        />
      </div>
    </Paper>
  );
}

const useStyles = makeStyles({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  container: {
    minWidth: "200px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});
