/* eslint-disable class-methods-use-this */
import { noop, geolocationConfig } from "constants";
import { publish } from "utils/socket";

class AbstractEngine {
  constructor(vehicle, accident, deviceType) {
    this.vehicle = vehicle;
    this.accident = accident;
    this.device = deviceType;
  }

  publishPosition(position) {
    if (!position) {
      return;
    }
    const currentPosition = {
      latitude: position.latitude,
      longitude: position.longitude,
      speed: position.speed,
      heading: position.heading,
      timestamp: Date.now(),
      vehicleType: this.vehicle.type,
    };
    publish(
      JSON.stringify({
        type: "location",
        topicId: this.accident.id,
        contents: currentPosition,
        sender: this.vehicle.id,
        createdAt: Date.now(),
      }),
      "/pub/message",
    );
  }

  getCurrentPositionWithLocalGPS(callback = noop, errCallback = noop) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const currentPosition = this.convertPosition(position);
        currentPosition.speed = 10.0;
        // currentPosition.latitude = 37.1234;
        // currentPosition.longitude = 124.123;
        currentPosition.heading = 179.3;
        callback(currentPosition);
      },
      errCallback,
      geolocationConfig,
    );
  }

  // abstract method
  getName() {
    // do nothing
  }

  // abstract method
  getCurrentPosition(callback = noop) {
    // do nothing
  }

  // abstract method
  notifyCurrentPosition(callback = noop, errCallback = noop) {
    // do nothing
  }

  // abstract method
  getCurrentImage(props = {}) {
    // do nothing
  }

  // abstract method
  convertPosition(position) {
    return {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      speed: position.coords.speed ? Math.floor(position.coords.speed) : null,
      heading: position.coords.heading,
    };
  }
}

export default AbstractEngine;
