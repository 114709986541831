import React from "react";
import { translateAccident } from "constants";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { dateFormat } from "utils";
import { setCenterEffect } from "utils/naverMaps";

function AccidentInfo({ accident }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { map, markers } = useSelector(state => state.location);

  const onClickAccidentAddress = () => {
    dispatch.location.deleteFocusId();
    setCenterEffect(map, markers.accident);
  };

  useHotkeys("g", onClickAccidentAddress, {
    scopes: ["mainPage"],
  });

  if (!accident) {
    return null;
  }
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={1}
        className={classes.contentRow}
      >
        <Grid item xs={4}>
          <Box className="accident-label">재난 종류</Box>
        </Grid>
        <Grid item xs={8}>
          <Box className="accident-value">
            {/* {translateAccident[accident.category]} */}
            {`${accident.category}/${accident.type}`}
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={1}
        className={classes.contentRow}
      >
        <Grid item xs={4}>
          <Box className="accident-label">재난 발생시간</Box>
        </Grid>
        <Grid item xs={8}>
          <Box className="accident-value">
            {dateFormat(accident.createdAt, "YYYY년 MM월 DD일 HH:mm:ss")}
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={1}
        className={classes.contentRow}
      >
        <Grid item xs={4}>
          <Box className="accident-label">재난 발생 지점</Box>
        </Grid>
        <Grid item xs={8} onClick={onClickAccidentAddress}>
          <Box className="accident-value">{accident.location.address}</Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        spacing={1}
        className={classes.contentRow}
      >
        <Grid item xs={4}>
          <Box className="accident-label">재난 설명</Box>
        </Grid>
        <Grid item xs={8}>
          <Box className="accident-value">{accident.description}</Box>
        </Grid>
      </Grid>
    </>
  );
}
const useStyles = makeStyles({
  contentRow: {
    paddingTop: "1rem",
  },
});
export default AccidentInfo;
