const initialState = {
  engine: null,
  device: "GPS",
};

export default {
  state: initialState,
  reducers: {
    setEngine(state, payload) {
      state.engine = payload;
    },
    setDevice(state, device) {
      state.device = device;
    },
  },
  effects: {},
};
