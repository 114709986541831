import {
  accidentCategoryList,
  accidentStatusList,
  vehicleTypeList,
  blockerTypeList,
} from "constants";

export const blockerColumns = [
  {
    id: "category",
    label: "구분",
    format: value => dataToKorean(value, blockerTypeList),
  },
  {
    id: "reason",
    label: "사유",
  },
  { id: "location", label: "위치", format: value => value.address },
];

export const vehicleVideoColumns = [
  {
    id: "name",
    label: "이름",
  },
  {
    id: "size",
    label: "크기",
  },
];

export const vehicleColumns = [
  {
    id: "accident",
    label: "현재 상태",
    format: value => value?.status || "재난 없음",
  },
  {
    id: "type",
    label: "차량 종류",
    // format: value => dataToKorean(value, vehicleTypeList),
  },
  {
    id: "jurisdiction",
    label: "관할서",
    format: value => value || "없음",
  },
  {
    id: "id",
    label: "차량명",
  },
];

export const jurisdictionColumns = [
  {
    id: "name",
    label: "이름",
  },
  {
    id: "phone",
    label: "전화번호",
  },
  { id: "location", label: "위치", format: value => value.address },
];

export const accidentColumns = [
  {
    id: "status",
    label: "상태",
    // format: value => dataToKorean(value, accidentStatusList),
  },
  {
    id: "category",
    label: "종류",
    // format: value => dataToKorean(value, accidentCategoryList),
  },
  {
    id: "name",
    label: "이름",
  },
  {
    id: "location",
    label: "위치",
    format: value => value.address,
  },
];

export const vehicleShowList = {
  main: "id",
  column: [
    {
      id: "type",
      label: "차량 종류",
      // format: value => dataToKorean(value, vehicleTypeList),
    },
    {
      id: "jurisdiction",
      label: "관할서",
      format: value => value || "없음",
    },
    {
      id: "id",
      label: "차량명",
    },
  ],
  list: ["typeKorean", "jurisdictionName", "id"],
};

export const accidentShowList = {
  main: "name",
  column: accidentColumns,
  list: ["jurisdictionName", "statusKorean", "name"],
};

export const koreanToData = (value, list) =>
  list.find(item => item.label === value)?.value;

export const dataToKorean = (value, list) =>
  list.find(item => item.value === value)?.label;

export const findChildren = (value, list) => {
  const index = list.findIndex(item => item.label === value);
  return list[index]?.children || [];
};
