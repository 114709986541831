import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ChevronRight } from "@material-ui/icons";
import { compareFunction } from "utils";

function ItemList({ list, handleClick, columns, title }) {
  const classes = useStyles();
  return (
    <Box m={2} className={classes.listContainer}>
      <Box>{title}</Box>
      <List className={classes.list}>
        {list.map(item => (
          <ListItem
            button
            hover="true"
            className={classes.item}
            key={item.id}
            onClick={() => handleClick(item)}
          >
            {columns.map(col => (
              <Grid
                item
                key={col.id}
                xs={12 / columns.length}
                className={classes.columnItem}
              >
                {col.format ? col.format(item[col.id]) : item[col.id]}
              </Grid>
            ))}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

function DependencyUpdateDialog({
  open,
  onClose,
  parentKey,
  parent,
  child = [],
  selectList,
  selectListFilter = () => true,
  itemInfo,
  updateFunction,
}) {
  const [childList, setChildList] = useState(child);
  const [deleteChildList, setDeleteChildList] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles({ isMobile });

  const handleAddItem = selectedItem => {
    if (!childList.some(item => item.id === selectedItem.id)) {
      setChildList(
        [...childList, selectedItem].sort((a, b) =>
          compareFunction(a, b, itemInfo.main),
        ),
      );
    }
    const newDeleteChildList = deleteChildList.filter(
      item => item.id !== selectedItem.id,
    );
    setDeleteChildList(newDeleteChildList);
  };

  const onClickDelete = deleteItem => {
    const newChildList = childList.filter(item => item.id !== deleteItem.id);
    setChildList(newChildList);
    if (!deleteChildList.some(item => item.id === deleteItem.id)) {
      setDeleteChildList([...deleteChildList, deleteItem]);
    }
  };

  const onClickSubmit = async () => {
    const update = childList.map(item => {
      const updateItem = { ...item, [`${parentKey}`]: parent };
      return updateFunction(updateItem);
    });
    await Promise.all(update);
    const deleteParent = deleteChildList.map(item => {
      const updateItem = { ...item, [`${parentKey}`]: null };
      return updateFunction(updateItem);
    });
    await Promise.all(deleteParent);
    onClose();
  };

  useEffect(() => {
    const sortedChildList = [...child].sort((a, b) =>
      compareFunction(a, b, itemInfo.main),
    );
    setChildList(sortedChildList);
  }, [child]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle>등록</DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction={isMobile ? "column" : "row"}
          spacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={5} xs={12}>
            <ItemList
              list={selectList
                .filter(item => !childList.some(check => check.id === item.id))
                .filter(selectListFilter)
                .sort((a, b) => compareFunction(a, b, itemInfo.main))}
              handleClick={handleAddItem}
              columns={itemInfo.column}
              title="투입 가능 목록"
            />
          </Grid>
          <Grid item>
            <ChevronRight className={classes.chevron} />
          </Grid>
          <Grid item md={5} xs={12}>
            <ItemList
              list={childList}
              handleClick={onClickDelete}
              columns={itemInfo.column}
              title="투입 목록"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickSubmit} color="primary">
          저장
        </Button>
        <Button onClick={onClose} color="secondary">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  formControl: {
    width: "250px",
  },
  listContainer: {
    width: "300px",
  },
  list: {
    overflowY: "auto",
    overflowX: "hidden",
    height: "350px",
    width: "100%",
  },
  item: {
    margin: "0.625rem 0.25rem",
    textAlign: "center",
    cursor: "pointer",
  },
  columnItem: {
    borderRight: "1px solid #DADADA",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
  },
  chevron: {
    transform: ({ isMobile }) => (isMobile ? "rotate(90deg)" : "none"),
  },
});

export default DependencyUpdateDialog;
