import { useEffect, useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HeaderTitle, StickyHeadTable, AlertDialog } from "components";
import { accidentColumns } from "utils/dataSetting";

function AccidentList() {
  const [selectDialog, setSelectDialog] = useState(false);
  const [myAccident, setMyAccident] = useState({});
  const { accidents } = useSelector(state => state.accident);
  const { vehicle } = useSelector(state => state.vehicle);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isSameObj = (objA, objB) => {
    return JSON.stringify(objA) === JSON.stringify(objB);
  };

  const handleClickRow = accident => {
    setMyAccident(accident);
    setSelectDialog(true);
  };

  const handleRegisterAccident = async () => {
    const updateVehicle = { ...vehicle, accident: myAccident };
    await dispatch.vehicle.updateVehicle(updateVehicle);
    await dispatch.vehicle.getVehicle({ vehicleId: vehicle.id });
  };

  useEffect(() => {
    if (vehicle?.accident && isSameObj(vehicle.accident, myAccident)) {
      navigate("/participant");
    }
  }, [myAccident, vehicle]);

  useEffect(() => {
    dispatch.accident.getAccidents();
    return () => {
      setSelectDialog(false);
      setMyAccident({});
    };
  }, []);

  return (
    <>
      <HeaderTitle>
        <Typography variant="h4">재난 선택</Typography>
      </HeaderTitle>
      <Box m={4}>
        <StickyHeadTable
          columns={accidentColumns.filter(col => col.id !== "name")}
          rows={accidents}
          onClickRow={handleClickRow}
        />
      </Box>
      <AlertDialog
        title="재난 선택"
        description={`${myAccident?.location?.address} 을 선택하시겠습니까?`}
        onClose={() => {
          setSelectDialog(false);
        }}
        open={selectDialog}
        onAgree={handleRegisterAccident}
      />
    </>
  );
}

export default AccidentList;
