import { useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ParticipantInfo from "pages/participant/info/ParticipantInfo";
import Root from "components/Root";
import VehicleList from "pages/vehicles";
import { JurisdictionList, JurisdictionInfo } from "pages/jurisdiction";
import {
  AccidentInfo,
  AccidentList as AdminAccidentList,
} from "pages/accidents";
import AccidentList from "pages/admin";
import FirePathLayout from "pages/root/FirePathLayout";
import { MAP_CONFIGS } from "configs";
import { BlockerDetail, ParticipantDetail } from "pages/participant/detail";
import Directions from "pages/direction/Directions";
import Register from "pages/Register";
import DemoEngine from "utils/engine/demoEngine";
import RealEngine from "utils/engine/realEngine";
import VideoStreaming from "pages/videostream/VideoStreaming";

const router = createBrowserRouter([
  // admin pages
  {
    path: "/admin",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Navigate to="accidents" replace />,
      },
      {
        path: "accidents",
        element: <AdminAccidentList />,
      },
      {
        path: "accidents/info/:accidentId",
        element: <AccidentInfo />,
      },
      {
        path: "vehicles",
        element: <VehicleList />,
      },
      {
        path: "jurisdiction",
        element: <JurisdictionList />,
      },
      {
        path: "jurisdiction/info/:jurisdictionId",
        element: <JurisdictionInfo />,
      },
    ],
  },
  // fire-path map pages
  {
    path: "/",
    element: <FirePathLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="participant" replace />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "accident",
        element: <AccidentList />,
      },
      {
        path: "participant",
        element: <ParticipantInfo />,
      },
      {
        path: "participant/detail/:participantId",
        element: <ParticipantDetail />,
      },
      {
        path: "blocker",
        element: <BlockerDetail />,
      },
      // {
      //   path: "directions",
      //   element: <Directions />,
      // },
      {
        path: "videostream",
        element: <VideoStreaming />,
      },
    ],
  },
]);

function App() {
  const { scriptLoaded, simulator } = useSelector(state => state.location);
  const { accident } = useSelector(state => state.accident);
  const { vehicle } = useSelector(state => state.vehicle);
  const dispatch = useDispatch();

  useEffect(() => {
    if (vehicle && accident) {
      if (simulator) {
        dispatch.engine.setEngine(new DemoEngine(vehicle, accident));
      } else {
        dispatch.engine.setEngine(new RealEngine(vehicle, accident));
      }
    }
  }, [simulator, vehicle, accident]);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      `https://openapi.map.naver.com/openapi/v3/maps.js?` +
      `ncpClientId=${MAP_CONFIGS.clientId}&submodules=geocoder,drawing,panorama,visualization`;
    document.head.appendChild(script);
    script.onload = () => {
      dispatch.location.setScriptLoaded(true);
    };
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: false, video: true }); // for asking permission
  }, []);

  return scriptLoaded && <RouterProvider router={router} />;
}

export default App;
