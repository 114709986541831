import React, { useEffect, useState } from "react";
import { blockerTypeList } from "constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CardMedia,
  Dialog,
  Grid,
  makeStyles,
} from "@material-ui/core";
import BlockerDialog from "./BlockerUpdate";
import { dateFormat } from "utils";
import { AlertDialog, HeaderTitle } from "components";
import { publishBlocker } from "utils/socket";

function findBlockerType(value) {
  return blockerTypeList.find(blocker => blocker.value === value)?.label;
}
function BlockerDetail() {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accident } = useSelector(state => state.accident);
  const { blocker } = useSelector(state => state.blocker);
  const { vehicle } = useSelector(state => state.vehicle);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [open, setOpen] = useState(false);
  const [isOpenBlockerDialog, setIsOpenBlockerDialog] = useState(false);

  const getNewImage = async () => {
    if (blocker && accident) {
      const data = await dispatch.image.getBlockerImage({
        accidentId: accident.id,
        blockerId: blocker.id,
      });
      if (data.data !== "") {
        setImageSrc(`data:image/png;base64,${data.data}`);
      }
    }
  };

  const onClickDelete = () => {
    setDeleteDialog(true);
  };

  const handleDeleteBlocker = async () => {
    await dispatch.blocker.deleteBlocker({
      accidentId: accident.id,
      blocker,
      vehicleId: vehicle.id,
    });
    publishBlocker(accident.id, blocker.id, "blockerDeleted");
    navigate(`/participant`);
  };

  const handleCloseBlockerDialog = () => {
    setIsOpenBlockerDialog(false);
  };

  useEffect(() => {
    dispatch.blocker.getBlocker({
      accidentId: searchParams.get("accidentId"),
      blockerId: searchParams.get("blockerId"),
    });
  }, [searchParams]);

  useEffect(getNewImage, [blocker, accident]);

  useEffect(() => {
    return () => {
      setImageSrc("");
    };
  }, []);

  if (!blocker || !accident) {
    return null;
  }

  return (
    <>
      <Box>
        <HeaderTitle title="진입 장애 구역 정보" prevPath="/participant" />
        <Box m={4}>
          <Grid
            className={classes.vehicleInfo}
            container
            direction="column"
            spacing={1}
          >
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.contentRow}
            >
              <Grid item xs={4}>
                <Box className="vehicle-label">진입 장애 구분</Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="vehicle-value">
                  {findBlockerType(blocker?.category)}
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.contentRow}
            >
              <Grid item xs={4}>
                <Box className="vehicle-label">진입 장애 사유</Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="vehicle-value">{blocker?.reason}</Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.contentRow}
            >
              <Grid item xs={4}>
                <Box className="vehicle-label">진입 장애 지점</Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="vehicle-value">
                  {blocker?.location?.address}
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.contentRow}
            >
              <Grid item xs={4}>
                <Box className="vehicle-label">생성한 차량명</Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="vehicle-value">{blocker?.vehicleNumber}</Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.contentRow}
            >
              <Grid item xs={4}>
                <Box className="vehicle-label">생성 시간</Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="vehicle-value">
                  {dateFormat(blocker?.createdAt, "YYYY년 MM월 DD일 HH:mm:ss")}
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.contentRow}
            >
              <Grid item xs={4}>
                <Box className="vehicle-label">갱신 시간</Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="vehicle-value">
                  {dateFormat(
                    blocker?.updatedAt || blocker?.createdAt,
                    "YYYY년 MM월 DD일 HH:mm:ss",
                  )}
                </Box>
              </Grid>
            </Grid>
            <CardMedia
              className={classes.media}
              component="img"
              src={imageSrc}
              onClick={() => setOpen(true)}
            />
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => setIsOpenBlockerDialog(true)}
              className={classes.updateButton}
            >
              장애 구역 수정
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={onClickDelete}
              className={classes.deleteButton}
            >
              장애 구역 삭제
            </Button>
          </Grid>
        </Box>
      </Box>
      <AlertDialog
        title="진입 장애 구역 삭제"
        description="진입 장애 구역을 삭제하시겠습니까?"
        onClose={() => {
          setDeleteDialog(false);
        }}
        open={deleteDialog}
        onAgree={handleDeleteBlocker}
      />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <CardMedia
          className={classes.dialogMedia}
          component="img"
          src={imageSrc}
        />
      </Dialog>
      <BlockerDialog
        modalVisible={isOpenBlockerDialog}
        onCloseModal={handleCloseBlockerDialog}
        newBlocker={blocker}
        accidentId={accident?.id}
      />
    </>
  );
}

const useStyles = makeStyles({
  body: {},
  vehicleInfo: {
    "& .vehicle-label": {
      fontWeight: 600,
    },
    "& .vehicle-value": {},
  },
  contentRow: {
    paddingTop: "1rem",
  },
  updateButton: {
    marginTop: "2rem",
  },
  deleteButton: {
    marginTop: "0.5rem",
  },
  media: {
    marginTop: "1rem",
  },
});

export default BlockerDetail;
