import { useEffect, useState } from "react";
import { engineClassName, pathTitles } from "constants";
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { Error } from "@material-ui/icons";
import {
  checkOverlap,
  createInfoWindowContent,
  focusPath,
  getArrivalTime,
  getDistance,
  getTime,
  removePathList,
  setCenterEffect,
} from "utils/naverMaps";
import { CustomButton } from "components";
import { publish } from "utils/socket";

function PathList({ map, points, vehicleNumber }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    setWaypoint,
    addPaths,
    clearPath,
    selectPath,
    setPathList,
    setNavigatingWaypoint,
    setWaypointActive,
  } = dispatch.path;
  const { waypoint, paths, pathList, navigatingWaypoint } = useSelector(
    state => state.path,
  );
  const { vehicle } = useSelector(state => state.vehicle);
  const { accident } = useSelector(state => state.accident);
  const { blockers } = useSelector(state => state.blocker);
  const { markers } = useSelector(state => state.location);
  const { engine } = useSelector(state => state.engine);
  const [selectedPath, setSelectedPath] = useState(0);

  const onClickPath = index => {
    if (pathList.length === 0) {
      return;
    }
    pathList[selectedPath]?.polylines.forEach(item => {
      item.polyline.setOptions({
        strokeColor: "gray",
        zIndex: 2,
      });
      item.borderline.setOptions({
        strokeColor: "gray",
        zIndex: 1,
      });
    });

    pathList[selectedPath]?.infoWindow.close();
    pathList[index].polylines.forEach(item => {
      item.polyline.setOptions({
        strokeColor: item.color,
        zIndex: 4,
      });
      item.borderline.setOptions({
        strokeColor: "black",
        zIndex: 3,
      });
    });
    const clickedPath = pathList[index];
    clickedPath.infoWindow.setContent(
      createInfoWindowContent(index + 1, clickedPath.pathData.summary.duration),
    );
    clickedPath.infoWindow.open(map);
    dispatch.location.deleteFocusId();
    focusPath(pathList[index].polylines, map);
    setSelectedPath(index);
  };

  const onClickNavStart = async () => {
    if (!(pathList.length > 0)) {
      return;
    }
    if (paths[vehicle.id]) {
      removePathList(paths[vehicle.id].paths.polylines);
    }
    if (engine?.getName() === engineClassName.demo) {
      engine.setPathIndex(0);
    }
    const selected = selectPath(selectedPath);
    addPaths({
      id: vehicleNumber,
      paths: selected,
      start: points.start,
      goal: points.goal,
      waypoint: points.waypoint,
    });
    setPathList([]);
    setNavigatingWaypoint(waypoint);
    const myVehicleId = localStorage.getItem("myVehicleId");
    const marker = dispatch.location.getMarkerById({ id: myVehicleId });
    map.setZoom(17);
    setCenterEffect(map, marker);
    dispatch.location.setFocusId({ id: myVehicleId });
    const stringPath = JSON.stringify({
      path: selected.pathData.path,
      start: points.start,
      goal: points.goal,
      waypoint: points.waypoint,
    });
    await dispatch.vehicle.updateVehicle({
      ...vehicle,
      path: stringPath,
    });
    publish(
      JSON.stringify(
        {
          type: "pathStart",
          topicId: accident.id,
          contents: "",
          sender: myVehicleId,
          createdAt: Date.now(),
        },
        "/pub/message",
      ),
    );
  };

  const onClickPathClose = () => {
    clearPath();
    setWaypoint(navigatingWaypoint);
    if (navigatingWaypoint?.address === "") {
      setWaypointActive(false);
    }
  };

  useEffect(() => {
    onClickPath(0);
  }, [pathList]);

  useHotkeys("shift+1", () => onClickPath(0), {
    scopes: ["directions"],
    keyup: true,
    preventDefault: true,
  });

  useHotkeys("shift+2", () => onClickPath(1), {
    scopes: ["directions"],
    keyup: true,
    preventDefault: true,
  });

  useHotkeys("shift+3", () => onClickPath(2), {
    scopes: ["directions"],
    keyup: true,
    preventDefault: true,
  });

  useHotkeys("shift+n", onClickNavStart, {
    scopes: ["directions"],
    keyup: true,
    preventDefault: true,
  });

  return (
    <Box m={4}>
      <Grid container direction="column" spacing={2}>
        {pathList.map((item, index) => (
          <Box
            id={item.id}
            key={item.id}
            className={
              index === selectedPath ? classes.selectedPath : classes.pathList
            }
            onClick={() => {
              onClickPath(index);
            }}
          >
            <Typography className={classes.title}>
              <span className={classes.titleNumber}>{index + 1}</span>
              {pathTitles[item.id] || `우회 경로 ${index + 1}`}
              {blockers.some(blocker =>
                checkOverlap(item.pathData.path, markers[blocker.id]),
              ) && (
                <CustomButton title="경로에 진입 장애 구역이 있습니다.">
                  <Error className={classes.errorIcon} />
                </CustomButton>
              )}
            </Typography>
            <Typography>
              시간 : {getTime(item.pathData.summary.duration)}
            </Typography>
            <Typography>
              도착 예상 시간 : {getArrivalTime(item.pathData.summary.duration)}
            </Typography>
            <Typography>
              거리 : {getDistance(item.pathData.summary.distance)}
            </Typography>
            <Divider className={classes.divider} />
            <Grid container className={classes.sectionContainer}>
              {item.pathData.section?.map((section, idx) => (
                <Grid item className={classes.section} key={section.pointIndex}>
                  {section.name} {getDistance(section.distance)}
                  {idx !== item.pathData.section.length - 1 && " ->"}
                </Grid>
              ))}
            </Grid>
          </Box>
        ))}
        {pathList.length !== 0 && (
          <Grid container spacing={1} className={classes.pathButtons}>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => {
                  onClickNavStart();
                }}
              >
                안내 시작
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="secondary"
                fullWidth
                onClick={onClickPathClose}
              >
                취소
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles({
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleNumber: {
    width: "20px",
    height: "20px",
    backgroundColor: "#007AFF",
    color: "white",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  selectedPath: {
    marginBottom: "5px",
    padding: "10px",
    border: "1px solid gray",
    backgroundColor: "rgba(115, 136, 255, 0.2)",
  },
  pathList: {
    marginBottom: "5px",
    padding: "10px",
    border: "1px solid gray",
  },
  divider: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  sectionContainer: {
    direction: "row",
  },
  section: {
    marginRight: "10px",
  },
  errorIcon: {
    marginLeft: "10px",
    color: "red",
  },
  pathButtons: {
    paddingTop: "10px",
  },
});

export default PathList;
