import { noop } from "constants";
import { client } from "stores/location";

export function publish(body, dest = "/pub/message", errorCallback = noop) {
  try {
    client.publish({
      destination: dest,
      body,
    });
  } catch (err) {
    errorCallback(err);
  }
}

export function publishBlocker(
  accidentId,
  blockerId,
  type,
  dest = "/pub/message",
) {
  publish(
    JSON.stringify(
      {
        type,
        topicId: accidentId,
        contents: "",
        sender: blockerId,
        createdAt: Date.now(),
      },
      dest,
    ),
  );
}
