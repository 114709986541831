import { useEffect } from "react";
import {
  Box,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Switch,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

function PathViewSwitch() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathView } = useSelector(state => state.path);

  const onChangePathView = () => {
    dispatch.path.setPathView(!pathView);
  };

  useEffect(() => {
    if (pathView) {
      dispatch.path.showOtherPath();
    } else {
      dispatch.path.hideOtherPath();
    }
  }, [pathView]);

  return (
    <Box className={classes.pathViewSwitch}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              name="PathView"
              checked={pathView}
              onChange={onChangePathView}
            />
          }
          label="다른 차량 경로 보기"
        />
      </FormGroup>
    </Box>
  );
}

const useStyles = makeStyles({});

export default PathViewSwitch;
