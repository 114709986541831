import React, { useRef, useState } from "react";
import { noop, initLocation } from "constants";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Fab,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {
  DriveEta,
  ExitToApp,
  Videocam,
  LocationOn,
  Search,
  Refresh,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  DrawPolylineList,
  isAdmin,
  paintBlueSelectedVehicle,
  reverseGeocode,
  setCenterEffect,
} from "utils/naverMaps";
import { publish } from "utils/socket";
import { getScreenStream, getWebcamStream } from "utils/webRTC";
import { startRecord } from "utils/video";

export default function VehicleController({
  departOnClick = noop,
  accidentOnClick = noop,
  finishOnClick = noop,
  logined = false,
}) {
  const dispatch = useDispatch();
  const { addPaths, setStartPoint, setGoalPoint, clearPath } = dispatch.path;
  const classes = useStyles();
  const { accident } = useSelector(state => state.accident);
  const { vehicle, isDepart } = useSelector(state => state.vehicle);
  const [openDialog, setOpenDialog] = useState(false);
  const { videoVisible } = useSelector(state => state.controller);
  const { engine } = useSelector(state => state.engine);
  const { map, sidebarExpanded } = useSelector(state => state.location);
  const { paths } = useSelector(state => state.path);

  const navErrorCnt = useRef(0);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const recordWebcam = async () => {
    const stream = await getWebcamStream("high");
    startRecord(stream, "webcam", (e, recordedBlob) => {
      enqueueSnackbar(
        "녹화영상을 업로드 하고 있습니다. 창을 닫거나 새로고침 하지말아주세요.",
        {
          variant: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          persist: true,
        },
      );
      const formData = new FormData();
      formData.append("file", recordedBlob, "record.webm");
      dispatch.video.uploadVideo({
        vehicleId: vehicle.id,
        body: formData,
      });
    });
  };

  const recordScreen = async () => {
    const stream = await getScreenStream();
    startRecord(stream, "screen", (e, recordedBlob) => {
      enqueueSnackbar(
        "녹화영상을 업로드 하고 있습니다. 창을 닫거나 새로고침 하지말아주세요.",
        {
          variant: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          persist: true,
        },
      );
      const formData = new FormData();
      formData.append("file", recordedBlob, "screen.webm");
      dispatch.video.uploadVideo({
        vehicleId: vehicle.id,
        body: formData,
      });
    });
  };

  const handleDepart = async () => {
    // only can start navigation after map is loaded
    if (!map) {
      return;
    }
    recordWebcam();
    recordScreen();
    handleStartNav();
    departOnClick();
  };

  const handleFinish = () => {
    setOpenDialog(false);
    finishOnClick();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleReload = () => {
    setOpenDialog(false);
    finishOnClick();
    window.location.reload();
  };

  const handleVideoButton = () => {
    if (isDepart) {
      dispatch.controller.setVideoVisible(!videoVisible);
      if (sidebarExpanded && !videoVisible) {
        navigate("/videostream");
      } else if (!videoVisible) {
        dispatch.location.setSidebarExpanded(true);
        navigate("/videostream");
      } else {
        dispatch.location.setSidebarExpanded(false);
      }
    }
  };

  const getStartFrom = position => {
    return {
      latitude: position.latitude,
      longitude: position.longitude,
      address: position.result.items[0].address,
      verified: true,
    };
  };

  const getGoalFrom = async () => {
    const result = await dispatch.vehicle.getVehicle({ vehicleId: vehicle.id });
    // console.log(accident);
    return {
      // ...accident?.location,
      ...result.accident.location,
      verified: true,
    };
  };

  const findFastestPath = async response => {
    const start = getStartFrom(response);
    const goal = await getGoalFrom();
    if (
      !(
        start.latitude &&
        start.longitude &&
        goal.latitude &&
        goal.longitude &&
        vehicle &&
        map
      )
    ) {
      return { pathObj: undefined, start, goal };
    }
    const data = await dispatch.path.getPaths({
      paths: {
        start,
        goal,
      },
    });
    if (data.code === 0) {
      const pathData = data.route.trafast[0];
      const pathObj = {
        id: "trafast",
        pathData,
        // polylines: DrawPolylineList(pathData, map),
      };
      return { pathObj, start, goal };
    }
    return { pathObj: undefined, start, goal };
  };

  const updateVehicle = async (pathObj, start, goal) => {
    const newVehicle = await dispatch.vehicle.getVehicle({
      vehicleId: vehicle.id,
    });
    await dispatch.vehicle.updateVehicle({
      ...newVehicle,
      departAt: new Date().getTime(),
      arrivedAt: null,
      finishedAt: null,
      status: "active",
      path: JSON.stringify({
        path: pathObj?.pathData?.path,
        start,
        goal,
      }),
    });
  };

  const focusVehicle = () => {
    const marker = dispatch.location.getMarkerById({ id: vehicle.id });
    dispatch.location.setFocusId(vehicle);
    setCenterEffect(map, marker);
  };

  const handlePointIsEmpty = point => {
    enqueueSnackbar(`${point}가 설정이 안되어 있습니다. 다시 탐색합니다.`, {
      variant: "error",
      autoHideDuration: 3000,
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
    navErrorCnt.current += 1;
    setTimeout(() => {
      startNavigationWithDefaultStartAndGoal();
    }, [2000]);
  };

  const handlePathNotFound = () => {
    enqueueSnackbar("경로 탐색 실패. 다시 탐색합니다.", {
      variant: "error",
      autoHideDuration: 3000,
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
    navErrorCnt.current += 1;
    setTimeout(() => {
      startNavigationWithDefaultStartAndGoal();
    }, [2000]);
  };

  const startNavigationWithDefaultStartAndGoal = async () => {
    await engine.getCurrentPosition(
      async position => {
        reverseGeocode(
          position.latitude,
          position.longitude,
          async (response, _) => {
            const { pathObj, start, goal } = await findFastestPath(response);
            console.log(pathObj, start, goal);
            if (navErrorCnt.current === 3) {
              enqueueSnackbar(
                "경로 탐색에 실패하였습니다. 길찾기 버튼을 눌러 새로운 경로를 탐색해주세요.",
                {
                  variant: "warning",
                  autoHideDuration: 10000,
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                },
              );
              navErrorCnt.current = 0;
              return;
            }
            if (!pathObj) {
              handlePathNotFound();
              return;
            }
            if (!start || !start.latitude || !start.longitude) {
              handlePointIsEmpty("출발지");
              return;
            }
            if (!goal || !goal.latitude || !goal.longitude) {
              handlePointIsEmpty("목적지");
              return;
            }

            const vehiclePath = {
              id: vehicle.id,
              paths: pathObj,
              start,
              goal,
            };
            addPaths(vehiclePath);
            setStartPoint(start);
            setGoalPoint(goal);
            await updateVehicle(pathObj, start, goal);
            focusVehicle();
            paintBlueSelectedVehicle(vehiclePath);

            publish(
              JSON.stringify(
                {
                  type: "pathStart",
                  topicId: accident.id,
                  contents: "",
                  sender: vehicle.id,
                  createdAt: Date.now(),
                },
                "/pub/message",
              ),
            );
          },
        );
      },
      e => {
        // if (e.code === 3) {
        //   // retry if geolocation timeout occurred
        //   startNavigationWithDefaultStartAndGoal();
        // }
        console.log(e);
        setTimeout(() => {
          startNavigationWithDefaultStartAndGoal();
        }, 1000);
      },
    );
  };

  const removeLastPath = () => {
    if (vehicle.id in paths) {
      clearPath();
      setStartPoint(initLocation);
      setGoalPoint(initLocation);
      updateVehicle(null, initLocation, initLocation);
      publish(
        JSON.stringify(
          {
            type: "pathEnd",
            topicId: accident.id,
            contents: "",
            sender: vehicle.id,
            createdAt: Date.now(),
          },
          "/pub/message",
        ),
      );
    }
  };

  const handleStartNav = async () => {
    if (isAdmin(vehicle.id)) {
      return;
    }
    removeLastPath();
    // navigation start
    startNavigationWithDefaultStartAndGoal();
  };

  return (
    <Box>
      <Fab
        className={classes.button}
        variant="extended"
        color={!isDepart ? "secondary" : "primary"}
        onClick={!isDepart ? handleDepart : handleOpenDialog}
        disabled={!logined || openDialog}
      >
        {!isDepart ? (
          <DriveEta className={classes.icon} />
        ) : (
          <ExitToApp className={classes.icon} />
        )}
        {!isDepart ? "출동" : "상황종료"}
      </Fab>
      <Fab
        className={classes.button}
        variant="extended"
        onClick={accidentOnClick}
        disabled={!logined || openDialog}
      >
        <LocationOn className={classes.icon} />
        재난위치
      </Fab>
      <Fab
        className={classes.button}
        variant="extended"
        onClick={handleVideoButton}
        disabled={!logined || openDialog || !isDepart}
      >
        <Videocam className={classes.icon} />
        {videoVisible ? " 영상 숨기기" : " 영상 보이기"}
      </Fab>
      <Fab
        className={classes.button}
        variant="extended"
        onClick={handleStartNav}
        disabled={!logined || openDialog || !isDepart}
      >
        <Search className={classes.icon} />
        길찾기
      </Fab>
      <Fab className={classes.button} variant="extended" onClick={handleReload}>
        <Refresh className={classes.icon} />
        페이지 새로고침
      </Fab>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
        <DialogTitle> 상황을 종료합니다 </DialogTitle>
        <DialogContent>
          <DialogContentText>
            상황을 종료하려면 확인을 클릭하세요
            <br />
            상황을 종료하지 않으려면 취소를 클릭하세요
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleFinish}
          >
            확인
          </Button>
          <Button
            size="small"
            variant="contained"
            color="secondary"
            onClick={handleCloseDialog}
          >
            취소
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

const useStyles = makeStyles({
  button: {
    // width: 180,
    // height: 135,
    marginRight: "0.3rem",
    marginLeft: "0.3rem",
    // fontSize: 32,
  },
  icon: {
    marginRight: "0.2rem",
  },
});
