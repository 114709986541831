import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import {
  ChevronLeft,
  AirportShuttle,
  Warning,
  Fireplace,
} from "@material-ui/icons";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Outlet, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: props => (props.sideBar ? drawerWidth : 0),
      flexShrink: 0,
    },
  },
  appBar: {
    width: props =>
      props.sideBar && props.xsUp ? `calc(100% - ${drawerWidth}px)` : "100%",
    marginLeft: props => (props.sideBar && props.xsUp ? drawerWidth : 0),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  arrowButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  wideDrawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    margin: "auto",
    minWidth: "300px",
    maxWidth: "1000px",
    justifyContent: "center",
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [sideBar, setSideBar] = React.useState(true);
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const xsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const classes = useStyles({ sideBar, xsDown });

  const handleDrawerToggle = () => {
    if (xsDown) {
      setMobileOpen(!mobileOpen);
    } else if (xsUp) {
      setSideBar(!sideBar);
    }
  };

  const drawer = (
    <div>
      <div
        className={classes.toolbar}
        style={{ display: "flex", alignItems: "center" }}
      >
        <IconButton
          style={{ marginLeft: "auto" }}
          onClick={() => {
            setMobileOpen(false);
            setSideBar(false);
          }}
        >
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem
          button
          key="Accident"
          onClick={() => {
            navigate("/admin/accidents");
          }}
        >
          <ListItemIcon>
            <Warning />
          </ListItemIcon>
          <ListItemText primary="재난" />
        </ListItem>
        <ListItem
          button
          key="Vehicle"
          onClick={() => {
            navigate("/admin/vehicles");
          }}
        >
          <ListItemIcon>
            <AirportShuttle />
          </ListItemIcon>
          <ListItemText primary="차량" />
        </ListItem>
        <ListItem
          button
          key="Jurisdiction"
          onClick={() => {
            navigate("/admin/jurisdiction");
          }}
        >
          <ListItemIcon>
            <Fireplace />
          </ListItemIcon>
          <ListItemText primary="관할서" />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {xsDown && (
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        )}
        {sideBar && (
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open={sideBar}
            >
              {drawer}
            </Drawer>
          </Hidden>
        )}
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Outlet />
      </main>
    </div>
  );
}

export default ResponsiveDrawer;
