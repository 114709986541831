import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton, HeaderTitle, RefreshButton } from "components";
import {
  checkConnectionStatus,
  getRemoteStream,
  createOffer,
  getWebcamStream,
} from "utils/webRTC";
import useInterval from "hooks/useInterval";

function ParticipantDetail() {
  const classes = useStyles();
  const { participantId } = useParams();
  const dispatch = useDispatch();
  const { vehicle, participatedVehicle } = useSelector(state => state.vehicle);
  const { accident } = useSelector(state => state.accident);
  const [refresh, setRefresh] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [open, setOpen] = useState(false);
  const [connected, setConnected] = useState(false);
  const videoSectionRef = useRef();

  const getNewImage = async () => {
    if (participatedVehicle) {
      const data = await dispatch.image.getVehicleImage({
        vehicleNumber: participantId,
      });
      if (data.data !== "") {
        setImageSrc(`data:image/png;base64,${data.data}`);
        const result = await dispatch.inference.inferenceRoadImg({
          image: data.data,
          vehicle: participatedVehicle,
        });
        dispatch.inference.setInferenceResult(result[0]);
      }
    }
    setRefresh(false);
  };

  const onClickRefresh = () => {
    setRefresh(true);
    showVideo();
    setRefresh(false);
    // getNewImage();
  };
  const showVideo = async () => {
    if (vehicle.id === participantId) {
      const localStream = await getWebcamStream("high");
      videoSectionRef.current.srcObject = localStream;
    } else {
      const remoteStream = getRemoteStream(participantId);
      videoSectionRef.current.srcObject = remoteStream;
    }
  };
  useEffect(() => {
    dispatch.vehicle.getVehicle({ vehicleId: participantId, detail: true });
    setConnected(false);
    if (vehicle.id && accident.id) {
      createOffer(participantId, vehicle.id, accident.id, videoSectionRef);
    }
  }, [participantId]);

  // useEffect(getNewImage, [participatedVehicle]);

  useEffect(() => {
    return () => {
      setImageSrc("");
    };
  }, []);

  useInterval(() => {
    setConnected(checkConnectionStatus(participantId));
  }, [1000]);
  return (
    <>
      <Box>
        <HeaderTitle title="재난 참여 차량 정보" prevPath="/participant" />
        <Box m={4}>
          <Grid
            className={classes.vehicleInfo}
            container
            direction="column"
            spacing={1}
          >
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.contentRow}
            >
              <Grid item xs={4}>
                <Box className="vehicle-label">차량명</Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="vehicle-value">{participatedVehicle?.id}</Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.contentRow}
            >
              <Grid item xs={4}>
                <Box className="vehicle-label">차량 종류</Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="vehicle-value">
                  {/* {translateVehicle[participatedVehicle?.type]} */}
                  {participatedVehicle?.type}
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              spacing={1}
              className={classes.contentRow}
            >
              <Grid item xs={4}>
                <Box className="vehicle-label">관할서</Box>
              </Grid>
              <Grid item xs={8}>
                <Box className="vehicle-value">
                  {/* {participatedVehicle?.jurisdiction?.name} */}
                  {participatedVehicle?.jurisdiction}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Card>
            <CardHeader
              title={
                connected || vehicle.id === participantId
                  ? `${participantId} 영상`
                  : "연결 중..."
              }
              action={
                <CustomButton
                  title="차량 영상 새로고침"
                  onClick={onClickRefresh}
                >
                  <RefreshButton loading={refresh} />
                </CustomButton>
              }
            />
            <CardMedia component="video" ref={videoSectionRef} autoPlay />
          </Card>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <CardMedia
          className={classes.dialogMedia}
          component="img"
          src={imageSrc}
        />
      </Dialog>
    </>
  );
}

const useStyles = makeStyles({
  body: {},
  vehicleInfo: {
    "& .vehicle-label": {
      fontWeight: 600,
    },
    "& .vehicle-value": {},
  },
  contentRow: {
    paddingTop: "1rem",
  },
  card: {
    marginTop: "2rem",
  },
});

export default ParticipantDetail;
