export const appVersion = "v1.7";
export const initLocation = {
  latitude: 0,
  longitude: 0,
  address: "",
  verified: false,
};

export const mapConfig = {
  zoom: 17,
  zoomControl: false,
};

export const initPoints = {
  start: initLocation,
  goal: initLocation,
};

export const initError = {
  error: false,
  label: "",
};

export const defaultPolylineStyle = {
  strokeColor: "gray",
  zIndex: 0,
};

export const accidentCategoryList = [
  {
    label: "화재",
    value: "fire",
    children: [
      { label: "일반화재", value: "fire" },
      { label: "기타", value: "etc" },
    ],
  },
  {
    label: "구급",
    value: "emergency",
    children: [
      { label: "부상", value: "injure" },
      { label: "질병", value: "disease" },
      { label: "심정지", value: "heartAttack" },
      { label: "기타", value: "etc" },
    ],
  },
  {
    label: "구조",
    value: "rescue",
    children: [
      { label: "승강기안전사고", value: "elevatorAccident" },
      { label: "기타안전사고", value: "etc" },
    ],
  },
  {
    label: "기타",
    value: "extra",
    children: [
      { label: "벌집안전조치", value: "honeycomb" },
      { label: "기타재해", value: "etc" },
    ],
  },
];

export const vehicleTypeList = [
  {
    label: "소방차",
    value: "firetruck",
  },
  {
    label: "펌프차(2000)",
    value: "firetruck",
  },
  {
    label: "응급차",
    value: "ambulance",
  },
];

export const accidentStatusList = [
  {
    label: "준비",
    value: "ready",
  },
  {
    label: "진행중",
    value: "proceeding",
  },
  {
    label: "종료",
    value: "end",
  },
];

export const blockerTypeList = [
  {
    label: "불법 주정차",
    value: "illegalParking",
  },
  {
    label: "도로 혼잡",
    value: "roadCongestion",
  },
  {
    label: "기타",
    value: "etc",
  },
];

export const translateVehicle = { firetruck: "소방차", ambulance: "응급차" };

export const translateAccident = {
  fire: "화재",
  fire1: "화재1",
  fire2: "화재2",

  emergency: "응급",
  patient: "환자",
  disease: "질병",

  proceeding: "진행중",
  ready: "준비중",
  end: "종료",
};

// hotkeys

export const participantInfoHotKeys = {
  title: "재난 정보 단축키",
  mappings: [
    {
      name: "단축키 창 열기/닫기",
      key: "H",
    },
    {
      name: "재난 정보창 이동",
      key: "1",
    },
    {
      name: "길찾기 이동",
      key: "2",
    },
    {
      name: "사이드 창 열기/닫기",
      key: "W",
    },
    {
      name: "재난 정보 새로 고침",
      key: "R",
    },
    {
      name: "현재 위치로 이동",
      key: "shift+R",
    },
    {
      name: "재난 지점 위치로 이동",
      key: "G",
    },
    {
      name: "뒤로 가기",
      key: "B",
    },
    {
      name: "현재 위치에 진입 장애 구역 추가",
      key: "space",
    },
  ],
};

export const directionsHotKeys = {
  title: "길찾기 단축키",
  mappings: [
    {
      name: "단축키 창 열기/닫기",
      key: "H",
    },
    {
      name: "재난 정보창 이동",
      key: "1",
    },
    {
      name: "길찾기 창 이동",
      key: "2",
    },
    {
      name: "사이드 창 열기/닫기",
      key: "W",
    },
    {
      name: "현재 위치로 이동",
      key: "shift+R",
    },
    {
      name: "출발지 입력",
      key: "S",
    },
    {
      name: "목적지 입력",
      key: "G",
    },
    {
      name: "출발지 목적지 교환",
      key: "C",
    },
    {
      name: "경유지 생성",
      key: "shift+W",
    },
    {
      name: "경유지 제거",
      key: "shift+E",
    },
    {
      name: "출발지 현재 위치로 설정",
      key: "shift+S",
    },
    {
      name: "목적지 재난 발생 위치 설정",
      key: "shift+G",
    },
    { name: "출발지 지도에서 직접 선택", key: "alt+S" },
    { name: "목적지 지도에서 직접 선택", key: "alt+G" },
    { name: "경유지 지도에서 직접 선택", key: "alt+W" },
    { name: "위치 직접 선택 취소", key: "esc" },
    {
      name: "(길찾기 후) 실시간 추천 경로 선택",
      key: "shift+1",
    },
    {
      name: "(길찾기 후) 최단 시간 경로 선택",
      key: "shift+2",
    },
    {
      name: "(길찾기 후) 편한길 경로 선택",
      key: "shift+3",
    },
    {
      name: "길찾기, (길찾기 후) 안내종료",
      key: "N",
    },
    {
      name: "(길찾기 후) 안내시작",
      key: "shift+N",
    },
    {
      name: "현재 위치에 진입 장애 구역 추가",
      key: "space",
    },
  ],
};

export const currentPolylineOptions = {
  strokeColor: "#007aff",
  zIndex: 2,
};
export const currentBorderLineOptions = {
  strokeColor: "black",
  zIndex: 1,
};

const style = `<style>
#rightClickPanel {
  width: 150px;
  marginLeft: 5px;
}

.menu-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding: 5px;
}

.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-list li {
  margin-bottom: 5px;
}

.menu-item {
  padding: 5px;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #e0e0e0;
}
</style>`;

export const mapRightClickContent = `
<div id="rightClickPanel">
  <div class="menu-title">장애구역 추가</div>
    <ul class="menu-list">
      <li>
        <div id="menu1" class="menu-item" value="illegalParking">불법 주정차</div>
      </li>
      <li>
        <div id="menu2" class="menu-item" value="roadCongestion">도로 혼잡</div>
      </li>
    </ul>
  </div>
</div>
${style}
`;

export const markerRigthClickContent = `
<div id="rightClickPanel">
  <div class="menu-title">장애구역</div>
    <ul class="menu-list">
      <li>
        <div id="menu1" class="menu-item" value="delete">제거</div>
      </li>
    </ul>
  </div>
</div>
${style}
`;

export const snackbarOption = {
  anchorOrigin: { vertical: "bottom", horizontal: "center" },
};

export const engineClassName = {
  real: "RealEngine",
  demo: "DemoEngine",
};

export const noop = () => {};

export const pathTitles = {
  traoptimal: "실시간 추천",
  trafast: "최단 시간",
  tracomfort: "편한 길",
};

export const device = {
  RTK: "RTK",
  GPS: "GPS",
  SIMULATOR: "SIMULATOR",
};

export const markerType = {
  vehicle: "vehicle",
  accident: "accident",
  blocker: "blocker",
  etc: "etc",
};

export const lowVideoConfig = {
  width: { min: 256, max: 854 },
  height: { min: 144, max: 480 },
  frameRate: { max: 10 },
};

export const highVideoConfig = {
  width: { max: 1280 },
  height: { max: 720 },
  frameRate: { max: 10 },
};

export const geolocationTimeout = 1000;

export const geolocationConfig = {
  enableHighAccuracy: false,
  timeout: geolocationTimeout,
  maximumAge: 0,
};
