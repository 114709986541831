import { useEffect } from "react";
import { engineClassName } from "constants";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Switch,
  makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

function SimulatorSwitch() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { simulator } = useSelector(state => state.location);
  const { vehicle } = useSelector(state => state.vehicle);
  const { paths } = useSelector(state => state.path);
  const { engine } = useSelector(state => state.engine);

  const onChangeSimulator = () => {
    dispatch.location.toggleSimulator();
  };

  const checkIsDemo = () => {
    if (engine?.getName() === engineClassName.demo) {
      return true;
    }
    return false;
  };

  const move = type => {
    if (simulator && type !== "" && engine.pathCoords.length > 0) {
      if (type === "forward") {
        // move forward
        engine.setPathIndex((engine.pathIndex + 1) % engine.pathCoords.length);
      } else if (type === "back") {
        // move back
        engine.setPathIndex(
          (engine.pathIndex - 1 + engine.pathCoords.length) %
            engine.pathCoords.length,
        );
      }
    }
  };

  const getArrowKeyDirection = e => {
    const type = e.code;
    if (type === "ArrowRight") {
      return "forward";
    }
    if (type === "ArrowLeft") {
      return "back";
    }
    return "";
  };

  const keyDownListener = e => {
    const direction = getArrowKeyDirection(e);
    move(direction);
  };

  const cleanup = () => {
    if (checkIsDemo()) {
      engine.setPathCoords([]);
      engine.setPathIndex(-1);
    }
    document.removeEventListener("keydown", keyDownListener);
  };

  useEffect(() => {
    if (!checkIsDemo()) {
      return () => {};
    }
    if (simulator) {
      document.addEventListener("keydown", keyDownListener);
    } else {
      cleanup();
    }
    return () => {
      cleanup();
    };
  }, [simulator, engine]);

  useEffect(() => {
    if (simulator && vehicle && checkIsDemo()) {
      if (Object.keys(paths).length === 0) {
        engine.setPathCoords([]);
        engine.setPathIndex(-1);
      } else {
        engine.setPathCoords(paths[vehicle.id]?.paths.pathData.path);
      }
    }
  }, [simulator, paths, vehicle, engine]);

  return (
    <Box className={classes.simulatorSwitch}>
      <FormGroup row>
        <FormControlLabel
          control={
            <Switch
              checked={simulator}
              onChange={onChangeSimulator}
              name="Simulator"
            />
          }
          label="시뮬레이터"
        />
      </FormGroup>
    </Box>
  );
}

const useStyles = makeStyles({
  simulatorSwitch: {},
});

export default SimulatorSwitch;
