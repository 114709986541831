import React, { useEffect, useState } from "react";
import {
  markerRigthClickContent,
  markerType,
  device as deviceType,
} from "constants";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Icon,
  Tooltip,
} from "@material-ui/core";
import { Adjust, ArrowLeft, ArrowRight } from "@material-ui/icons";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { PathViewSwitch, Tab } from "components";
import { createMarker, getPosition } from "utils/naverMaps";
import { publishBlocker } from "utils/socket";
import findBlockerType from "utils/blocker";

function MainPage() {
  const classes = useStyles();
  const navi = useNavigate();

  const dispatch = useDispatch();
  const { accident } = useSelector(state => state.accident);
  const { vehicle, accidentVehicles } = useSelector(state => state.vehicle);
  const { map, scriptLoaded, sidebarExpanded } = useSelector(
    state => state.location,
  );
  const { blockers } = useSelector(state => state.blocker);
  const { device } = useSelector(state => state.engine);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickExpand = () => dispatch.location.toggleSidebar();

  const getParticipantInfos = async () => {
    if (vehicle.accident) {
      await dispatch.accident.getAccident({ id: vehicle.accident.id });
      await dispatch.vehicle.getAccidentVehicles({
        accidentId: vehicle.accident.id,
      });
      await dispatch.blocker.getBlockers({ accidentId: vehicle.accident.id });
    }
  };

  const toolTipMessage = () => {
    switch (device) {
      case deviceType.GPS:
        return "눌러서 RTK를 연결하세요";
      case deviceType.RTK:
        return "이미 RTK에 연결되어 있습니다";
      case deviceType.SIMULATOR:
        return "시뮬레이터를 실행중입니다";
      default:
        return "ERROR OCCURED";
    }
  };

  const handleClickGPS = async () => {
    await navigator.serial.requestPort();
    // const ports = await navigator.serial.getPorts();
    // if (ports.length === 0) {
    //   await navigator.serial.requestPort();
    // } else {
    //   setDialogOpen(true);
    // }
  };

  const handleCancelRequest = async () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (scriptLoaded && blockers.length > 0) {
      blockers.forEach(blocker => {
        const blockerMarker = dispatch.location.getMarkerById({
          id: blocker.id,
        });
        // prevent duplicated blocker markers
        if (!blockerMarker) {
          dispatch.location.addMarker({
            id: blocker.id,
            marker: createMarker({
              position: getPosition(
                blocker.location.latitude,
                blocker.location.longitude,
              ),
              map,
              markerType: markerType.blocker,
              icon: blocker?.category,
              callback: () => {
                navi({
                  pathname: `/blocker`,
                  search: `?accidentId=${blocker.accident.id}&blockerId=${blocker.id}`,
                });
              },
              rightClickContent: markerRigthClickContent,
              callbackRightClick: async value => {
                if (value === "delete") {
                  await dispatch.blocker.deleteBlocker({
                    accidentId: accident.id,
                    blocker,
                    vehicleId: vehicle.id,
                  });
                  publishBlocker(accident.id, blocker.id, "blockerDeleted");
                }
              },
              options: {
                title: findBlockerType(blocker?.category),
              },
            }),
          });
        }
      });
    }
  }, [scriptLoaded, blockers]);

  useEffect(() => {
    const myVehicleId = localStorage.getItem("myVehicleId");

    if (myVehicleId !== null) {
      dispatch.vehicle.getVehicle({ vehicleId: myVehicleId });
    }
  }, []);

  useEffect(() => {
    getParticipantInfos();
  }, [vehicle]);

  useEffect(() => {
    accidentVehicles.forEach(item => {
      const indexPath = JSON.parse(item.path);
      if (
        indexPath &&
        item.id !== vehicle?.id &&
        !dispatch.path.getPathById({ id: item.id }) &&
        item.accident?.id === vehicle.accident?.id
      ) {
        dispatch.path.addPaths({
          id: item.id,
          paths: {
            pathData: { path: indexPath.path },
          },
          start: indexPath.start,
          goal: indexPath.goal,
          waypoint: indexPath.waypoint,
        });
      }
    });
  }, [accidentVehicles]);

  // hotkeys
  useHotkeys("w", handleClickExpand, {
    scopes: ["mainPage"],
  });

  return (
    <Box className={classes.foldPanel} id="foldPanel">
      <Box className={classes.entryExpand} onClick={handleClickExpand}>
        <Icon className={classes.icon}>
          {!sidebarExpanded ? (
            <ArrowRight className={classes.largeArrow} />
          ) : (
            <ArrowLeft className={classes.largeArrow} />
          )}
        </Icon>
      </Box>
      <Box
        className={`${sidebarExpanded ? classes.content : classes.contentFold}`}
      >
        <Tab />
        <Box className={classes.main}>
          <Outlet />
        </Box>
        <Box className={classes.tools}>
          {/* <SimulatorSwitch /> */}
          <PathViewSwitch />
          <Tooltip title={toolTipMessage()} placement="right-end">
            <span>
              <Fab
                disabled={device !== deviceType.GPS}
                variant="extended"
                size="small"
                onClick={handleClickGPS}
                color="primary"
              >
                <Adjust color="secondary" className={classes.flickerIcon} />
                {device}에서 수신중
              </Fab>
            </span>
          </Tooltip>
          <Dialog
            open={dialogOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              이미 연결된 RTK 장비가 있습니다
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                RTK 장비의 전원을 확인해주세요
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelRequest} color="primary" autoFocus>
                확인
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles({
  "@keyframes flicker": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.3,
    },
  },
  main: {
    borderLeft: "1px solid #ddd",
    position: "absolute",
    left: "0",
    top: "3.25rem",
    flexDirection: "column",
    width: "24.5rem",
    height: "calc(100% - 6.25rem)",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    overflow: "auto",
  },
  tools: {
    width: "100%",
    justifyContent: "center",
    paddingTop: 5,
    paddingBottom: 5,
    display: "flex",
    position: "absolute",
    bottom: 0,
    backgroundColor: "#7388FF",
    color: "white",
    fontWeight: "bold",
  },
  foldPanel: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 200,
  },
  content: {
    position: "relative",
    height: "100%",
    backgroundColor: "#fff",
    minWidth: "24.5rem",
    boxShadow: "0 0 5px 0 rgba(0,0,0,.2), 5px 0 15px 0 rgba(0,0,0,.1)",
  },
  contentFold: {
    visibility: "hidden",
  },
  entryExpand: {
    zIndex: 200,
    position: "absolute",
    right: "-43px",
    top: "calc(50% - 22px)",
    width: "44px",
    height: "44px",
    left: "100%",
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,.2)",
    borderLeft: 0,
    borderTop: 0,
    borderRadius: "0 4px 4px 0",
    fontSize: "1px",
    lineHeight: "1px",
    boxShadow: "1px 1px 0px 0 rgba(0,0,0,.2)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#e6e6e6",
    },
  },
  largeArrow: {
    fontSize: "2.5rem",
  },
  icon: {
    padding: "3px",
  },
  flickerIcon: {
    animationName: "$flicker",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out",
    marginRight: "0.5rem",
  },
});

export default MainPage;
