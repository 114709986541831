import {
  initLocation,
  currentPolylineOptions,
  currentBorderLineOptions,
} from "constants";
import { instance as apiClient } from "apiClient";
import { DrawPolyline } from "utils/naverMaps";

const initialState = {
  isPathLoading: false,
  paths: {},
  pathList: [],
  startPoint: initLocation,
  goalPoint: initLocation,
  waypoint: initLocation,
  waypointActive: false,
  navigatingWaypoint: initLocation,
  pathView: false,
};

export default {
  state: initialState,
  reducers: {
    setIsPathLoading(state, payload) {
      state.isPathLoading = payload;
    },
    addPaths(state, payload) {
      state.paths[payload.id] = payload;
    },
    setPathList(state, payload) {
      state.pathList = payload;
    },
    removePaths(state, payload) {
      delete state.paths[payload.id];
    },
    clearPathMap(state, _) {
      state.paths = initialState.paths;
    },
    setStartPoint(state, payload) {
      state.startPoint = payload;
    },
    setGoalPoint(state, payload) {
      state.goalPoint = payload;
    },
    setWaypoint(state, payload) {
      state.waypoint = payload;
    },
    setWaypointActive(state, payload) {
      state.waypointActive = payload;
    },
    setNavigatingWaypoint(state, payload) {
      state.navigatingWaypoint = payload;
    },
    setPathView(state, payload) {
      state.pathView = payload;
    },
  },
  effects: dispatch => ({
    async getPaths(payload) {
      const { paths, waypoint } = payload;
      const { data } = await apiClient.get(
        `/api/v1/paths?start=${paths.start.longitude},${
          paths.start.latitude
        }&goal=${paths.goal.longitude},${paths.goal.latitude}${
          waypoint
            ? `&waypoints=${waypoint.longitude},${waypoint.latitude}`
            : ""
        }`,
      );
      return data;
    },
    getPathById(payload, state) {
      return state.path.paths[payload.id];
    },
    clearPath(_, state) {
      const { pathList } = state.path;
      pathList.forEach(item => {
        item.polylines.forEach(poly => {
          poly.polyline.setMap(null);
          poly.borderline.setMap(null);
        });
        item.infoWindow.close();
      });
      this.setPathList([]);
    },
    clearPathByNumber(payload) {
      const { vehicleNumber } = payload;
      const vehiclePath = this.getPathById({ id: vehicleNumber });
      vehiclePath.paths?.polylines?.forEach(poly => {
        poly.polyline.setMap(null);
        poly.borderline.setMap(null);
      });
    },
    selectPath(payload, state) {
      const selectedIdx = payload;
      const { pathList } = state.path;
      // eslint-disable-next-line prefer-const
      let selectedPath = null;
      pathList.forEach((item, index) => {
        item.infoWindow.close();
        // 선택되지 않은 경로 맵에서 제거
        if (index !== selectedIdx) {
          item.polylines.forEach(poly => {
            poly.polyline.setMap(null);
            poly.borderline.setMap(null);
          });
          return;
        }
        // 선택된 경로 컬러변경
        item.polylines.forEach(poly => {
          poly.polyline.setOptions(currentPolylineOptions);
          poly.borderline.setOptions(currentBorderLineOptions);
        });
        selectedPath = item;
      });
      return selectedPath;
    },
    showOtherPath(payload, state) {
      const { vehicle } = state.vehicle;
      const { paths } = state.path;
      const { map } = state.location;
      Object.values(paths).forEach(item => {
        const connectStatus = dispatch.location.getMarkerById({
          id: item.id,
        });
        if (!connectStatus) {
          return;
        }
        if (item.id !== vehicle.id) {
          dispatch.path.addPaths({
            ...item,
            paths: {
              ...item.paths,
              polylines: [
                DrawPolyline(item.paths.pathData.path, map, {
                  zIndex: 1,
                  strokeColor: "gray",
                }),
              ],
            },
          });
        }
      });
    },
    hideOtherPath(payload, state) {
      const { vehicle } = state.vehicle;
      const { paths } = state.path;
      Object.values(paths).forEach(item => {
        if (item.id !== vehicle.id) {
          if (!item.paths.polylines) {
            return;
          }
          item.paths?.polylines?.forEach(poly => {
            poly.polyline.setMap(null);
            poly.borderline.setMap(null);
          });
        }
      });
    },
    getPathView(_, state) {
      return state.path.pathView;
    },
  }),
};
