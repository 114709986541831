import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { AlertDialog } from "components";
import { removeIdentifyCircle, removePathList } from "utils/naverMaps";
import { publish } from "utils/socket";
import { client } from "stores/location";

function VehicleInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { vehicle } = useSelector(state => state.vehicle);
  const { markers } = useSelector(state => state.location);
  const { paths } = useSelector(state => state.path);
  const { accident } = useSelector(state => state.accident);

  const [logoutDialog, setLogoutDialog] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("myVehicleType");
    localStorage.removeItem("myVehicleId");
    dispatch.vehicle.setVehicleInfo({});
    dispatch.accident.setAccident(null);
    Object.values(paths).forEach(item => {
      removePathList(item.paths?.polylines);
      dispatch.path.removePaths({ id: item.id });
    });
    Object.values(markers).forEach(item => item.setMap(null));
    removeIdentifyCircle();
    dispatch.location.clearMarker();
    dispatch.vehicle.setIsDepart(false);
    publish(
      JSON.stringify(
        {
          type: "leave",
          topicId: accident.id,
          contents: "",
          sender: vehicle.id,
          createdAt: Date.now(),
        },
        "/pub/message",
      ),
    );
    client.unsubscribe(`${accident.id}-${vehicle.id}`);
    client.deactivate();
  };

  useEffect(() => {
    dispatch.vehicle.getVehicles();
  }, []);

  return (
    <>
      <Box m={4} className={classes.vehicleNumber}>
        <Box id="vehicle-label">탑승한 차량명</Box>
        <Box className="vehicle-container">
          <Box className="vehicle-number">{vehicle.id}</Box>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              setLogoutDialog(true);
            }}
          >
            변경
          </Button>
        </Box>
      </Box>
      <Divider />
      <AlertDialog
        title="변경"
        description="차량을 변경하시겠습니까?"
        onClose={() => {
          setLogoutDialog(false);
        }}
        open={logoutDialog}
        onAgree={handleLogout}
      />
    </>
  );
}

const useStyles = makeStyles({
  vehicleNumber: {
    display: "flex",
    margin: "1.5rem",
    alignItems: "center",
    justifyContent: "right",
    "& #vehicle-label": {
      fontWeight: 600,
      marginRight: "1rem",
      minWidth: "7rem",
    },
    "& .vehicle-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .vehicle-number": {
      marginRight: "1rem",
    },
  },
});

export default VehicleInfo;
