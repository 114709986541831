import React, { useEffect, useState } from "react";
import {
  participantInfoHotKeys,
  device as deviceType,
  appVersion,
} from "constants";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";
import Blockers from "./Blockers";
import Participants from "./Participants";
import BlockerCreateDialog from "./BlockerCreateDialog";
import AccidentInfo from "./AccidentInfo";
import VehicleInfo from "./VehicleInfo";
import { setCenterEffect } from "utils/naverMaps";
import { CustomButton, RefreshButton, HeaderTitle } from "components";

function ParticipantInfo() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accident } = useSelector(state => state.accident);
  const { map, markers } = useSelector(state => state.location);
  const { vehicle, accidentVehicles } = useSelector(state => state.vehicle);
  const { blockers } = useSelector(state => state.blocker);
  const [showAddBlocker, setShowAddBlocker] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const handleRefreshVehicle = async () => {
    setRefresh(true);
    await getParticipantInfos();
    const getMyMarker = markers[vehicle.id];
    setCenterEffect(map, getMyMarker);
    setRefresh(false);
  };

  const handleAddBlocker = () => {
    setShowAddBlocker(true);
  };

  const getParticipantInfos = async () => {
    if (vehicle.accident) {
      await dispatch.accident.getAccident({ id: vehicle.accident.id });
      await dispatch.vehicle.getAccidentVehicles({
        accidentId: vehicle.accident.id,
      });
      await dispatch.blocker.getBlockers({ accidentId: vehicle.accident.id });
    }
  };

  useEffect(() => {
    if (!vehicle.id) {
      navigate("/register");
    }
  }, [vehicle]);

  useEffect(() => {
    dispatch.hotkey.setHotkeys(participantInfoHotKeys);
  }, []);

  useHotkeys("r", handleRefreshVehicle, {
    scopes: ["participantInfo"],
  });
  return (
    <Box>
      <HeaderTitle>
        <Typography variant="caption">{appVersion}</Typography>
        <Typography variant="h4">재난 정보</Typography>
        <CustomButton
          title="재난정보 새로고침"
          onClick={handleRefreshVehicle}
          classes={classes.refreshButton}
        >
          <RefreshButton loading={refresh} />
        </CustomButton>
      </HeaderTitle>
      <Box className={classes.body}>
        <VehicleInfo />
        {accident && (
          <Box m={4} mt={1}>
            <Grid
              className={classes.accidentInfo}
              container
              direction="column"
              spacing={1}
            >
              <AccidentInfo accident={accident} />
              <Participants vehicles={accidentVehicles} />
              <Blockers
                blockers={blockers}
                handleAddBlocker={handleAddBlocker}
              />
            </Grid>
          </Box>
        )}
      </Box>
      <BlockerCreateDialog
        accidentId={vehicle?.accident?.id}
        open={showAddBlocker}
        onOpen={() => setShowAddBlocker(true)}
        onClose={() => setShowAddBlocker(false)}
      />
    </Box>
  );
}

const useStyles = makeStyles({
  "@keyframes flicker": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.3,
    },
  },
  main: {
    borderLeft: "1px solid #ddd",
    position: "absolute",
    left: "0",
    flexDirection: "column",
    width: "24.5rem",
    height: "100%",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    overflow: "auto",
  },
  sub: {
    borderLeft: "1px solid #ddd",
    position: "absolute",
    left: "100%",
    flexDirection: "column",
    width: "24.5rem",
    height: "100%",
    backgroundColor: "#fff",
    boxSizing: "border-box",
  },
  body: {},
  accidentInfo: {
    "& .accident-label": {
      fontWeight: 600,
    },
    "& .accident-value": {},
  },
  refreshButton: {
    marginLeft: "0.1rem",
  },
  simulatorSwitch: {
    float: "right",
    marginRight: "1.5rem",
  },
  flickerIcon: {
    animationName: "$flicker",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out",
    marginRight: "0.5rem",
  },
  gpsButton: {
    marginLeft: "5%",
    marginTop: "1%",
    position: "absolute",
    bottom: "1%",
  },
});

export default ParticipantInfo;
