/* eslint-disable no-empty-function */
/* eslint-disable no-unused-vars */
import { instance as apiClient } from "apiClient";

const initialState = {
  accidents: [],
  accident: null,
  blockers: [],
  blocker: null,
};

export default {
  state: initialState,
  reducers: {
    setAccident(state, payload) {
      state.accident = payload;
    },
    setAccidents(state, payload) {
      state.accidents = payload;
    },
  },
  effects: dispatch => ({
    async getAccidents(payload) {
      const { data } = await apiClient.get(`/api/v1/accidents`);
      this.setAccidents(data);
    },
    async getAccident(payload) {
      const { data } = await apiClient.get(`/api/v1/accidents/${payload.id}`);
      this.setAccident(data);
    },
    async createAccident(payload) {
      try {
        let accident = payload;
        accident = { ...payload, createdAt: new Date().getTime() };
        const response = await apiClient.post(`/api/v1/accidents`, accident);
        this.setAccident(response.data);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async updateAccident(payload) {
      try {
        let accident = payload;
        accident = { ...payload, updatedAt: new Date().getTime() };
        if (accident.status === "상황종료" && !accident.finishedAt) {
          accident = { ...accident, finishedAt: new Date().getTime() };
        }
        const response = await apiClient.put(`/api/v1/accidents`, accident);
        this.setAccident(response.data);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async deleteAccident(payload) {
      await apiClient.delete(`/api/v1/accidents/${payload}`);
    },
  }),
};
