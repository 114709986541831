import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import AccidentCreate from "./AccidentDialog";
import { BreadCrumbs, StickyHeadTable } from "components";
import { accidentColumns } from "utils/dataSetting";

const breadCrumbs = [
  {
    id: "accidents",
    label: "재난",
    path: "/admin/accidents",
    active: false,
  },
];

function AccidentList() {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { accidents } = useSelector(state => state.accident);

  const [isOpenCreate, setOpenCreate] = useState(false);

  const handleOpenCreate = event => {
    event.preventDefault();
    setOpenCreate(true);
  };
  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  const handleClickRow = accident => {
    navigator(`/admin/accidents/info/${accident.id}`);
  };

  const getAccidents = async () => {
    await dispatch.accident.getAccidents();
  };

  useEffect(() => {
    getAccidents();
    dispatch.jurisdiction.getJurisdictions();
    return () => {};
  }, []);

  return (
    <Box>
      {/* Header */}
      <BreadCrumbs items={breadCrumbs} />
      {/* Contents */}
      <Grid container>
        <Grid item xs={12}>
          {/* accidents card */}
          <Card variant="outlined">
            <CardContent>
              {/* accidents list header */}
              <Box m={1} pb={3}>
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <Typography variant="h5">재난 목록</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleOpenCreate}
                    >
                      재난 추가
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              {/* accidents list */}
              <Box m={1}>
                <StickyHeadTable
                  columns={accidentColumns}
                  rows={accidents}
                  onClickRow={handleClickRow}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <AccidentCreate open={isOpenCreate} onClose={handleCloseCreate} />
    </Box>
  );
}

export default AccidentList;
