import { CircularProgress } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";

function RefreshButton({
  loading = false,
  size = "1.5rem",
  color = "inherit",
}) {
  if (loading) {
    return <CircularProgress size={size} color={color} />;
  }
  return <Refresh />;
}

export default RefreshButton;
