import React from "react";
import { AlertDialog } from "components";

function BlockerCreateDialog({
  open,
  setOpen,
  markers,
  vehicle,
  createBlocker,
}) {
  const handleCreateBlocker = () => {
    const myPosition = markers[vehicle.id].getPosition();
    createBlocker("etc", myPosition);
  };

  return (
    <AlertDialog
      open={open}
      onAgree={handleCreateBlocker}
      onClose={() => setOpen(false)}
      title="진입 장애 구역 생성"
      description="현재 위치에 진입 장애 구역을 생성하시겠습니까?"
    />
  );
}
export default BlockerCreateDialog;
