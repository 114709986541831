import { useDispatch, useSelector } from "react-redux";
import { Box, Fab } from "@material-ui/core";
import { Videocam } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

export default function MainButtonController({ classes: parentClass = "" }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isDepart } = useSelector(state => state.vehicle);
  const { videoVisible } = useSelector(state => state.controller);
  const { sidebarExpanded } = useSelector(state => state.location);
  const navigate = useNavigate();

  const handleVideoButton = () => {
    if (isDepart) {
      dispatch.controller.setVideoVisible(!videoVisible);
      if (sidebarExpanded && !videoVisible) {
        navigate("/videostream");
      } else if (!videoVisible) {
        dispatch.location.setSidebarExpanded(true);
        navigate("/videostream");
      } else {
        dispatch.location.setSidebarExpanded(false);
      }
    }
  };

  return (
    <Box className={parentClass}>
      <Fab
        className={classes.button}
        variant="extended"
        onClick={handleVideoButton}
      >
        <Videocam className={classes.videoIcon} />
        {videoVisible ? " 영상 숨기기" : " 영상 보이기"}
      </Fab>
    </Box>
  );
}
const useStyles = makeStyles({
  button: {
    // width: 130,
    // height: 70,
    // fontSize: 17,
  },
  videoIcon: {
    marginRight: "0.2rem",
  },
});
