export const KEYCLOAK_CONFIGS = {
  url: "https://keycloak.service.dudaji.com/auth", // keycloak 주소
  realm: "dudaji", // 사용할 relm
  clientId: "dudaji-web", // 사용할 client id
  redirectUri: "http://localhost:3000", // 로그인 후 redriect uri
};

export const MAP_CONFIGS = {
  clientId: process.env.REACT_APP_NAVER_MAP_CLIENT_ID,
  apiKey: process.env.REACT_APP_NAVER_MAP_API_KEY,
};

let wsProtocol = "ws";

if (process.env.REACT_APP_HTTPS === "true") {
  wsProtocol = "wss";
}
export const BASE_URLS = {
  FIRE_PATH_URL_WS: `${wsProtocol}://${process.env.REACT_APP_API_BASE_URL}/ws`,
  FIRE_PATH_URL:
    `${process.env.REACT_APP_API_BASE_URL}` || window.location.origin,
};
