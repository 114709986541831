import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export default function CustomButton({
  children,
  classes: parentClass = "",
  title = "",
  onClick = () => {},
  arrow = true,
  placement = "top",
}) {
  const classes = useStyles();
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      title={title}
      placement={placement}
      arrow={arrow}
    >
      <IconButton className={parentClass || classes.button} onClick={onClick}>
        {children}
      </IconButton>
    </Tooltip>
  );
}

const useStyles = makeStyles({
  tooltip: {
    fontSize: "1rem",
    zIndex: "9999",
  },
  button: {
    padding: 0,
  },
});
