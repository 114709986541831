import { useEffect, useState } from "react";
import { blockerTypeList } from "constants";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormLabel,
  TextField,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import produce from "immer";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { publish } from "utils/socket";

const initBlocker = {
  id: undefined,
  accident: undefined,
  category: undefined,
  reason: undefined,
  location: {
    address: undefined,
    latitude: undefined,
    longitude: undefined,
  },
};

function BlockerDialog({
  modalVisible,
  onCloseModal,
  newBlocker = initBlocker,
  accidentId,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [blocker, setBlocker] = useState(newBlocker);
  const handleChangeBlocker = (inputKey, inputValue) => {
    const changedData = produce(blocker, draft => {
      draft[inputKey] = inputValue;
    });
    setBlocker(changedData);
  };

  const onClickSubmit = async () => {
    const payload = { item: blocker, accidentId };
    if (blocker.id) {
      await dispatch.blocker.updateBlocker(payload);
    } else {
      await dispatch.blocker.createBlocker(payload);
    }
    onCloseModal();
  };

  useEffect(() => {
    setBlocker(newBlocker);
  }, [newBlocker]);

  return (
    <Dialog open={modalVisible} onClose={onCloseModal} fullWidth maxWidth="sm">
      <DialogTitle>
        {blocker.id ? "진입 장애 구역 수정" : "진입 장애 구역 등록"}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">구분</FormLabel>
              <Select
                aria-label="Blocker Category"
                id="blockerCategory"
                value={blocker.category || ""}
                onChange={e => handleChangeBlocker("category", e.target.value)}
              >
                {blockerTypeList.map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item m={3}>
            <TextField
              autoFocus
              label="사유"
              value={blocker.reason || ""}
              onChange={e => handleChangeBlocker("reason", e.target.value)}
            />
          </Grid>
          <Grid item m={3}>
            <TextField
              id="blockerLocation"
              label="위치"
              value={blocker.location?.address || ""}
              onChange={e => {
                const newLocation = {
                  ...blocker.location,
                  address: e.target.value,
                };
                handleChangeBlocker("location", newLocation);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickSubmit} color="primary">
          저장
        </Button>
        <Button onClick={onCloseModal} color="secondary">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
  },
});

export default BlockerDialog;
