import { useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import produce from "immer";
import { makeStyles } from "@material-ui/styles";
import { SnackbarMessage } from "components";
import { getGeocode } from "utils/naverMaps";
import { handleEnter } from "utils";

const initJurisdictionData = {
  id: undefined,
  location: { latitude: undefined, longitude: undefined, address: undefined },
  phone: undefined,
  name: undefined,
};

const initSnack = {
  isOpen: false,
  severity: "success",
  message: "",
};

function JurisdictionDialog({
  open,
  onClose,
  isUpdate = false,
  newJurisdiction = initJurisdictionData,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [jurisdiction, setJurisdiction] = useState(newJurisdiction);
  const [snackInfo, setSnackInfo] = useState(initSnack);
  const addressVerified = isUpdate ? useRef("true") : useRef("false");

  const handleChangeJurisdiction = (inputKey, inputValue) => {
    const changedData = produce(jurisdiction, draft => {
      draft[inputKey] = inputValue;
    });
    setJurisdiction(changedData);
  };

  const onChangeJurisdiction = e => {
    if (addressVerified.current !== "false") {
      addressVerified.current = "false";
    }
    const address = e.target.value;
    handleChangeJurisdiction("location", {
      address,
      latitude: null,
      longitude: null,
    });
  };

  const validate = () => {
    if (
      !jurisdiction.name ||
      !jurisdiction.phone ||
      !jurisdiction.location.address
    ) {
      setSnackInfo({
        isOpen: true,
        severity: "error",
        message: "값을 입력해주세요.",
      });
      return false;
    }
    if (addressVerified.current !== "true") {
      setSnackInfo({
        isOpen: true,
        severity: "error",
        message: "주소를 검증 해주세요.",
      });
      return false;
    }
    return true;
  };

  const onClickSubmit = async () => {
    if (!validate()) return;
    let response;
    if (isUpdate) {
      response = await dispatch.jurisdiction.updateJurisdiction(jurisdiction);
    } else {
      response = await dispatch.jurisdiction.createJurisdiction(jurisdiction);
    }
    if (response.status !== 200) {
      setSnackInfo({
        isOpen: true,
        severity: "error",
        message: "같은 이름의 관할소가 이미 있습니다.",
      });
      return;
    }
    await dispatch.jurisdiction.getJurisdictions();
    onClose();
  };

  const searchAddress = () => {
    const { address } = jurisdiction.location;
    if (!address || address.length === 0) {
      addressVerified.current = "failed";
      return;
    }
    getGeocode(
      jurisdiction.location,
      "location",
      (inputKey, newState) => {
        handleChangeJurisdiction(inputKey, newState);
        addressVerified.current = "true";
        setSnackInfo({
          isOpen: true,
          severity: "success",
          message: "주소 검증을 완료했습니다.",
        });
      },
      () => {
        addressVerified.current = "failed";
        setSnackInfo({
          isOpen: true,
          severity: "error",
          message: "주소를 찾을 수 없습니다.",
        });
      },
    );
  };

  useEffect(() => {
    setJurisdiction(newJurisdiction);
  }, [newJurisdiction]);

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle>{isUpdate ? "관할서 수정" : "관할서 생성"}</DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField
                error={!jurisdiction.name}
                helperText={!jurisdiction.name && "값을 입력해주세요."}
                autoFocus
                label="관할서 이름"
                value={jurisdiction.name || ""}
                onChange={e => handleChangeJurisdiction("name", e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                error={!jurisdiction.phone}
                helperText={!jurisdiction.phone && "값을 입력해주세요."}
                autoFocus
                label="관할서 전화번호"
                value={jurisdiction.phone || ""}
                onChange={e =>
                  handleChangeJurisdiction("phone", e.target.value)
                }
              />
            </Grid>
            <Grid item>
              <TextField
                style={{ minWidth: "300px" }}
                error={
                  !jurisdiction.location.address ||
                  addressVerified.current === "failed"
                }
                helperText={
                  (!jurisdiction.location.address ||
                    addressVerified.current === "failed") &&
                  "주소를 올바르게 입력해주세요."
                }
                autoFocus
                label="관할서 주소"
                value={jurisdiction.location?.address || ""}
                onChange={onChangeJurisdiction}
                onKeyDown={e => {
                  handleEnter(e, () => {
                    searchAddress();
                  });
                }}
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={searchAddress}
                className={classes.verifyButton}
                disabled={addressVerified.current === "true"}
              >
                {addressVerified.current === "true" ? "검증 완료" : "주소 검증"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.formActions}>
          <Button variant="outlined" color="primary" onClick={onClickSubmit}>
            {isUpdate ? "수정" : "생성"}
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            취소
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarMessage
        {...snackInfo}
        setCondition={() => {
          setSnackInfo(initSnack);
        }}
      />
    </>
  );
}

const useStyles = makeStyles({
  formContainer: {
    margin: "1rem",
  },
  formActions: {
    paddingTop: "2rem",
    justifyContent: "center",
  },
  verifyButton: {
    marginTop: "0.5rem",
    marginLeft: "2rem",
  },
});

export default JurisdictionDialog;
