import { publish } from "./socket";

let lat = 0;
let long = 0;

export const getCurrentPosition = callback => {
  navigator.geolocation.getCurrentPosition(position => callback(position));
};

export const notifyCurrentPosition = (vehicle, accidentId) => {
  const publishCurrentPosition = position => {
    const currentPosition = {
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
      speed: position.coords.speed,
      timestamp: position.timestamp,
      vehicleType: vehicle.type,
    };
    publish(
      JSON.stringify({
        type: "location",
        topicId: accidentId,
        contents: currentPosition,
        sender: vehicle.id,
        createdAt: Date.now(),
      }),
      "/pub/message",
    );
  };
  getCurrentPosition(publishCurrentPosition);
};

export const notifyMockPosition = async (
  vehicle,
  accident,
  pathCoords,
  index,
) => {
  if (pathCoords.length === 0) {
    return;
  }
  const currentPosition = pathCoords[index];
  const position = {
    latitude: currentPosition[1],
    longitude: currentPosition[0],
    speed: 0,
    timestamp: Date.now(),
    vehicleType: vehicle.type,
  };
  publish(
    JSON.stringify({
      type: "location",
      topicId: accident.id,
      contents: position,
      sender: vehicle.id,
      createdAt: Date.now(),
    }),
  );
};

export const isArrived = (map, current, goal) => {
  if (map) {
    const proj = map.getProjection();
    const distance = proj.getDistance(
      new window.naver.maps.LatLng(current.latitude, current.longitude),
      new window.naver.maps.LatLng(goal.latitude, goal.longitude),
    );
    return distance < 50;
  }
  return false;
};

const toRadians = degree => {
  return degree * (Math.PI / 180);
};

const haversineDistance = (lat1, lon1, lat2, lon2) => {
  if (lat1 === 0 || lon1 === 0) {
    return null;
  }
  const R = 6371;

  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance;
};

export const calculateSpeed = current => {
  const distance = haversineDistance(
    lat,
    long,
    current.latitude,
    current.longitude,
  );
  lat = current.latitude;
  long = current.longitude;
  const speed = distance ? Math.round(distance * 3600) : null;
  return speed;
};
