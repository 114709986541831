import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

function SnackbarMessage({ isOpen = false, severity, setCondition, message }) {
  return (
    <Snackbar open={isOpen} autoHideDuration={5000} onClose={setCondition}>
      <Alert onClose={setCondition} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackbarMessage;
