/* eslint-disable no-unused-vars */
import { instance as apiClient } from "apiClient";
import { formatBytes } from "utils/video";

const initialState = {
  isVideoUploaded: false,
  uploadedVideoCnt: 0,
  vehicleVideos: [],
};

export default {
  state: initialState,
  reducers: {
    setIsVideoUploaded(state, payload) {
      state.isVideoUploaded = payload;
    },
    setVehicleVideos(state, payload) {
      state.vehicleVideos = payload;
    },
    addUploadedVideoCnt(state, payload) {
      state.uploadedVideoCnt += 1;
    },
  },
  effects: dispatch => ({
    async uploadVideo(payload) {
      try {
        const { vehicleId, body } = payload;
        const response = await apiClient.post(
          `/api/v1/vehicles/${vehicleId}/video`,
          body,
          {
            headers: { "Content-Type": "multipart/form-data" },
          },
        );
        this.addUploadedVideoCnt();
        return response;
      } catch (e) {
        this.addUploadedVideoCnt();
        return e.response;
      }
    },
    async getVehicleVideos(payload) {
      const { accidentId } = payload;
      const { data } = await apiClient.get(`/api/v1/video/${accidentId}`);
      await data.forEach(d => {
        d.size = formatBytes(d.size);
      });
      this.setVehicleVideos(data);
      return data;
    },

    async downloadVideo(payload) {
      try {
        const { accidentId, fileName } = payload;
        const { data } = await apiClient.get(
          `/api/v1/video/${accidentId}/${fileName}`,
          { responseType: "blob" },
        );
        return data;
      } catch (e) {
        return undefined;
      }
    },

    async deleteVideo(payload) {
      const { accidentId, fileName } = payload;
      await apiClient.delete(`/api/v1/video/${accidentId}/${fileName}`);
    },
  }),
};
