/* eslint-disable import/prefer-default-export */
import { init } from "@rematch/core";
import immerPlugin from "@rematch/immer";
import loadingPlugin from "@rematch/loading";
import logger from "redux-logger";
import auth from "./auth";
import accident from "./accident";
import blocker from "./blocker";
import location from "./location";
import path from "./path";
import vehicle from "./vehicle";
import jurisdiction from "./jurisdiction";
import hotkey from "./hotkey";
import engine from "./engine";
import image from "./image";
import inference from "./inference";
import controller from "./controller";
import download from "./download";
import video from "./video";

export const configureAppStore = () => {
  const store = init({
    models: {
      accident,
      auth,
      blocker,
      hotkey,
      location,
      vehicle,
      path,
      jurisdiction,
      engine,
      image,
      inference,
      controller,
      download,
      video,
    },
    plugins: [immerPlugin(), loadingPlugin()],
    // redux: {
    //   middlewares: [logger],
    // },
  });
  return store;
};
