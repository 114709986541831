import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, CardMedia } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { getSnapshotData } from "utils/upload";
import { getWebcamStream } from "utils/webRTC";
import { generateRandomPossibility } from "utils";

export default function Snapshot(props) {
  const { accident, engine, markers, vehicle, webcam } = props;
  const { vehicleLocation } = useSelector(state => state.location);
  const { blockerPossibility } = useSelector(state => state.inference);
  const classes = useStyles();
  const dispatch = useDispatch();

  const snapshot = async () => {
    if (accident && vehicle.id) {
      const snapshotData = getSnapshotData({
        camRef: webcam,
        canvasId: "snapshot",
      });
      const vehicleMarker = markers[vehicle.id];
      if (!vehicleMarker) {
        return null;
      }
      const position = vehicleMarker.getPosition();
      await engine?.current.getCurrentImage({
        // demo engine props
        panoEleId: "vehicle-pano",
        vehicleNumber: vehicle.id,
        callback: dispatch.image.uploadVehicleImage,
        position: { latitude: position.y, longitude: position.x },
        // real engine props
        id: vehicle.id,
        data: snapshotData,
        urlPath: `vehicles/${vehicle.id}/image`,
      });
      if (snapshotData !== "") {
        const result = await dispatch.inference.inferenceRoadImg({
          image: snapshotData,
          vehicle,
          ...vehicleLocation,
        });
        if (result[0]) {
          let possibility = blockerPossibility;
          if (result[0].Impossible) {
            if (possibility < 100.0) {
              possibility += generateRandomPossibility();
            }
            possibility = possibility > 100 ? 100.0 : possibility;
          } else {
            if (possibility > 0) {
              possibility -= generateRandomPossibility();
            }
            possibility = possibility < 0 ? 0.0 : possibility;
          }
          dispatch.inference.setBlockerPossibility(possibility);
        }
      }
    }
  };

  const webCamStreamFor = async () => {
    webcam.current.srcObject = await getWebcamStream();
  };

  useEffect(() => {
    webCamStreamFor();
  }, []);

  // invoke whenever location is changed
  useEffect(() => {
    snapshot();
  }, [accident, vehicle, vehicleLocation]);

  return (
    <Box className={classes.videoContainer}>
      <CardMedia
        className={classes.video}
        component="video"
        ref={webcam}
        autoPlay
      />
      <canvas id="snapshot" className={classes.snapshot} />
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  videoContainer: {
    zIndex: "-1001 !important",
    position: "absolute !important",
  },
  video: {
    position: "absolute !important",
  },
  snapshot: {},
}));
