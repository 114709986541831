/* eslint-disable no-use-before-define */
import { lowVideoConfig, highVideoConfig } from "constants";
import { publish } from "utils/socket";

const peerConnectionList = {};
const streamList = {};
const candidateQueue = {};
const config = {
  iceServers: [
    {
      urls: ["stun:stun.l.google.com:19302"],
    },
    {
      // TURN SERVER INFO
      urls: ["turn:34.22.72.108:3478"],
      username: "firepath",
      credential: "dudaji",
    },
  ],
};

const initVideoConfig = {
  quality: "low",
  trigger: "",
};

let retryConnectionTimer = null;

async function getWebcamId() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const videoDevices = devices.filter(device => device.kind === "videoinput");
  const webcam = videoDevices.filter(device =>
    device.label.toLowerCase().includes("webcam"),
  );
  if (webcam.length > 0) {
    return webcam[0].deviceId;
  }
  return videoDevices[0].deviceId;
}

export async function getWebcamStream(videoQuality = "low") {
  const webcamId = await getWebcamId();
  const videoConfig =
    videoQuality === "high" ? highVideoConfig : lowVideoConfig;
  const stream = await navigator.mediaDevices.getUserMedia({
    video: {
      deviceId: { exact: webcamId },
      ...videoConfig,
    },
  });
  return stream;
}

export async function getScreenStream() {
  const stream = await navigator.mediaDevices.getDisplayMedia({
    video: true,
    preferCurrentTab: true,
    ...lowVideoConfig,
  });
  const videoTrack = stream.getVideoTracks()[0];
  const settings = videoTrack.getSettings();
  videoTrack.applyConstraints({
    width: settings.width / 2,
    height: settings.height / 2,
  });
  return stream;
}

export function send(type, payload, accident, receiver, selfId) {
  publish(
    JSON.stringify({
      type: `${type}for${receiver}`,
      topicId: accident,
      contents: payload,
      sender: selfId,
      createdAt: Date.now(),
    }),
    "/pub/message",
    err => {
      console.log(err);
    },
  );
}

function getTargetName(selfId, peer, videoConfig) {
  const peerName = selfId === videoConfig.trigger ? `To${peer}` : peer;
  return videoConfig.quality === "high" ? `${peerName}High` : peerName;
}

function addNewPeer(
  peer,
  selfId,
  receiver,
  accidentId,
  localStream,
  videoConfig = initVideoConfig,
  videoRef = null,
) {
  const peerConnection = new RTCPeerConnection(config);
  const remoteStream = new MediaStream();
  peerConnectionList[peer] = peerConnection;
  streamList[peer] = remoteStream;

  peerConnection.onicecandidate = e => {
    const { candidate } = e;
    if (candidate) {
      console.log("send candidate from ", selfId, "to", receiver);
      const candidatePayload = {
        candidateData: {
          type: "candidate",
          candidate: candidate.candidate,
          sdpMid: candidate.sdpMid,
          sdpMLineIndex: candidate.sdpMLineIndex,
        },
        videoConfig,
      };
      send(
        "candidate",
        JSON.stringify(candidatePayload),
        accidentId,
        receiver,
        selfId,
      );
    }
  };

  peerConnection.ontrack = event => {
    event.streams[0].getTracks().forEach(track => {
      remoteStream.addTrack(track);
      if (videoRef?.current) {
        videoRef.current.srcObject = remoteStream;
      }
    });
  };
  localStream.getTracks().forEach(track => {
    peerConnection.addTrack(track, localStream);
  });
}

async function sendOffer(
  accidentId,
  receiver,
  selfId,
  pc,
  videoConfig,
  offerOptions = {},
) {
  const offer = await pc.createOffer(offerOptions);
  pc.setLocalDescription(offer);
  const payload = { offer, videoConfig };
  send("offer", JSON.stringify(payload), accidentId, receiver, selfId);
}

export async function createOffer(
  receiver,
  selfId,
  accidentId,
  videoRef = null,
  videoConfig = initVideoConfig,
) {
  console.log("send offer from ", selfId, "to", receiver);
  const target = getTargetName(selfId, receiver, videoConfig);
  if (!(target in peerConnectionList)) {
    const localStream = await getWebcamStream(videoConfig.quality);
    if (receiver === selfId && videoRef) {
      videoRef.current.srcObject = localStream;
      return;
    }
    await addNewPeer(
      target,
      selfId,
      receiver,
      accidentId,
      localStream,
      videoConfig,
      videoRef,
    );
    const peerConnection = peerConnectionList[target];

    peerConnection.oniceconnectionstatechange = async e => {
      if (peerConnection.iceConnectionState !== "connected") {
        retryConnectionTimer = setTimeout(() => {
          console.log("retry video connection...");
          send(
            "closeConnection",
            JSON.stringify(videoConfig),
            accidentId,
            receiver,
            selfId,
          );
          closeConnection(selfId, receiver, videoConfig);
          createOffer(receiver, selfId, accidentId, videoRef, videoConfig);
        }, [7000]);
      } else if (retryConnectionTimer) {
        clearTimeout(retryConnectionTimer);
      }
    };

    // send offer
    // peerConnection.onnegotiationneeded = async () => {
    sendOffer(accidentId, receiver, selfId, peerConnection, videoConfig);
    // };
  } else if (videoRef) {
    videoRef.current.srcObject = streamList[receiver];
  }
}

export async function createAnswer(payload, receiver, selfId, accidentId) {
  try {
    console.log("send answer from ", selfId, "to", receiver);
    const { offer, videoConfig } = JSON.parse(payload);
    const target = getTargetName(selfId, receiver, videoConfig);
    const localStream = await getWebcamStream(videoConfig.quality);
    await addNewPeer(
      target,
      selfId,
      receiver,
      accidentId,
      localStream,
      videoConfig,
    );
    const peerConnection = peerConnectionList[target];

    peerConnection.setRemoteDescription(offer);
    const answer = await peerConnection.createAnswer();
    const answerPayload = { answer, videoConfig };
    send("answer", JSON.stringify(answerPayload), accidentId, receiver, selfId);
    peerConnection.setLocalDescription(answer);
  } catch (err) {
    console.log(err);
  }
}

export function addAnswer(selfId, payload, sender) {
  try {
    const { answer, videoConfig } = JSON.parse(payload);
    const target = getTargetName(selfId, sender, videoConfig);
    if (!peerConnectionList[target]) {
      console.log("no peerConnection", target);
      return;
    }
    peerConnectionList[target].setRemoteDescription(answer);
  } catch (err) {
    console.log(err);
  }
}

export function addCandidate(selfId, payload, sender) {
  const { candidateData, videoConfig } = JSON.parse(payload);
  const target = getTargetName(selfId, sender, videoConfig);
  if (!candidateQueue[target]) {
    candidateQueue[target] = [];
  }
  candidateQueue[target].push(candidateData);
  try {
    if (peerConnectionList[target]?.remoteDescription) {
      candidateQueue[target].forEach(item => {
        peerConnectionList[target].addIceCandidate(item).catch(err => {
          console.log(err);
        });
      });
      delete candidateQueue[target];
    }
  } catch (err) {
    console.log(err);
  }
}

export function closeConnection(selfId, peer, videoConfig = initVideoConfig) {
  const target = getTargetName(selfId, peer, videoConfig);
  if (target in peerConnectionList) {
    peerConnectionList[target].removeTrack(
      peerConnectionList[target].getSenders()[0],
    );
    peerConnectionList[target].close();
    delete peerConnectionList[target];
    delete streamList[target];
  }
}
export function getRemoteStream(peer) {
  if (peer in peerConnectionList) {
    return streamList[peer];
  }
  return null;
}
export function getLowResolutionStreams() {
  // exclude high resolution streams
  const lowQualityStreamList = Object.entries(streamList).filter(item => {
    return !item[0].endsWith("High");
  });
  const filteredStream = lowQualityStreamList.map(item => {
    const [id, stream] = item;
    return {
      vehicleId: id,
      streamId: stream.id,
      stream,
    };
  });
  return filteredStream;
}

export function checkConnectionStatus(peer) {
  if (
    peer in peerConnectionList &&
    peerConnectionList[peer].connectionState === "connected"
  ) {
    return true;
  }
  return false;
}

export function closeFailedConnections(accidentId, selfId) {
  const failedPeerList = [];
  const failedConnections = Object.entries(peerConnectionList).filter(item => {
    return (
      item[1].iceConnectionState !== "connected" &&
      item[1].iceConnectionState !== "completed"
    );
  });
  failedConnections.forEach(item => {
    let videoConfig = initVideoConfig;
    if (item[0].endsWith("High")) {
      videoConfig = {
        quality: "high",
        trigger: selfId,
      };
    }
    send(
      "closeConnection",
      JSON.stringify(videoConfig),
      accidentId,
      item[0],
      selfId,
    );
    closeConnection(selfId, item[0], videoConfig);
    failedPeerList.push(item[0]);
  });
  return failedPeerList;
}

export function closeAllPeerConnections() {
  Object.entries(peerConnectionList).forEach(entry => {
    peerConnectionList[entry[0]].close();
    delete peerConnectionList[entry[0]];
    delete streamList[entry[0]];
  });
}
