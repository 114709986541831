import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  Button,
  Tooltip,
} from "@material-ui/core";
import { Close, Fullscreen } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { CustomButton, RefreshButton, HeaderTitle } from "components";
import {
  getLowResolutionStreams,
  createOffer,
  closeConnection,
  send,
  closeFailedConnections,
} from "utils/webRTC";
import useInterval from "hooks/useInterval";
import { publish } from "utils/socket";
import { setCenterEffect } from "utils/naverMaps";

function VideoStreaming() {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { vehicle } = useSelector(state => state.vehicle);
  const { accident } = useSelector(state => state.accident);
  const { map } = useSelector(state => state.location);
  const classes = useStyles();
  const [refresh, setRefresh] = useState(false);
  const [streamList, setStreamList] = useState([]);
  const [open, setOpen] = useState(false);
  const [zoomVehicleId, setZoomVehicleId] = useState("");
  const videoZoom = useRef();

  const compareAndSetList = (arrA, arrB) => {
    if (JSON.stringify(arrA) !== JSON.stringify(arrB)) {
      setStreamList(arrB);
    }
  };

  const reconnect = () => {
    const failedList = closeFailedConnections(accident.id, vehicle.id);
    if (failedList.length > 0) {
      publish(
        JSON.stringify({
          type: "webrtcInvitation",
          topicId: accident.id,
          contents: "Invitation for WebRTC",
          sender: vehicle.id,
          createdAt: Date.now(),
        }),
        "/pub/message",
      );
    }
  };

  const handleRefreshConnection = () => {
    setRefresh(true);
    setZoomVehicleId("");
    reconnect();
    setStreamList(getLowResolutionStreams());
    setRefresh(false);
  };

  const handleClickVideo = target => {
    dispatch.location.setFocusId(target);
    const marker = dispatch.location.getMarkerById({ id: target });
    setCenterEffect(map, marker);
  };

  const handleZoomVideo = id => {
    setZoomVehicleId(id);
    setOpen(true);
    // 여기서 createOffer를 하면 videoZoom.current가 null이어서 오류 발생
    createOffer(id, vehicle.id, accident.id, videoZoom, {
      quality: "high",
      trigger: vehicle.id,
    });
  };

  const handleCloseVideo = () => {
    setOpen(false);
    send(
      "closeConnection",
      JSON.stringify({ quality: "high", trigger: vehicle.id }),
      accident.id,
      zoomVehicleId,
      vehicle.id,
    );
    closeConnection(vehicle.id, zoomVehicleId, {
      quality: "high",
      trigger: vehicle.id,
    });
  };

  useEffect(() => {
    streamList.forEach(obj => {
      document.getElementById(obj.vehicleId).srcObject = obj.stream;
    });
  }, [streamList]);

  useInterval(() => {
    compareAndSetList(streamList, getLowResolutionStreams());
  }, [1000]);

  useEffect(() => {
    if (!accident) {
      navigator("/participant");
    } else {
      publish(
        JSON.stringify({
          type: "webrtcInvitation",
          topicId: accident.id,
          contents: "Invitation for WebRTC",
          sender: vehicle.id,
          createdAt: Date.now(),
        }),
        "/pub/message",
      );
    }
  }, []);

  useEffect(() => {
    dispatch.controller.setVideoVisible(true);
    return () => {
      dispatch.controller.setVideoVisible(false);
    };
  }, []);
  return (
    <>
      <Box>
        <HeaderTitle>
          <Typography variant="h4">차량 영상</Typography>
          <CustomButton
            title="영상 새로고침"
            onClick={handleRefreshConnection}
            classes={classes.refreshButton}
          >
            <RefreshButton loading={refresh} />
          </CustomButton>
        </HeaderTitle>
        <Grid container direction="row" spacing={2} className={classes.info}>
          <Grid item xs={8}>
            <Typography variant="h5">현재 연결된 차량 </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5">{streamList.length}대</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={1}>
          {streamList.map(obj => (
            <Grid item xs={6} key={obj.vehicleId}>
              <Card
                onClick={() => {
                  handleClickVideo(obj.vehicleId);
                }}
              >
                <CardMedia component="video" autoPlay id={obj.vehicleId} />
                <CardContent>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={6}>
                      <Typography variant="body1" display="inline">
                        {obj.vehicleId}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip title="영상 확대">
                        <Button
                          size="small"
                          color="primary"
                          display="inline"
                          onClick={() => {
                            handleZoomVideo(obj.vehicleId);
                          }}
                        >
                          <Fullscreen />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Dialog
        open={open}
        onClose={handleCloseVideo}
        maxWidth="md"
        fullWidth
        style={{ zIndex: 9999 }}
      >
        <CardContent
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {zoomVehicleId}
          <Button onClick={handleCloseVideo}>
            <Close />
          </Button>
        </CardContent>
        <CardMedia component="video" autoPlay ref={videoZoom} />
      </Dialog>
    </>
  );
}

const useStyles = makeStyles({
  refreshButton: {
    marginLeft: "0.1rem",
  },
  info: {
    marginTop: "2rem",
    marginBottom: "2rem",
    marginLeft: "1rem",
  },
});
export default VideoStreaming;
