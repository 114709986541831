/* eslint-disable no-unused-vars */
import { instance as apiClient } from "apiClient";
import { DrawPolylineList } from "utils/naverMaps";

const initialState = {
  vehicle: {},
  tempVehicle: {},
  vehicles: [],
  accidentVehicles: [],
  participatedVehicle: null,
  isDepart: false,
};

export default {
  state: initialState,
  reducers: {
    setVehicleInfo(state, payload) {
      state.vehicle = payload;
    },
    setTempVehicle(state, payload) {
      state.tempVehicle = payload;
    },
    setParticipatedVehicle(state, payload) {
      state.participatedVehicle = payload;
    },
    setVehicles(state, payload) {
      state.vehicles = payload;
    },
    setAccidentVehicles(state, payload) {
      state.accidentVehicles = payload;
    },
    setIsDepart(state, payload) {
      state.isDepart = payload;
    },
  },
  effects: dispatch => ({
    async getVehicle(payload) {
      const { data } = await apiClient.get(
        `/api/v1/vehicles/info/${payload.vehicleId}`,
      );

      if (payload?.detail) {
        this.setParticipatedVehicle(data);
        return data;
      }
      this.setVehicleInfo(data);
      return data;
    },
    async getTempVehicle(payload) {
      const { data } = await apiClient.get(
        `/api/v1/vehicles/info/${payload.vehicleNumber}`,
      );
      this.setTempVehicle(data);
      return data;
    },
    async getVehicles(payload) {
      const params = payload?.search ? `?search=${payload.search}` : "";
      const query = `/api/v1/vehicles${params}`;
      const { data } = await apiClient.get(query);
      this.setVehicles(data);
    },
    async getAccidentVehicles(payload) {
      const { data } = await apiClient.get(
        `/api/v1/vehicles/${payload.accidentId}`,
      );
      this.setAccidentVehicles(data);
    },
    async createVehicle(payload) {
      try {
        const response = await apiClient.post(`/api/v1/vehicles`, payload);
        this.setVehicleInfo(response.data);
        return response;
      } catch (error) {
        return error;
      }
    },
    async deleteVehicle(payload) {
      await apiClient.delete(`/api/v1/vehicles/${payload}`);
    },
    async updateVehicle(payload, state) {
      try {
        const response = await apiClient.put(`/api/v1/vehicles`, payload);
        this.setVehicleInfo(response.data);
        return response;
      } catch (error) {
        return error.response;
      }
    },
    async joinAccident(payload) {
      await apiClient.put(
        `/api/v1/vehicles/${payload.accidentId}`,
        payload.vehicle,
      );
    },
    async leaveAccident(payload) {
      await apiClient.put(
        `/api/v1/vehicles/${payload.accidentId}/${payload.vehicleId}`,
      );
    },
    getMyVehicleNumber(_, state) {
      return state.vehicle.vehicle.id;
    },
    getLocalAccidentVehicle(_, state) {
      return state.vehicle.accidentVehicles;
    },
  }),
};
