/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from "react";
import { vehicleTypeList } from "constants";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormLabel,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { SnackbarMessage } from "components";

function VehicleDialog({
  modalVisible,
  onCloseModal,
  isUpdate,
  vehicle,
  setVehicle,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { jurisdictions } = useSelector(state => state.jurisdiction);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const handleChangeVehicle = (inputKey, inputValue) => {
    const changedData = produce(vehicle, draft => {
      draft[inputKey] = inputValue;
    });
    setVehicle(changedData);
  };

  function validate() {
    if (!vehicle.id || !vehicle.type) {
      setError(true);
      setMessage("값을 입력해주세요.");
      return false;
    }
    return true;
  }

  const onClickSubmit = async () => {
    if (!validate()) return;
    let response;
    if (isUpdate) {
      response = await dispatch.vehicle.updateVehicle(vehicle);
    } else {
      response = await dispatch.vehicle.createVehicle(vehicle);
    }
    if (response.status !== 200) {
      setError(true);
      setMessage("중복된 값입니다.");
      return;
    }
    await dispatch.vehicle.getVehicles();
    onCloseModal();
  };

  useEffect(() => {
    dispatch.jurisdiction.getJurisdictions();
  }, []);

  if (!jurisdictions) {
    return null;
  }

  return (
    <>
      <Dialog
        open={modalVisible}
        onClose={onCloseModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{isUpdate ? "차량 정보 수정" : "차량 등록"}</DialogTitle>
        <DialogContent dividers>
          <Grid container direction="column" spacing={3}>
            <Grid item m={3}>
              <TextField
                error={!vehicle.id}
                helperText={error && !vehicle.id && "값을 입력해주세요."}
                autoFocus
                label="차량명"
                value={vehicle.id || ""}
                onChange={e => handleChangeVehicle("id", e.target.value)}
              />
            </Grid>
            <Grid item>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">차량 종류</FormLabel>
                <Select
                  error={!vehicle.type}
                  aria-label="Vehicle Type"
                  id="vehicleType"
                  value={vehicle.type || ""}
                  onChange={e => handleChangeVehicle("type", e.target.value)}
                >
                  {vehicleTypeList.map((item, idx) => (
                    <MenuItem key={`${item.value}-${idx}`} value={item.label}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {error && !vehicle.type && "값을 입력해주세요."}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              {/* <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend">관할서</FormLabel> */}
              <TextField
                error={!vehicle.jurisdiction}
                helperText={
                  error && !vehicle.jurisdiction && "값을 입력해주세요."
                }
                autoFocus
                label="관할서"
                value={vehicle.jurisdiction || ""}
                onChange={e =>
                  handleChangeVehicle("jurisdiction", e.target.value)
                }
              />
              {/* <Select
                  aria-label="Jurisdiction"
                  name="vehicleJurisdiction"
                  value={vehicle.jurisdiction?.name || "None"}
                  onChange={e => {
                    const selectedJurisdiction = jurisdictions.find(
                      item => item.name === e.target.value,
                    );
                    handleChangeVehicle("jurisdiction", selectedJurisdiction);
                  }}
                >
                  <MenuItem key="none" value="None">
                    없음
                  </MenuItem>
                  {jurisdictions.map(item => (
                    <MenuItem key={item.name} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select> */}
              {/* </FormControl> */}
            </Grid>
            <Grid item m={3}>
              <TextField
                error={!vehicle.number}
                helperText={error && !vehicle.number && "값을 입력해주세요."}
                autoFocus
                label="차량 번호"
                value={vehicle.number || ""}
                onChange={e => handleChangeVehicle("number", e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.formActions}>
          <Button onClick={onClickSubmit} variant="outlined" color="primary">
            저장
          </Button>
          <Button onClick={onCloseModal} variant="outlined" color="secondary">
            취소
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarMessage error={error} setError={setError} message={message} />
    </>
  );
}

const useStyles = makeStyles({
  formControl: {
    minWidth: 120,
  },
  formActions: {
    paddingTop: "2rem",
    justifyContent: "center",
  },
});

export default VehicleDialog;
