import { IconButton } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { Close } from "@material-ui/icons";

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      onClick={e => {
        e.stopPropagation();
        closeSnackbar(snackbarKey);
      }}
    >
      <Close />
    </IconButton>
  );
}

export default SnackbarCloseButton;
