import axios from "axios";
import { BASE_URLS } from "configs";

export const uploadStatusTemp = {
  offset: 0,
  tempPath: "",
};

export const uploadRequestTemp = {
  subPath: "",
  total: 0,
  data: "",
  dataLength: 0,
  status: uploadStatusTemp,
};

export async function upload({ urlPath, body }) {
  try {
    const response = await axios.post(
      `${BASE_URLS.FIRE_PATH_URL}/api/v1/${urlPath}`,
      body,
    );
    return response;
  } catch (error) {
    return error;
  }
}

export function splitBase64String(
  base64String,
  // chunkSize: base64 length = filesize(B) * 4 / 3
  chunkSize = Math.round((2 * 1024 * 1024 * 4) / 3),
) {
  const regex = new RegExp(`.{1,${chunkSize}}`, "g");
  return base64String.match(regex);
}

export function getSnapshotData({ camRef, canvasId }) {
  const canvas = document.getElementById(canvasId);
  // set webcam width, height
  canvas.width = 1920;
  canvas.height = 1080;

  canvas
    .getContext("2d")
    .drawImage(
      camRef.current,
      0,
      0,
      camRef.current.clientWidth,
      camRef.current.clientHeight,
    );
  const base64 = canvas.toDataURL("image/jpeg").split(";base64,")[1];
  return base64;
}
