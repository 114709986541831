import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Button,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import VehicleDialog from "./VehicleDialog";
import { AlertDialog, BreadCrumbs, StickyHeadTable } from "components";
import { vehicleColumns } from "utils/dataSetting";

const breadCrumbs = [
  {
    id: "vehicles",
    label: "차량 목록",
    path: "/admin/vehicles",
    active: false,
  },
];

const initVehicle = {
  id: undefined,
  type: undefined,
  jurisdiction: undefined,
  jurisdictionAddress: undefined,
  jurisdictionLatitude: undefined,
  jurisdictionLongitude: undefined,
  number: undefined,
  status: "inactive",
};

function VehicleList() {
  const { vehicles } = useSelector(state => state.vehicle);
  const dispatch = useDispatch();
  const [vehicle, setVehicle] = useState(initVehicle);
  const [checkBoxList, setCheckBoxList] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);

  const onClickAdd = () => {
    setModalVisible(true);
  };

  const onClickDelete = () => {
    setDeleteAlert(true);
  };

  const onClickDeleteConfirm = async () => {
    const promises = checkBoxList.map(key => {
      return dispatch.vehicle.deleteVehicle(key);
    });
    await Promise.all(promises);
    await dispatch.vehicle.getVehicles();
    setCheckBoxList([]);
    setDeleteAlert(false);
  };

  const onCloseModal = () => {
    setModalVisible(false);
    setIsUpdate(false);
    setVehicle(initVehicle);
  };

  const onClickInfo = vehicleInfo => {
    setIsUpdate(true);
    setVehicle(vehicleInfo);
    setModalVisible(true);
  };

  useEffect(() => {
    dispatch.vehicle.getVehicles();
    dispatch.jurisdiction.getJurisdictions();
    setCheckBoxList([]);
  }, []);

  return (
    <Box>
      <BreadCrumbs items={breadCrumbs} />
      <Grid container>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Box m={1} pb={3}>
                <Grid container justifyContent="space-between" spacing={3}>
                  <Grid item>
                    <Typography variant="h5" gutterBottom>
                      차량 목록
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container alignitmes="center" spacing={2}>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={onClickAdd}
                        >
                          차량 추가
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={onClickDelete}
                        >
                          차량 제거
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box m={1}>
                <StickyHeadTable
                  columns={vehicleColumns}
                  rows={vehicles}
                  onClickRow={onClickInfo}
                  useCheckbox
                  checkBoxList={checkBoxList}
                  setCheckBoxList={setCheckBoxList}
                />
              </Box>
              <Box>
                <VehicleDialog
                  modalVisible={modalVisible}
                  onCloseModal={onCloseModal}
                  isUpdate={isUpdate}
                  vehicle={vehicle}
                  setVehicle={setVehicle}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <AlertDialog
        title="차량 제거"
        description="제거하시겠습니까?"
        onClose={() => {
          setDeleteAlert(false);
        }}
        open={deleteAlert}
        onAgree={onClickDeleteConfirm}
        dis
      />
    </Box>
  );
}

export default VehicleList;
