const mediaRecorder = {
  webcam: {
    recorder: null,
    blob: [],
  },
  screen: {
    recorder: null,
    blob: [],
  },
};

export function startRecord(stream, type, callback) {
  if (mediaRecorder[type].recorder) {
    return;
  }
  mediaRecorder[type].blob = [];
  mediaRecorder[type].recorder = new MediaRecorder(stream, {
    mimeType: "video/webm",
  });
  mediaRecorder[type].recorder.onstart = e => {
    console.log("Start Recording", e);
  };
  mediaRecorder[type].recorder.onstop = e => {
    console.log("Stop Recording", e);
  };
  mediaRecorder[type].recorder.ondataavailable = async e => {
    if (e.data && e.data.size > 0) {
      mediaRecorder[type].blob.push(e.data);
      const blob = new Blob(mediaRecorder[type].blob, { type: "video/webm" });
      await callback(e, blob);
    }
  };
  mediaRecorder[type].recorder.start();
}

export function stopRecord(type) {
  if (mediaRecorder[type].recorder) {
    mediaRecorder[type].recorder.stop();
    mediaRecorder[type].recorder = null;
  }
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KiB", "MiB", "GiB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}
