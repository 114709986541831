import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

function divideListIntoGroups(list, groupSize) {
  const dividedGroups = [];

  for (let i = 0; i < list.length; i += groupSize) {
    const group = list.slice(i, i + groupSize);
    dividedGroups.push(group);
  }

  return dividedGroups;
}

function keyText({ classes, mappingKey }) {
  return mappingKey.split("+").map(item => (
    <Typography key={item} className={classes.keyText}>
      {item}
    </Typography>
  ));
}

export default function HotKeysHelper({
  isOpen = false,
  onClose = () => {},
  keyMaps = {
    title: "",
    mappings: [],
  },
}) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width: 1000px)");

  const itemsPerPage = isMobile ? 10 : 20;
  const [page, setPage] = useState(1);
  const pageData = keyMaps.mappings.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage,
  );
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const columnData = divideListIntoGroups(pageData, 10);
  const twoColumns = columnData.length > 1;

  useEffect(() => {
    setPage(1);
  }, [keyMaps.mappings]);

  useEffect(() => {
    setPage(isMobile ? page * 2 - 1 : Math.ceil(page / 2));
  }, [isMobile]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth={twoColumns ? "md" : "xs"}
    >
      <DialogTitle>{keyMaps.title}</DialogTitle>
      <DialogContent>
        <Box className={classes.total}>
          {columnData.map((column, index, arr) => (
            <React.Fragment key={column[0].name}>
              <List className={classes.helperWindow}>
                {column.map((mapping, idx, array) => (
                  <React.Fragment key={mapping.name}>
                    <ListItem className={classes.helperRow}>
                      <ListItemText
                        primaryTypographyProps={{
                          variant: "subtitle1",
                          className: classes.keyDescription,
                        }}
                        primary={mapping.name}
                      />
                      <ListItemText
                        primaryTypographyProps={{
                          className: classes.hotkeysIcon,
                        }}
                        primary={keyText({ classes, mappingKey: mapping.key })}
                      />
                    </ListItem>
                    {idx !== array.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
              {index !== arr.length - 1 && (
                <Divider orientation="vertical" flexItem />
              )}
            </React.Fragment>
          ))}
        </Box>
        <Box className={classes.pagination}>
          <Pagination
            count={Math.ceil(keyMaps.mappings.length / itemsPerPage)}
            page={page}
            onChange={handleChangePage}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles({
  total: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
  },
  helperWindow: {
    marginRight: "3rem",
    marginLeft: "3rem",
  },
  helperRow: {
    padding: "5px",
    width: "350px",
  },
  keyDescription: {},
  hotkeysIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  keyText: {
    background: "#f0f0f0",
    borderRadius: "5px",
    padding: "5px 10px",
    fontWeight: "bold",
    margin: "2px",
  },
  pagination: { display: "flex", justifyContent: "center", marginTop: "10px" },
});
