import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AccidentDialog from "./AccidentDialog";
import BlockerDialog from "./BlockerDialog";
import {
  AlertDialog,
  BreadCrumbs,
  DependencyUpdateDialog,
  StickyHeadTable,
} from "components";
import {
  blockerColumns,
  vehicleColumns,
  vehicleShowList,
  vehicleVideoColumns,
} from "utils/dataSetting";

import VehicleDialog from "pages/vehicles/VehicleDialog";
import { dateFormat } from "utils";

const breadCrumbs = [
  {
    id: "accidents",
    label: "재난",
    path: "/admin/accidents",
    active: false,
  },
  {
    id: "info",
    label: "재난 정보",
    active: true,
  },
];

function AccidentInfoItem({ label, value }) {
  return (
    <Box m={1} mb={3}>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={2}
          style={{ fontWeight: "bold", marginBottom: "6px" }}
        >
          {label}
        </Grid>
        <Grid
          item
          xs={12}
          md={10}
          style={{
            overflowWrap: "break-word",
          }}
        >
          {value}
        </Grid>
      </Grid>
    </Box>
  );
}

const initVehicle = {
  id: undefined,
  type: undefined,
  jurisdiction: undefined,
  jurisdictionAddress: undefined,
  jurisdictionLatitude: undefined,
  jurisdictionLongitude: undefined,
  number: undefined,
};

const initSnackBarProp = {
  isOpen: false,
  severity: "error",
  message: "",
};

function AccidentInfo() {
  const classes = useStyle();
  const { accidentId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { vehicles } = useSelector(state => state.vehicle);
  const { accident } = useSelector(state => state.accident);
  const { blockers } = useSelector(state => state.blocker);
  const { vehicleVideos } = useSelector(state => state.video);
  const { accidentVehicles } = useSelector(state => state.vehicle);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState(false);
  const [isOpenVehicleDialog, setIsOpenVehicleDialog] = useState(false);
  const [isOpenBlockerDialog, setIsOpenBlockerDialog] = useState(false);
  const [blocker, setBlocker] = useState({});
  const [blockerCheckBox, setBlockerCheckBox] = useState([]);
  const [vehicleVideoCheckBox, setVehicleVideoCheckBox] = useState([]);
  const [vehicle, setVehicle] = useState(initVehicle);
  const [vehicleModalVisible, setVehicleModalVisible] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [blockerDeleteAlert, setBlockerDeleteAlert] = useState(false);
  const [vehicleVideoDeleteAlert, setVehicleVideoDeleteAlert] = useState(false);
  const [endDialog, setEndDialog] = useState(false);

  const onClickEnd = () => {
    setEndDialog(true);
  };
  const onClickEndConfirm = () => {
    const endAccident = { ...accident, status: "상황종료" };
    dispatch.accident.updateAccident(endAccident);
    setEndDialog(false);
  };

  const onClickVehicleDownload = async () => {
    const response = await dispatch.download.downloadVehicleCsv({ accidentId });
    download(response, "vehicle", "csv");
  };

  const onClickInferenceDownload = async () => {
    const response = await dispatch.download.downloadInferenceCsv({
      accidentId,
    });
    download(response, "accident", "zip");
  };

  const onClickBlockerDownload = async () => {
    const response = await dispatch.download.downloadBlockerCsv({ accidentId });
    download(response, "blocker", "zip");
  };

  const download = (response, type, ext) => {
    if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `${accidentId}-${type}-result.${ext}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } else {
      let message = "다운로드 실패";
      let variant = "error";
      if (response.status === 404) {
        message = "추적기록이 없습니다";
        variant = "warning";
      }
      enqueueSnackbar(message, {
        variant,
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    }
  };

  const onClickEdit = () => {
    setIsOpenEditDialog(true);
  };
  const handleCloseDialog = () => {
    setIsOpenEditDialog(false);
  };
  const onClickDelete = () => {
    setDeleteAlert(true);
  };
  const onClickDeleteConfirm = () => {
    dispatch.accident.deleteAccident(accidentId);
    navigate("/admin/accidents");
  };

  const onClickBlockerCreate = () => {
    setIsOpenBlockerDialog(true);
  };
  const handleCloseBlockerDialog = () => {
    setBlocker({});
    setIsOpenBlockerDialog(false);
  };
  const onClickBlockerDelete = () => {
    setBlockerDeleteAlert(true);
  };
  const onClickBlockerDeleteConfirm = async () => {
    const promises = blockerCheckBox.map(key => {
      const [deletedBlocker] = blockers.filter(b => b.id === key);
      const payload = {
        accidentId,
        blocker: deletedBlocker,
        vehicleId: vehicle.id,
      };
      return dispatch.blocker.deleteBlocker(payload);
    });
    await Promise.all(promises);
    setBlockerCheckBox([]);
    setBlockerDeleteAlert(false);
  };

  const onClickVehicleVideoDelete = () => {
    setVehicleVideoDeleteAlert(true);
  };
  const onClickVehicleVideoDeleteConfirm = async () => {
    const promises = vehicleVideoCheckBox.map(key => {
      const payload = {
        accidentId,
        fileName: key,
      };
      return dispatch.video.deleteVideo(payload);
    });
    await Promise.all(promises);
    setVehicleVideoCheckBox([]);
    setVehicleVideoDeleteAlert(false);
    dispatch.video.getVehicleVideos({
      accidentId,
    });
  };
  const handleClickRowBlocker = row => {
    setBlocker(row);
    setIsOpenBlockerDialog(true);
  };

  const onClickUpdateVehicle = () => {
    setIsOpenVehicleDialog(true);
  };
  const handleCloseVehicleDialog = () => {
    setIsOpenVehicleDialog(false);
    dispatch.vehicle.getAccidentVehicles({ accidentId });
  };
  const onClickVehicle = vehicleInfo => {
    setVehicle(vehicleInfo);
    setVehicleModalVisible(true);
  };

  const onClickVehicleVideo = async videoInfo => {
    const data = await dispatch.video.downloadVideo({
      accidentId,
      fileName: videoInfo.name,
    });
    if (data && data.size > 0) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = videoInfo.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  const onCloseVehicleModal = () => {
    setVehicleModalVisible(false);
  };

  useEffect(() => {
    dispatch.accident.getAccident({ id: accidentId });
    dispatch.vehicle.getAccidentVehicles({ accidentId });
    dispatch.blocker.getBlockers({ accidentId });
    dispatch.vehicle.getVehicles();
    dispatch.video.getVehicleVideos({
      accidentId,
    });
  }, []);

  useEffect(() => {
    dispatch.vehicle.getAccidentVehicles({ accidentId });
  }, [vehicles]);

  if (!accident) {
    return null;
  }

  return (
    <Box>
      <BreadCrumbs items={breadCrumbs} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              {/* accidents info header */}
              <Box m={1} pb={3}>
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Typography variant="h5">재난 정보</Typography>
                      </Grid>
                      <Grid item>
                        {!(accident.status === "상황종료") ? (
                          <Button
                            onClick={onClickEnd}
                            variant="outlined"
                            color="primary"
                          >
                            재난 종료
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Box className={classes.editButtons}>
                      <Box className={classes.editButton}>
                        <Button
                          onClick={onClickInferenceDownload}
                          variant="outlined"
                          color="default"
                        >
                          다운로드
                        </Button>
                      </Box>
                      <Box className={classes.editButton}>
                        <Button
                          onClick={onClickEdit}
                          variant="outlined"
                          color="primary"
                        >
                          수정
                        </Button>
                      </Box>
                      <Box className={classes.editButton}>
                        <Button
                          onClick={onClickDelete}
                          variant="outlined"
                          color="secondary"
                        >
                          제거
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {/* accidents info */}
              <Box m={1}>
                {/* Accident Info */}
                <Grid container>
                  <Grid item xs={12}>
                    <AccidentInfoItem label="재난번호" value={accident.id} />
                    <AccidentInfoItem label="이름" value={accident.name} />
                    <AccidentInfoItem label="종류" value={accident.category} />
                    <AccidentInfoItem
                      label="분류"
                      // value={dataToKorean(
                      //   accident.type,
                      //   findChildren(accident.category, accidentCategoryList),
                      // )}
                      value={accident.type}
                    />
                    <AccidentInfoItem
                      label="관할서"
                      value={
                        accident.jurisdiction ? accident.jurisdiction : "없음"
                      }
                    />
                    <AccidentInfoItem
                      label="상태"
                      // value={dataToKorean(accident.status, accidentStatusList)}
                      value={accident.status}
                    />
                    <AccidentInfoItem
                      label="위치"
                      value={accident.location ? accident.location.address : ""}
                    />
                    <AccidentInfoItem
                      label="설명"
                      value={accident.description}
                    />
                    <AccidentInfoItem
                      label="생성 시간"
                      value={dateFormat(
                        accident.createdAt,
                        "YYYY년 MM월 DD일 HH:mm:ss",
                      )}
                    />
                    <AccidentInfoItem
                      label="종료 시간"
                      value={
                        accident.finishedAt &&
                        dateFormat(
                          accident.finishedAt,
                          "YYYY년 MM월 DD일 HH:mm:ss",
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* Blockers */}
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Box m={1} pb={3}>
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <Typography variant="h5">진입 장애 구역</Typography>
                  </Grid>
                  <Grid item>
                    <Box className={classes.editButtons}>
                      <Box className={classes.editButton}>
                        <Button
                          onClick={onClickBlockerDownload}
                          variant="outlined"
                          color="default"
                        >
                          다운로드
                        </Button>
                      </Box>
                      <Box className={classes.editButton}>
                        <Button
                          onClick={onClickBlockerCreate}
                          variant="outlined"
                          color="primary"
                        >
                          생성
                        </Button>
                      </Box>
                      <Box className={classes.editButton}>
                        <Button
                          onClick={onClickBlockerDelete}
                          variant="outlined"
                          color="secondary"
                        >
                          제거
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box m={1}>
                <Grid container direction="column">
                  <StickyHeadTable
                    columns={blockerColumns}
                    rows={blockers}
                    onClickRow={handleClickRowBlocker}
                    useCheckbox
                    checkBoxList={blockerCheckBox}
                    setCheckBoxList={setBlockerCheckBox}
                  />
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* Vehicles */}
        <Grid item xs={12}>
          <Card variant="outlined">
            <CardContent>
              <Box m={1} pb={3}>
                <Grid
                  container
                  alignItems="flex-start"
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <Typography variant="h5">차량</Typography>
                  </Grid>
                  <Grid item>
                    <Box className={classes.editButtons}>
                      <Box className={classes.editButton}>
                        <Button
                          onClick={onClickVehicleDownload}
                          variant="outlined"
                          color="default"
                        >
                          다운로드
                        </Button>
                      </Box>
                      <Box className={classes.editButton}>
                        <Button
                          onClick={onClickUpdateVehicle}
                          variant="outlined"
                          color="primary"
                        >
                          변경
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box m={1}>
                <Grid container direction="column">
                  <StickyHeadTable
                    columns={vehicleColumns}
                    rows={accidentVehicles}
                    onClickRow={onClickVehicle}
                  />
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardContent>
            <Box m={1} pb={3}>
              <Grid
                container
                alignItems="flex-start"
                justifyContent="space-between"
                spacing={3}
              >
                <Grid item>
                  <Typography variant="h5">차량 영상</Typography>
                </Grid>
                <Grid item>
                  <Box className={classes.editButtons}>
                    <Box className={classes.editButton}>
                      <Button
                        onClick={onClickVehicleVideoDelete}
                        variant="outlined"
                        color="primary"
                      >
                        삭제
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box m={1}>
              <Grid container direction="column">
                <StickyHeadTable
                  columns={vehicleVideoColumns}
                  rows={vehicleVideos}
                  onClickRow={onClickVehicleVideo}
                  useCheckbox
                  checkBoxList={vehicleVideoCheckBox}
                  setCheckBoxList={setVehicleVideoCheckBox}
                />
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <AccidentDialog
        open={isOpenEditDialog}
        onClose={handleCloseDialog}
        newAccident={accident}
        isUpdate
      />
      <BlockerDialog
        modalVisible={isOpenBlockerDialog}
        onCloseModal={handleCloseBlockerDialog}
        newBlocker={blocker}
        accidentId={accidentId}
      />
      <DependencyUpdateDialog
        open={isOpenVehicleDialog}
        onClose={handleCloseVehicleDialog}
        parentKey="accident"
        parent={accident}
        child={accidentVehicles}
        selectList={vehicles}
        selectListFilter={item =>
          !(
            item.accident?.status === "ready" ||
            item.accident?.status === "proceeding"
          )
        }
        itemInfo={vehicleShowList}
        updateFunction={dispatch.vehicle.updateVehicle}
      />
      <VehicleDialog
        modalVisible={vehicleModalVisible}
        onCloseModal={onCloseVehicleModal}
        isUpdate
        vehicle={vehicle}
        setVehicle={setVehicle}
      />
      <AlertDialog
        title="재난 제거"
        description="제거하시겠습니까?"
        onClose={() => {
          setDeleteAlert(false);
        }}
        open={deleteAlert}
        onAgree={onClickDeleteConfirm}
      />
      <AlertDialog
        title="장애 구역 제거"
        description="제거하시겠습니까?"
        onClose={() => {
          setBlockerDeleteAlert(false);
        }}
        open={blockerDeleteAlert}
        onAgree={onClickBlockerDeleteConfirm}
      />
      <AlertDialog
        title="차량 영상 제거 "
        description="제거하시겠습니까 ?"
        onClose={() => {
          setVehicleVideoDeleteAlert(false);
        }}
        open={vehicleVideoDeleteAlert}
        onAgree={onClickVehicleVideoDeleteConfirm}
      />
      <AlertDialog
        title="재난 종료"
        description="재난을 종료하시겠습까?"
        onClose={() => {
          setEndDialog(false);
        }}
        open={endDialog}
        onAgree={onClickEndConfirm}
      />
    </Box>
  );
}
const useStyle = makeStyles({
  editButtons: {
    display: "flex",
  },
  editButton: {
    paddingRight: "0.425rem",
  },
});

export default AccidentInfo;
