import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";

function DirectionDisabledLayer({ children, visibility = false }) {
  const classes = useStyles();

  const layerClassName = visibility
    ? classes.disabledLayerOn
    : classes.disabledLayerOff;

  return (
    <Box className={layerClassName}>
      <Box className={classes.directionDisabled} />
      <Box className={classes.disabledInfoButton}>
        {children || (
          <Button variant="contained" color="primary">
            지도에서 마우스 클릭하여 위치선택
          </Button>
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(() => ({
  disabledLayerOn: { visibility: "visible" },
  disabledLayerOff: { visibility: "hidden" },
  directionDisabled: {
    position: "absolute",
    display: "block",
    width: "100%",
    height: "100%",
    opacity: "0.8",
    backgroundColor: "gray",
    zIndex: 9998,
  },
  disabledInfoButton: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 9999,
    textAlign: "center",
    "& .MuiButtonBase-root": {
      top: "45%",
      "&:hover": {
        background: "#3f51b5",
      },
    },
  },
}));

export default DirectionDisabledLayer;
