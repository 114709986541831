import axios from "axios";
import { BASE_URLS } from "configs";

const instance = axios.create({
  baseURL: BASE_URLS.FIRE_PATH_URL,
});

const localInstance = axios.create({
  baseURL: "http://localhost:5003",
});

// eslint-disable-next-line import/prefer-default-export
export { instance, localInstance };
