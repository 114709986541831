import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ChevronLeft } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import CustomButton from "./CustomButton";

function HeaderTitle({ children, title, prevPath = "" }) {
  const classes = useStyles();
  const navigator = useNavigate();

  const onClickBackBtn = () => navigator(prevPath);

  useHotkeys("b", onClickBackBtn, {
    scopes: ["mainPage"],
    keyup: true,
  });

  return (
    <Box className={classes.title}>
      {prevPath && (
        <CustomButton
          classes={classes.backBtn}
          title="뒤로가기"
          onClick={onClickBackBtn}
        >
          <ChevronLeft />
        </CustomButton>
      )}
      {children || <Typography variant="h4">{title}</Typography>}
    </Box>
  );
}

const useStyles = makeStyles({
  title: {
    width: "100%",
    display: "flex",
    marginTop: "3rem",
    textAlign: "center",
    justifyContent: "center",
  },
  backBtn: {
    position: "absolute",
    left: 0,
  },
});
export default HeaderTitle;
