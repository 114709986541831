const initialState = {
  isOpen: false,
  hotkeys: { mappings: [] },
};

export default {
  state: initialState,
  reducers: {
    setHotkeys(state, payload) {
      state.hotkeys = payload;
    },
    setIsOpen(state, payload) {
      state.isOpen = payload;
    },
  },
  effects: {},
};
