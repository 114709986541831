/* eslint-disable class-methods-use-this */
import { engineClassName, noop, device } from "constants";
import { toPng } from "html-to-image";
import AbstractEngine from "./abstractEngine";
import { addListener } from "utils/naverMaps";

class DemoEngine extends AbstractEngine {
  constructor(...args) {
    super(...args, device.SIMULATOR);
    this.pathCoords = [];
    this.pathIndex = -1;
    this.currentPosition = null;
  }

  // Override
  getName() {
    return engineClassName.demo;
  }

  // Override
  getCurrentPosition(callback = noop) {
    // 안내 시작 모드라면 현재 mock position을 현재 위치로
    if (this.pathCoords?.length > 0 && this.pathIndex !== -1) {
      this.currentPosition = this.convertMockPosition(
        this.pathCoords[this.pathIndex],
      );
      callback(this.currentPosition);
      return;
    }
    if (this.currentPosition === null) {
      // 안내 시작 모드가 아니라면 현재 위치 return
      this.getCurrentPositionWithLocalGPS(callback);
    }
  }

  // Override
  notifyCurrentPosition(callback = noop, errCallback = noop) {
    this.getCurrentPosition(position => {
      this.publishPosition(position);
    });
    callback(this.device);
  }

  // Override
  getCurrentImage(props = {}) {
    const payload = { ...props };
    const pano = new window.naver.maps.Panorama(props.panoEleId, {
      position: new window.naver.maps.LatLng(
        props.position.latitude,
        props.position.longitude,
      ),
      size: new window.naver.maps.Size(600, 400),
      pov: {
        pan: 0,
        tilt: 0,
        fov: 70,
      },
      flightSpot: false,
      logoControl: false,
      visible: true,
    });
    addListener(pano, "pano_status", () => {
      setTimeout(() => {
        const ele = document.getElementById(props.panoEleId);
        toPng(ele).then(dataUrl => {
          const src = dataUrl.split(",")[1];
          payload.body = {
            subPath: "",
            total: 0,
            data: src,
            dataLength: 0,
            status: {
              offset: 0,
              tempPath: "",
            },
          };
          props.callback(payload);
          ele.removeChild(ele.firstChild);
        });
      }, 1000);
    });
  }

  convertMockPosition(position) {
    return {
      latitude: position[1],
      longitude: position[0],
      speed: null,
      heading: null,
    };
  }

  setPathCoords(pathCoords) {
    this.pathCoords = pathCoords;
  }

  setPathIndex(pathIndex) {
    this.pathIndex = pathIndex;
  }
}

export default DemoEngine;
