// eslint-disable-next-line import/no-unresolved
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { TextEncoder } from "text-encoding";
import { BASE_URLS } from "configs";
import { clearListeners } from "utils/naverMaps";
import { instance as apiClient } from "apiClient";

const initClient = () => {
  Object.assign(global, { WebSocket });
  Object.assign(global, { TextEncoder });

  const client = new Client({
    brokerURL: BASE_URLS.FIRE_PATH_URL_WS,
    // forceBinaryWSFrames: true,
    webSocketFactory: () => new SockJS(`${BASE_URLS.FIRE_PATH_URL}/ws`),
    reconnectDelay: 5000,
    heartbeatIncoming: 4000,
    heartbeatOutgoing: 4000,
  });
  return client;
};

export const client = initClient();

const initialState = {
  map: null,
  markers: {},
  scriptLoaded: false,
  simulator: false,
  focusId: null,
  sidebarExpanded: true,
  vehicleLocation: {},
};

export default {
  state: initialState,
  reducers: {
    setMap(state, payload) {
      state.map = payload;
    },
    addMarker(state, payload) {
      state.markers[payload.id] = payload.marker;
    },
    removeMarker(state, payload) {
      delete state.markers[payload.id];
    },
    clearMarker(state) {
      state.markers = {};
    },
    setScriptLoaded(state, payload) {
      state.scriptLoaded = payload;
    },
    setSimulator(state, payload) {
      state.simulator = payload;
    },
    setFocusId(state, payload) {
      state.focusId = payload.id;
    },
    deleteFocusId(state) {
      state.focusId = null;
    },
    setSidebarExpanded(state, payload) {
      state.sidebarExpanded = payload;
    },
    setVehicleLocation(state, payload) {
      state.vehicleLocation = payload;
    },
  },
  effects: {
    getMarkerById(payload, state) {
      return state.location.markers[payload.id];
    },
    deleteMarker(payload, state) {
      const marker = state.location.markers[payload.id];
      if (marker) {
        marker?.setMap(null);
        clearListeners(marker);
        this.removeMarker(payload);
      }
    },
    toggleSimulator(payload, state) {
      this.setSimulator(!state.location.simulator);
    },
    getFocusId(payload, state) {
      return state.location.focusId;
    },
    getSimulator(payload, state) {
      return state.location.simulator;
    },
    toggleSidebar(payload, state) {
      this.setSidebarExpanded(!state.location.sidebarExpanded);
    },
    async searchAddress(payload) {
      const response = await apiClient.get(`/api/v1/location?query=${payload}`);
      return response;
    },
  },
};
