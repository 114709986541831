import React, { useEffect, useState } from "react";
import {
  directionsHotKeys,
  initError,
  initLocation,
  defaultPolylineStyle,
  markerType,
} from "constants";
import { makeStyles } from "@material-ui/styles";
import {
  InputLabel,
  Box,
  Button,
  Divider,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import {
  SwapVerticalCircle,
  AddCircle,
  MyLocation,
  RemoveCircle,
} from "@material-ui/icons";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import PathList from "./PathList";
import DirectionDisabledLayer from "./DirectionDisabledLayer";
import {
  createMarker,
  DrawPolylineList,
  getGeocode,
  getInfoWindow,
  getPosition,
  once,
  removeListener,
  removePathList,
  reverseGeocode,
} from "utils/naverMaps";
import { AddressTextField, CustomButton } from "components";
import { publish } from "utils/socket";

const initErrors = {
  start: initError,
  goal: initError,
  waypoint: initError,
};

function Directions() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    clearPath,
    setIsPathLoading,
    setPathList,
    setStartPoint,
    setGoalPoint,
    setWaypoint,
    setWaypointActive,
    setNavigatingWaypoint,
  } = dispatch.path;
  const { vehicle } = useSelector(state => state.vehicle);
  const { map, markers } = useSelector(state => state.location);
  const { accident } = useSelector(state => state.accident);
  const { engine } = useSelector(state => state.engine);
  const {
    isPathLoading,
    paths,
    startPoint,
    goalPoint,
    waypoint,
    waypointActive,
  } = useSelector(state => state.path);

  const [errors, setErrors] = useState(initErrors);
  const [disabledLayer, setDisabledLayer] = useState(false);
  const [listener, setListener] = useState(null);

  const isNavigating = () => !!paths[vehicle.id];

  const addMarkerByKey = (inputKey, inputValue) => {
    const marker = dispatch.location.getMarkerById({ id: inputKey });
    if (!marker) {
      // 경유지 마커 생성
      dispatch.location.addMarker({
        id: inputKey,
        marker: createMarker({
          position: getPosition(inputValue.latitude, inputValue.longitude),
          map,
          markerType: markerType.etc,
          icon: inputKey,
        }),
      });
    } else {
      // 경유지 마커가 존재하는 경우 포지션 이동
      marker.setPosition(
        getPosition(inputValue.latitude, inputValue.longitude),
      );
    }
  };

  const handleValidatePoint = (inputKey, inputValue) => {
    const changedError = produce(errors, draft => {
      draft[inputKey] = inputValue;
    });
    setErrors(changedError);
  };

  const handleChangePoint = (inputKey, inputValue) => {
    if (inputKey === "start") {
      setStartPoint(inputValue);
    } else if (inputKey === "goal") {
      setGoalPoint(inputValue);
    } else if (inputKey === "waypoint") {
      setWaypoint(inputValue);
    }
    clearPath();
    handleValidatePoint(inputKey, initError);
    addMarkerByKey(inputKey, inputValue);
    map.setCenter(getPosition(inputValue.latitude, inputValue.longitude));
    if (inputKey === "waypoint") {
      rediscoveryPath(inputValue);
    }
  };

  const handleChangeAddress = (inputKey, inputValue, setPoint) => {
    setPoint(inputValue);
    addMarkerByKey(inputKey, inputValue);
    handleValidatePoint(inputKey, initError);
    clearPath();
    if (inputKey === "waypoint" && inputValue.verified) {
      rediscoveryPath(inputValue);
    }
  };

  const handleSwapPoints = () => {
    const start = startPoint;
    const goal = goalPoint;
    const nextErrors = { ...errors };
    nextErrors.start = errors.goal;
    nextErrors.goal = errors.start;
    setStartPoint(goal);
    setGoalPoint(start);
    setErrors(nextErrors);

    clearPath();
  };

  const handleClearPoints = () => {
    if (isNavigating()) {
      // TODO: add toast message
      return;
    }
    clearMarkers();
    clearPath();
    setStartPoint(initLocation);
    setGoalPoint(initLocation);
    setWaypoint(initLocation);
  };

  const validInputs = () => {
    // eslint-disable-next-line prefer-const
    let validate = true;
    // eslint-disable-next-line prefer-const
    let changedError = { ...initErrors };

    // start check
    if (!startPoint.address || errors.start.error || !startPoint.verified) {
      validate = false;
      changedError = {
        ...changedError,
        start: {
          error: true,
          label: "유효하지 않은 주소입니다.",
        },
      };
    }
    //  goal check
    if (!goalPoint.address || errors.goal.error || !goalPoint.verified) {
      validate = false;
      changedError = {
        ...changedError,
        goal: {
          error: true,
          label: "유효하지 않은 주소입니다.",
        },
      };
    }
    //  waypoint check
    if (
      waypointActive &&
      (!waypoint.address || errors.waypoint.error || !waypoint.verified)
    ) {
      validate = false;
      changedError = {
        ...changedError,
        waypoint: {
          error: true,
          label: "유효하지 않은 주소입니다.",
        },
      };
    }

    if (!validate) {
      setErrors(changedError);
    } else {
      setErrors(initErrors);
    }

    return validate;
  };

  const handleSubmitGetPaths = async () => {
    if (!validInputs()) {
      return;
    }
    setIsPathLoading(true);
    clearMarkers();
    clearPath();
    dispatch.path.removePaths({ id: vehicle.id });
    const payload = {
      id: vehicle.id,
      paths: { start: startPoint, goal: goalPoint },
      waypoint: waypointActive && waypoint,
    };
    const pathData = await dispatch.path.getPaths(payload);

    const {
      start: st,
      goal: gl,
      waypoints: wps,
    } = pathData.route.trafast[0].summary;

    dispatch.location.addMarker({
      id: "start",
      marker: createMarker({
        position: getPosition(st.location[1], st.location[0]),
        map,
        markerType: markerType.etc,
        icon: "start",
      }),
    });
    dispatch.location.addMarker({
      id: "goal",
      marker: createMarker({
        position: getPosition(gl.location[1], gl.location[0]),
        map,
        markerType: markerType.etc,
        icon: "goal",
      }),
    });
    if (waypointActive) {
      dispatch.location.addMarker({
        id: `waypoint`,
        marker: createMarker({
          position: getPosition(wps[0].location[1], wps[0].location[0]),
          map,
          markerType: markerType.etc,
          icon: `waypoint`,
        }),
      });
    }

    updatePathList(pathData);
    setIsPathLoading(false);
  };

  const clearMarkers = () => {
    dispatch.location.deleteMarker({ id: "start" });
    dispatch.location.deleteMarker({ id: "goal" });
    dispatch.location.deleteMarker({ id: `waypoint` });
  };

  const handleEndRoute = async () => {
    if (paths[vehicle.id]) {
      removePathList(paths[vehicle.id].paths.polylines);
      dispatch.path.removePaths({ id: vehicle.id });
    }
    clearPath();
    setNavigatingWaypoint(initLocation);
    await dispatch.vehicle.updateVehicle({
      ...vehicle,
      path: null,
      start: null,
      goal: null,
    });
    publish(
      JSON.stringify(
        {
          type: "pathEnd",
          topicId: accident.id,
          contents: "",
          sender: vehicle.id,
          createdAt: Date.now(),
        },
        "/pub/message",
      ),
    );
  };

  const handleGetPosition = e => {
    return { latitude: e.coord.y, longitude: e.coord.x };
  };

  const addGeocodeListener = (updatePoint, updateKey) => {
    setDisabledLayer(true);
    const onceListener = once(map, "click", e => {
      const clickedPosition = handleGetPosition(e);
      reverseGeocode(
        clickedPosition.latitude,
        clickedPosition.longitude,
        updatePoint,
        updateKey,
      );
      cancelListener();
    });
    setListener(onceListener);
  };

  const cancelListener = () => {
    setDisabledLayer(false);
    if (listener) {
      removeListener(listener);
    }
    setListener(null);
  };

  const handleUpdatePointByClick = (response, pointKey) => {
    const payload = {
      address: response.result.items[0].address,
      latitude: response.latitude,
      longitude: response.longitude,
      verified: true,
    };
    handleChangePoint(pointKey, payload);
  };

  const handleAddWaypoint = () => {
    setWaypointActive(true);
  };

  const handleRemoveWaypoint = () => {
    dispatch.location.deleteMarker({ id: `waypoint` });
    setWaypoint(initLocation);
    setWaypointActive(false);
    clearPath();
  };

  const getCurrentPosition = () => {
    return new Promise(resolve => {
      engine.getCurrentPosition(position => {
        reverseGeocode(
          position.latitude,
          position.longitude,
          handleUpdatePointByClick,
          "start",
        );
        resolve();
      });
    });
  };

  const setGoalToAccedentLocation = () => {
    if (accident?.location) {
      // 목적지 재난 발생지점으로 설정
      const accidentPosition = {
        ...accident.location,
        verified: true,
      };
      handleChangePoint("goal", accidentPosition);
    }
  };

  const searchAddress = (label, point) => {
    if (point.address) {
      getGeocode(point, label, handleChangePoint, () => {
        handleValidatePoint(label, {
          error: true,
          label: "주소를 찾을 수 없습니다.",
        });
      });
    }
  };

  const startEndAdornment = {
    function: handleSwapPoints,
    style: "swapButton",
    comment: "출발지 목적지 교환",
  };

  const waypointEndAdornment = {
    function: handleRemoveWaypoint,
    style: "removeButton",
    comment: "경유지 제거",
  };

  const goalEndAdornment = {
    function: handleAddWaypoint,
    style: "addButton",
    comment: "경유지 추가 (최대 1개)",
  };

  const rediscoveryPath = changed => {
    if (isNavigating()) {
      getCurrentPosition().then(() => {
        const myVehicleMarker = markers[vehicle.id];
        reverseGeocode(
          myVehicleMarker.position.y,
          myVehicleMarker.position.x,
          async response => {
            const pathData = await dispatch.path.getPaths({
              id: vehicle.id,
              paths: {
                start: {
                  address: response.result.items[0].address,
                  latitude: response.latitude,
                  longitude: response.longitude,
                },
                goal: goalPoint,
              },
              waypoint: {
                longitude: changed.longitude,
                latitude: changed.latitude,
              },
            });
            updatePathList(pathData);
          },
        );
      });
    }
  };

  const updatePathList = pathData => {
    setPathList([
      {
        id: "traoptimal",
        pathData: pathData.route.traoptimal[0],
        polylines: DrawPolylineList(pathData.route.traoptimal[0], map),
        infoWindow: getInfoWindow(pathData.route.traoptimal[0], map, 1, true),
      },
      {
        id: "trafast",
        pathData: pathData.route.trafast[0],
        polylines: DrawPolylineList(
          pathData.route.trafast[0],
          map,
          defaultPolylineStyle,
        ),
        infoWindow: getInfoWindow(pathData.route.trafast[0], map, 2, false),
      },
      {
        id: "tracomfort",
        pathData: pathData.route.tracomfort[0],
        polylines: DrawPolylineList(
          pathData.route.tracomfort[0],
          map,
          defaultPolylineStyle,
        ),
        infoWindow: getInfoWindow(pathData.route.tracomfort[0], map, 3, false),
      },
    ]);
  };

  useEffect(() => {
    const navigating = isNavigating();
    if (navigating || !markers[vehicle.id]) {
      // 경로안내중일 경우 출발지에 현재 위치 표시하지 않음
      return () => {};
    }

    getCurrentPosition();
    searchAddress("goal", accident.location);
    return () => {
      clearMarkers();
    };
  }, [accident, markers[vehicle.id]]);

  // 경로가 존재할 때 출발, 목적지 값을 설정
  useEffect(() => {
    if (vehicle.id && paths[vehicle.id]) {
      setStartPoint({ ...paths[vehicle.id].start });
      setGoalPoint({ ...paths[vehicle.id].goal });
      setWaypoint({ ...paths[vehicle.id].waypoint });
      setNavigatingWaypoint({ ...paths[vehicle.id].waypoint });
      if (
        paths[vehicle.id].waypoint?.address &&
        paths[vehicle.id].waypoint?.address !== ""
      ) {
        setWaypointActive(true);
      }
      if (!isNavigating()) {
        addMarkerByKey("start", { ...paths[vehicle.id].start });
        addMarkerByKey("goal", { ...paths[vehicle.id].goal });
        addMarkerByKey("waypoint", { ...paths[vehicle.id].waypoint });
      }
    }
  }, [paths, vehicle.id]);

  useEffect(() => {
    if (waypoint?.address !== "") {
      addMarkerByKey("waypoint", waypoint);
    } else {
      dispatch.location.deleteMarker({ id: "waypoint" });
    }
  }, [waypoint]);

  useEffect(() => {
    dispatch.hotkey.setHotkeys(directionsHotKeys);
    return () => {
      clearPath();
    };
  }, []);

  // hotkeys
  useHotkeys(
    "s",
    async () => {
      const start = document.getElementById("start");
      start.focus();
    },
    {
      scopes: ["directions"],
      keydown: true,
      preventDefault: true,
    },
  );

  useHotkeys("shift+s", getCurrentPosition, {
    scopes: ["directions"],
    keydown: true,
    preventDefault: true,
  });

  useHotkeys(
    "g",
    async () => {
      const goal = document.getElementById("goal");
      goal.focus();
    },
    {
      scopes: ["directions"],
      keydown: true,
      preventDefault: true,
    },
  );

  useHotkeys("shift+g", setGoalToAccedentLocation, {
    scopes: ["directions"],
    keydown: true,
    preventDefault: true,
  });

  useHotkeys(
    "shift+w",
    () => {
      handleAddWaypoint();
      const waypointTextField = document.getElementById(`waypoint`);
      waypointTextField.focus();
    },
    {
      scopes: ["directions"],
      keyup: true,
      preventDefault: true,
    },
  );

  useHotkeys("shift+e", () => handleRemoveWaypoint(), {
    scopes: ["directions"],
    keyup: true,
    preventDefault: true,
  });

  useHotkeys("c", handleSwapPoints, {
    scopes: ["directions"],
    keyup: true,
  });

  useHotkeys("r", handleClearPoints, {
    scopes: ["directions"],
    keyup: true,
  });

  useHotkeys("n", isNavigating() ? handleEndRoute : handleSubmitGetPaths, {
    scopes: ["directions"],
    keyup: true,
    preventDefault: true,
  });

  useHotkeys(
    "alt+s",
    () =>
      !isNavigating() && addGeocodeListener(handleUpdatePointByClick, "start"),
    {
      scopes: ["directions"],
      keyup: true,
    },
  );

  useHotkeys(
    "alt+g",
    () =>
      !isNavigating() && addGeocodeListener(handleUpdatePointByClick, "goal"),
    {
      scopes: ["directions"],
      keyup: true,
    },
  );

  useHotkeys(
    "alt+w",
    () => {
      addGeocodeListener(handleUpdatePointByClick, "waypoint");
    },
    {
      scopes: ["directions"],
      keyup: true,
    },
  );

  useHotkeys(
    "esc",
    () => {
      cancelListener();
    },
    {
      scopes: ["directions"],
      keydown: true,
    },
  );

  return (
    <Box>
      <DirectionDisabledLayer
        visibility={disabledLayer}
        onClose={() => setDisabledLayer(false)}
      />
      <DirectionDisabledLayer visibility={isPathLoading}>
        <CircularProgress className={classes.loading} color="primary" />
      </DirectionDisabledLayer>
      <Box p={4}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.textInputs}>
              <InputLabel className={classes.inputLabels}>
                <Box>출발지</Box>
                {!isNavigating() && (
                  <CustomButton
                    classes={classes.inputLabelsButton}
                    title="현재 위치를 출발지로 설정"
                    onClick={() => getCurrentPosition()}
                  >
                    <MyLocation className="myPositionButton" />
                  </CustomButton>
                )}
              </InputLabel>
              <AddressTextField
                classes={classes}
                id="start"
                point={startPoint}
                setPoint={setStartPoint}
                errors={errors}
                addGeocodeListener={addGeocodeListener}
                handleUpdatePointByClick={handleUpdatePointByClick}
                searchAddress={searchAddress}
                handleChangeAddress={handleChangeAddress}
                endAdornmentIcon={SwapVerticalCircle}
                endAdornment={startEndAdornment}
              />
              {waypointActive && (
                <>
                  <InputLabel className={classes.inputLabels}>
                    <Box>경유지</Box>
                  </InputLabel>
                  <AddressTextField
                    classes={classes}
                    id="waypoint"
                    point={waypoint}
                    setPoint={setWaypoint}
                    errors={errors}
                    addGeocodeListener={addGeocodeListener}
                    handleUpdatePointByClick={handleUpdatePointByClick}
                    searchAddress={searchAddress}
                    handleChangeAddress={handleChangeAddress}
                    endAdornmentIcon={RemoveCircle}
                    endAdornment={waypointEndAdornment}
                    placeholder="최대 경유지 1개"
                  />
                </>
              )}
              <InputLabel className={classes.inputLabels}>
                <Box>목적지</Box>
                {!isNavigating() && (
                  <CustomButton
                    classes={classes.inputLabelsButton}
                    title="재난 지점을 목적지로 설정"
                    onClick={setGoalToAccedentLocation}
                  >
                    <MyLocation className="myPositionButton" />
                  </CustomButton>
                )}
              </InputLabel>
              <AddressTextField
                classes={classes}
                id="goal"
                point={goalPoint}
                setPoint={setGoalPoint}
                errors={errors}
                addGeocodeListener={addGeocodeListener}
                handleUpdatePointByClick={handleUpdatePointByClick}
                searchAddress={searchAddress}
                handleChangeAddress={handleChangeAddress}
                endAdornmentIcon={AddCircle}
                endAdornment={goalEndAdornment}
                waypointActive={waypointActive}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {!isNavigating() ? (
              <Grid container justifyContent="space-between">
                <Button
                  variant="outlined"
                  color="default"
                  onClick={handleClearPoints}
                >
                  다시 입력
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmitGetPaths}
                >
                  길찾기
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={handleEndRoute}
                >
                  경로 안내 종료
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <PathList
        map={map}
        points={{ start: startPoint, goal: goalPoint, waypoint }}
        vehicleNumber={vehicle.id}
      />
    </Box>
  );
}

const useStyles = makeStyles({
  body: {},
  contentRow: {
    paddingTop: "1rem",
  },
  inputLabels: { display: "flex", alignItems: "center" },
  inputLabelsButton: {
    padding: 0,
    paddingLeft: "0.625rem",
    "& .myPositionButton": {
      color: "#F25149",
      paddingTop: 0,
      paddingBottom: 0,
      "&:hover": {
        opacity: 0.7,
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  textInputs: {
    "& .textInput": {
      marginTop: "0.3rem",
      marginBottom: "0.3rem",
      width: "100%",
      "& .mouseButton": {
        color: "gray",
        "&:hover": {
          opacity: 0.7,
        },
      },
      "& .swapButton": {
        top: 20,
        zIndex: 200,
        color: "#3f51b5",
        "&:hover": {
          opacity: 0.7,
        },
      },
      "& .addButton": {
        top: 20,
        zIndex: 200,
        color: "#36B552",
        "&:hover": {
          opacity: 0.7,
        },
      },
      "& .removeButton": {
        top: 20,
        zIndex: 200,
        color: "#B5515D",
        "&:hover": {
          opacity: 0.7,
        },
      },
    },
    // border: "1px solid black",
    position: "relative",
    borderRadius: "2px",
    "& .MuiFilledInput-root": {
      backgroundColor: "white",
    },
  },
  startInputs: {
    paddingLeft: 0,
    paddingRight: "0.5rem",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  loading: {
    top: "45%",
    position: "relative",
  },
});

export default Directions;
