import { instance as apiClient } from "apiClient";

export default {
  state: {},
  reducers: {},
  effects: {
    async downloadInferenceCsv(payload) {
      try {
        const response = await apiClient.get(
          `/api/v1/track/download/inference/${payload.accidentId}`,
          {
            responseType: "blob",
          },
        );
        return response;
      } catch (err) {
        return err.response;
      }
    },
    async downloadVehicleCsv(payload) {
      try {
        const response = await apiClient.get(
          `/api/v1/track/download/vehicle/${payload.accidentId}`,
          {
            responseType: "blob",
          },
        );
        return response;
      } catch (err) {
        return err.response;
      }
    },
    async downloadBlockerCsv(payload) {
      try {
        const response = await apiClient.get(
          `/api/v1/track/download/blocker/${payload.accidentId}`,
          {
            responseType: "blob",
          },
        );
        return response;
      } catch (err) {
        return err.response;
      }
    },
  },
};
