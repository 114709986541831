/* eslint-disable no-use-before-define */
import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  mapRightClickContent,
  markerRigthClickContent,
  markerType,
  geolocationTimeout,
} from "constants";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { Box } from "@material-ui/core";
import { useHotkeys } from "react-hotkeys-hook";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { MyLocation } from "@material-ui/icons";
import RediscoverDialog from "./dialogs/RediscoverDialog";
import BlockerCreateDialog from "./dialogs/BlockerCreateDialog";
import {
  CustomButton,
  NaverMapButtonController,
  Snapshot,
  VehicleController,
} from "components";
import useInterval from "hooks/useInterval";
import { client } from "stores/location";
import {
  addListener,
  checkOverlap,
  clearListeners,
  createMarker,
  createPanel,
  deleteMarker,
  DrawPolyline,
  focusTimer,
  getGeocode,
  getPosition,
  identifyCircle,
  initMap,
  playWarningSound,
  removeIdentifyCircle,
  removeListener,
  removePathList,
  reverseGeocode,
  setCenterEffect,
  isAdmin,
  paintAllVehiclePath,
} from "utils/naverMaps";

import { publish, publishBlocker } from "utils/socket";
import findBlockerType from "utils/blocker";
import { parsedMessage } from "utils";
import { getSnapshotData } from "utils/upload";
import {
  addAnswer,
  createAnswer,
  closeConnection,
  createOffer,
  closeAllPeerConnections,
  addCandidate,
} from "utils/webRTC";
import { isArrived } from "utils/location";

function NaverMap({ width = "100%", height = "400px" }) {
  // naver map ref
  const mapRef = useRef();
  const engineRef = useRef();
  const webcamRef = useRef();
  const blockersRef = useRef();
  const isFocusVehicleRef = useRef();
  const startPointRef = useRef();
  const goalPointRef = useRef();
  const navigate = useNavigate();
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const { vehicle, isDepart } = useSelector(state => state.vehicle);
  const { accident } = useSelector(state => state.accident);
  const { engine } = useSelector(state => state.engine);
  const { uploadedVideoCnt } = useSelector(state => state.video);
  const { startPoint, goalPoint } = useSelector(state => state.path);
  const { map, markers, scriptLoaded, sidebarExpanded, focusId } = useSelector(
    state => state.location,
  );
  const { paths } = useSelector(state => state.path);

  // 경로 재탐색 다이얼로그
  const [open, setOpen] = useState(false);

  const [blockerDialogOpen, setBlockerDialogOpen] = useState(false);
  const [interruptedSection, setInterruptedSection] = useState({
    sectionStart: null,
    sectionEnd: null,
  });
  const [interruptedSnackbarId, setInterruptedSanckbarId] = useState(null);
  const dispatch = useDispatch();
  const { clearPath, clearPathByNumber } = dispatch.path;
  const classes = useStyles();

  // subscribe accidents
  const subscribe = accidentId => {
    client.subscribe(
      `/exchange/sharing.exchange/accident.${accidentId}`,
      async body => {
        const message = parsedMessage(body);
        if (message.type === "location") {
          updateUserLocation(message);
        } else if (message.type === "leave") {
          deleteUserLocation(message);
          closeConnection(vehicle.id, message.sender);
        } else if (
          message.type === "join" &&
          dispatch.controller.getVideoVisible()
        ) {
          createOffer(message.sender, vehicle.id, accidentId);
        } else if (message.type === `closeConnectionfor${vehicle.id}`) {
          closeConnection(
            vehicle.id,
            message.sender,
            JSON.parse(message.contents),
          );
        } else if (message.type === "blockerCreated") {
          updateBlocker(message);
        } else if (message.type === "blockerUpdated") {
          updateBlockerData(message);
        } else if (message.type === "blockerDeleted") {
          const blockerId = message.sender;
          const marker = dispatch.location.getMarkerById({ id: blockerId });
          dispatch.location.deleteMarker({ id: blockerId });
          deleteMarker(marker);
          dispatch.blocker.getBlockers({ accidentId });
        } else if (message.type === "pathStart") {
          drawPath(message);
        } else if (message.type === "pathEnd") {
          removePathList(
            dispatch.path.getPathById({ id: message.sender })?.paths.polylines,
          );
          dispatch.path.removePaths({ id: message.sender });
        } else if (
          message.type === "webrtcInvitation" &&
          vehicle.id !== message.sender
        ) {
          createOffer(message.sender, vehicle.id, accidentId);
        } else if (message.type === `offerfor${vehicle.id}`) {
          createAnswer(
            message.contents,
            message.sender,
            vehicle.id,
            accident.id,
          );
        } else if (message.type === `answerfor${vehicle.id}`) {
          addAnswer(vehicle.id, message.contents, message.sender);
        } else if (message.type === `candidatefor${vehicle.id}`) {
          addCandidate(vehicle.id, message.contents, message.sender);
        }
      },
      // insert subscribe id
      { id: `${accidentId}-${vehicle.id}` },
    );
  };

  const drawVehiclesPath = (userId, vehiclePathData) => {
    if (userId === vehicle.id) {
      return [
        DrawPolyline(vehiclePathData.path, map, {
          strokeOpacity: 1,
          zIndex: 3,
          strokeColor: "#007aff",
        }),
      ];
    }
    if (dispatch.path.getPathView()) {
      let color = "gray";
      if (userId === focusId) {
        color = "#007aff";
      }
      return [
        DrawPolyline(vehiclePathData.path, map, {
          zIndex: 1,
          strokeColor: color,
        }),
      ];
    }
    return undefined;
  };

  const drawPath = async message => {
    const userId = message.sender;
    const tempVehicle = await dispatch.vehicle.getTempVehicle({
      vehicleNumber: userId,
    });
    if (!tempVehicle.path) {
      return;
    }
    const vehiclePath = dispatch.path.getPathById({ id: userId });
    const vehiclePathData = JSON.parse(tempVehicle.path);

    if (userId !== vehicle.id) {
      // update others' path only path is updated
      if (
        JSON.stringify(vehiclePath?.paths?.pathData?.path) !==
        JSON.stringify(vehiclePathData?.path)
      ) {
        // if vehicle's path is changed, then remove origin path
        removePathList(
          dispatch.path.getPathById({ id: message.sender })?.paths.polylines,
        );
        dispatch.path.removePaths({ id: message.sender });
      }
    }

    if (!vehiclePath) {
      dispatch.path.addPaths({
        id: userId,
        paths: {
          pathData: { path: vehiclePathData.path },
          polylines: drawVehiclesPath(userId, vehiclePathData),
        },
        start: vehiclePathData.start,
        goal: vehiclePathData.goal,
        waypoint: vehiclePathData.waypoint,
      });
    } else if (
      !vehiclePath.paths?.polylines ||
      JSON.stringify(vehiclePath?.paths?.pathData?.path) !==
        JSON.stringify(vehiclePathData?.path)
    ) {
      clearPath();
      clearPathByNumber({ vehicleNumber: userId });
      dispatch.path.addPaths({
        ...vehiclePath,
        paths: {
          ...vehiclePath.paths,
          pathData: { path: vehiclePathData.path },
          polylines: drawVehiclesPath(userId, vehiclePathData),
        },
      });
    }
  };

  const deleteUserLocation = message => {
    dispatch.location.deleteMarker({ id: message.sender });
    dispatch.location.removeMarker({ id: message.sender });
    if (dispatch.path.getPathById({ id: message.sender })) {
      removePathList(
        dispatch.path.getPathById({ id: message.sender }).paths.polylines,
      );
      dispatch.path.removePaths({ id: message.sender });
    }
    removeIdentifyCircle();
  };

  const updateVehicleRoadInfo = data => {
    dispatch.location.setVehicleLocation({
      speed: data.speed,
      heading: data.heading,
      latitude: data.latitude,
      longitude: data.longitude,
    });
  };

  const updateUserLocation = message => {
    const userId = message.sender;
    const location = message.contents;
    const { vehicleType } = location;
    const marker = dispatch.location.getMarkerById({ id: userId });
    const { heading, speed } = location;
    // if not exists user marker then..
    if (!marker) {
      dispatch.location.addMarker({
        id: userId,
        marker: createMarker({
          position: getPosition(location.latitude, location.longitude),
          map: mapRef.current,
          markerType: markerType.vehicle,
          icon: vehicleType,
          callback: () => {
            navigate(`/participant/detail/${userId}`);
            dispatch.location.setSidebarExpanded(true);
            dispatch.location.setFocusId({ id: userId });
          },
          options: {
            title: userId,
          },
        }),
      });
      return;
    }
    drawPath(message);
    marker.setPosition(getPosition(location.latitude, location.longitude));
    // rotate vehicle icon by direction
    const iconParent = document.querySelector(`div[title = "${userId}"]`);
    const icon = iconParent?.firstChild;
    if (icon) {
      const vehicleIcon = icon.querySelector("img");
      if (heading) {
        vehicleIcon.style.rotate = `${heading - 90}deg`;
        vehicleIcon.style.transform = `scaleY(${heading <= 180 ? 1 : -1})`;
      }
      const speedDiv = icon.querySelector("div");
      if (speed !== null) {
        speedDiv.innerText = `${userId} : ${Number(speed).toFixed(0)}km/h`;
      } else {
        speedDiv.innerText = `${userId}`;
      }
    }

    // set map center to vehicle's current position
    if (isFocusVehicleRef.current && userId === vehicle.id) {
      setCenterEffect(mapRef.current, marker);
    }
    // draw identify circle
    if (userId === vehicle.id) {
      identifyCircle(mapRef.current, marker);
      updateVehicleRoadInfo(location);
    }
    // check vehicle is arrived
    if (!vehicle.arrivedAt && isArrived(map, location, accident.location)) {
      dispatch.vehicle.updateVehicle({
        ...vehicle,
        arrivedAt: new Date().getTime(),
      });
    }
  };

  const updateBlocker = async message => {
    await dispatch.blocker.getBlockers({ accidentId: message.topicId });
    const blockerId = message.sender;
    const blocker = await dispatch.blocker.getBlocker({
      accidentId: message.topicId,
      blockerId,
    });
    let marker = dispatch.location.getMarkerById({ id: blockerId });
    if (!marker) {
      dispatch.location.addMarker({
        id: blockerId,
        marker: createMarker({
          position: getPosition(
            message.contents.latitude,
            message.contents.longitude,
          ),
          map,
          markerType: markerType.blocker,
          icon: blocker?.category,
          callback: () => {
            navigator({
              pathname: `/blocker`,
              search: `?accidentId=${blocker.accident.id}&blockerId=${blocker.id}`,
            });
          },
          rightClickContent: markerRigthClickContent,
          callbackRightClick: async value => {
            if (value === "delete") {
              await dispatch.blocker.deleteBlocker({
                accidentId: accident.id,
                blocker,
                vehicleId: vehicle.id,
              });
              publishBlocker(accident.id, blocker.id, "blockerDeleted");
            }
          },
          options: {
            title: findBlockerType(blocker?.category),
          },
        }),
      });
    }
    marker = dispatch.location.getMarkerById({ id: blockerId });
    const overlap = checkOverlap(
      dispatch.path.getPathById({
        id: dispatch.vehicle.getMyVehicleNumber(),
      })?.paths.pathData.path,
      marker,
    );

    // 경로상에 블록커 존재하는지 검사
    if (overlap) {
      setInterruptedSection(overlap);
      const snackbarId = enqueueSnackbar(
        `현재 경로에 장애 구역이 추가 됐습니다.
         클릭하면 경로 재탐색을 진행합니다.`,
        {
          variant: "warning",
          // autoHideDuration: "30000",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          persist: true,
          onClick: () => {
            dispatch.location.deleteFocusId();
            setCenterEffect(mapRef.current, marker);
            // 경로 재탐색 다이얼로그 열기
            setOpen(true);
            closeSnackbar();
          },
        },
      );
      playWarningSound();
      setInterruptedSanckbarId(snackbarId);
      // 경로 재탐색
    } else {
      enqueueSnackbar("장애 구역이 추가 됐습니다.", {
        variant: "info",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        onClick: () => {
          dispatch.location.deleteFocusId();
          setCenterEffect(mapRef.current, marker);
        },
      });
    }
  };

  const updateBlockerData = async message => {
    const blockerId = message.sender;
    const blocker = await dispatch.blocker.getBlocker({
      accidentId: message.topicId,
      blockerId,
    });
    const marker = dispatch.location.getMarkerById({ id: blockerId });
    const icon = blocker.category;
    const iconSize = 50;
    marker.setIcon({
      content:
        `<img src="/assets/marker/${icon}.png" alt="" ` +
        `style="margin:0px;padding:0px;border:0px solid transparent;display:block;max-width:none;max-height:none; ` +
        `-webkit-user-select: none; position: absolute; width: ${iconSize}px; height:${iconSize}px; left: ${
          -iconSize / 2
        }px; top: ${-iconSize / 2}px;">`,
    });
  };

  const getJoinRequestBody = accidentId => {
    return JSON.stringify({
      topicId: accidentId,
      sender: vehicle.id,
      createdAt: Date.now(),
      type: "join",
      contents: "",
    });
  };

  const handleVehicleDepart = async () => {
    await dispatch.vehicle.getVehicle({ vehicleId: vehicle.id });
    if (!client.connected) {
      client.activate();
      client.onStompError = () => {
        enqueueSnackbar("연결이 불안정합니다.", {
          variant: "warning",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      };
      client.onWebSocketError = () => {
        enqueueSnackbar("연결이 불안정합니다.", {
          variant: "warning",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      };
    }
    client.onConnect = () => {
      handleJoinAccident(accident.id);
    };
  };

  const handleJoinAccident = async accidentId => {
    await closeAllPeerConnections();
    publish(getJoinRequestBody(accidentId), "/pub/message");
    subscribe(accidentId);
  };

  const handleExitAccident = async () => {
    // deleteUserLocation({sender: vehicle.id})
    publish(
      JSON.stringify({
        topicId: accident.id,
        sender: vehicle.id,
        createdAt: Date.now(),
        type: "leave",
        contents: "",
      }),
    );
    deleteUserLocation({ sender: vehicle.id });
    client.unsubscribe(`${accident.id}-${vehicle.id}`);
    client.deactivate();
    window.location.reload();
  };

  const createBlocker = (value, pos) => {
    reverseGeocode(pos.y, pos.x, async response => {
      const { address } = response.result.items[0];
      const blocker = {
        id: undefined,
        category: value,
        accident,
        reason: "",
        location: { address, latitude: pos.y, longitude: pos.x },
      };
      const { data: createdBlocker } = await dispatch.blocker.createBlocker({
        ...blocker,
        accidentId: accident.id,
        vehicleNumber: vehicle.id,
      });
      await uploadBlockerImage(createdBlocker);

      publishBlocker(accident.id, createdBlocker.id, "blockerCreated");
    });
  };

  const createBlockerByHotKey = () => {
    setBlockerDialogOpen(true);
  };
  const uploadBlockerImage = async blocker => {
    const snapshotData = getSnapshotData({
      camRef: webcamRef,
      canvasId: "snapshot",
    });
    await engineRef?.current.getCurrentImage({
      panoEleId: "blocker-pano",
      accidentId: accident.id,
      blockerId: blocker.id,
      position: blocker.location,
      callback: dispatch.image.uploadBlockerImage,
      data: snapshotData,
      id: `${accident.id}/blockers/${blocker.id}`,
      urlPath: `blockers/${accident.id}/${blocker.id}/image`,
    });
  };

  const resizeMap = () => {
    let size;
    const offset = document.getElementById("foldPanel").offsetWidth;
    if (sidebarExpanded) {
      size = new window.naver.maps.Size(
        window.innerWidth - offset,
        window.innerHeight,
      );
    } else {
      size = new window.naver.maps.Size(window.innerWidth, window.innerHeight);
    }
    if (map) {
      map.setSize(size);
    }
  };

  const focusOnMyVehicle = useMemo(
    () =>
      debounce(() => dispatch.location.setFocusId({ id: vehicle.id }), 5000),
    [vehicle.id],
  );

  const searchPositionFor = location => {
    getGeocode(location, "", (key, newLocation) => {
      const newAccident = { ...accident };
      newAccident.location = newLocation;
      dispatch.accident.updateAccident(newAccident);
    });
  };

  const handleClickMyLocation = () => {
    const myVehicleId = localStorage.getItem("myVehicleId");
    dispatch.location.setFocusId(myVehicleId);
    const marker = dispatch.location.getMarkerById({ id: myVehicleId });
    map.setZoom(17);
    setCenterEffect(map, marker);
  };

  const resetTimer = timer => {
    isFocusVehicleRef.current = false;
    return focusTimer(timer, isFocusVehicleRef);
  };

  useEffect(() => {
    if (vehicle.id) {
      let timer = null;
      const autoFocusHandler = e => {
        timer = resetTimer(timer);
      };
      document.addEventListener("touchmove", autoFocusHandler);
      document.addEventListener("mousemove", autoFocusHandler);
      document.addEventListener("scroll", autoFocusHandler);
      return () => {
        document.removeEventListener("touchmove", autoFocusHandler);
        document.removeEventListener("mousemove", autoFocusHandler);
        document.removeEventListener("scroll", autoFocusHandler);
      };
    }
  }, [vehicle.id]);

  useEffect(() => {
    const handleMouseDown = () => {
      focusOnMyVehicle.cancel();
      dispatch.location.deleteFocusId();
    };

    const handleMouseUp = () => {
      focusOnMyVehicle();
    };

    const handleKeyDown = event => {
      if (event.code === "KeyG") {
        dispatch.location.deleteFocusId();
      }
    };

    const handleKeyUp = event => {
      if (event.code === "KeyG") {
        focusOnMyVehicle();
      }
    };

    // const handleScroll = () => {
    //   // dispatch.location.deleteFocusId();
    //   focusOnMyVehicle();
    // };
    const mapComponent = document.getElementById("map");

    // mapComponent.addEventListener("mousedown", handleMouseDown);
    // document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    // const scrollListener = addListener(
    //   mapRef.current,
    //   "zoom_changed",
    //   handleScroll,
    // );
    return () => {
      // mapComponent.removeEventListener("mousedown", handleMouseDown);
      // document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      // removeListener(scrollListener);
    };
  }, [vehicle.id]);

  useEffect(() => {
    paintAllVehiclePath(paths, focusId, vehicle);
  }, [focusId]);

  useEffect(() => {
    if (accident && vehicle.id && isDepart) {
      handleVehicleDepart();
    } else if (
      accident &&
      vehicle.id &&
      !isDepart &&
      client.connected &&
      uploadedVideoCnt === 2
    ) {
      handleExitAccident();
    }
  }, [accident?.id, vehicle?.id, isDepart, uploadedVideoCnt]);

  useEffect(() => {
    let accidentMarker = null;
    if (scriptLoaded && accident) {
      if (
        accident.location.latitude === null ||
        accident.location.longitude === null
      ) {
        searchPositionFor(accident.location);
      }
      accidentMarker = createMarker({
        position: getPosition(
          accident.location.latitude,
          accident.location.longitude,
        ),
        map: mapRef.current,
        markerType: markerType.accident,
        icon: accident.category,
        options: {
          title: "재난 지점",
        },
      });
      dispatch.location.addMarker({ id: "accident", marker: accidentMarker });
    }
    return () => {
      if (accidentMarker) {
        clearListeners(accidentMarker);
      }
    };
  }, [scriptLoaded, accident]);

  useEffect(() => {
    let timer;
    const rightClickListener = addListener(mapRef.current, "rightclick", e => {
      createPanel(
        mapRightClickContent,
        mapRef.current,
        getPosition(e.coord.y, e.coord.x),
        createBlocker,
      );
    });

    const handleTouchStart = e => {
      if (e.domEvent.touches.length === 1 && !timer) {
        timer = setTimeout(() => {
          createPanel(
            mapRightClickContent,
            mapRef.current,
            getPosition(e.coord.y, e.coord.x),
            createBlocker,
          );
        }, 1000);
      }
    };

    const handleTouchEnd = e => {
      if (timer) {
        clearTimeout(timer);
        timer = undefined;
      }
    };

    const touchStartListener = addListener(
      mapRef.current,
      "touchstart",
      handleTouchStart,
    );

    const touchMoveListener = addListener(
      mapRef.current,
      "touchmove",
      handleTouchEnd,
    );

    const touchEndListener = addListener(
      mapRef.current,
      "touchend",
      handleTouchEnd,
    );

    return () => {
      removeListener(rightClickListener);
      removeListener(touchStartListener);
      removeListener(touchMoveListener);
      removeListener(touchEndListener);
    };
  }, [accident]);

  useEffect(() => {
    if (map) {
      window.addEventListener("resize", resizeMap);
      return () => {
        window.removeEventListener("resize", resizeMap);
      };
    }
    return () => {};
  }, [map]);

  useEffect(() => {
    resizeMap();
  }, [map, sidebarExpanded]);

  useEffect(async () => {
    if (!map && markers?.accident?.position) {
      const naverMap = await initMap({
        map: mapRef.current,
        accidentLocation: markers.accident.position,
      });
      mapRef.current = naverMap;
      dispatch.location.setMap(naverMap);
      dispatch.location.setScriptLoaded(true);
      return () => {
        mapRef.current = null;
      };
    }
  }, [map, markers.accident]);

  useEffect(() => {
    engineRef.current = engine;
    return () => {};
  }, [engine]);

  // useEffect(() => {
  //   startPointRef.current = startPoint;
  //   goalPointRef.current = goalPoint;
  //   blockersRef.current = blockers;
  //   return () => {};
  // }, [startPoint, goalPoint, blockers]);

  useInterval(async () => {
    // send location info every 1 sec.
    if (isDepart && accident && vehicle.id && !isAdmin(vehicle.id)) {
      engine.notifyCurrentPosition(
        device => {
          dispatch.engine.setDevice(device);
        },
        err => console.log(err),
      );
    }
  }, [geolocationTimeout]);
  // hotkeys
  useHotkeys("space", () => createBlockerByHotKey());

  useHotkeys("shift+r", handleClickMyLocation, {
    scopes: ["naverMap"],
    keyup: true,
    preventDefault: true,
  });

  return (
    <>
      <Box
        ref={mapRef}
        className={classes.map}
        id="map"
        style={{ width, height }}
      >
        {scriptLoaded && (
          <NaverMapButtonController createBlocker={createBlocker} />
        )}
      </Box>
      <Snapshot
        accident={accident}
        engine={engineRef}
        markers={markers}
        vehicle={vehicle}
        webcam={webcamRef}
      />
      <BlockerCreateDialog
        open={blockerDialogOpen}
        setOpen={setBlockerDialogOpen}
        markers={markers}
        vehicle={vehicle}
        createBlocker={createBlocker}
      />
      <RediscoverDialog
        interruptedSnackbarId={interruptedSnackbarId}
        interruptedSection={interruptedSection}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

const useStyles = makeStyles({
  map: {
    display: "flex",
  },
});

export default NaverMap;
