import { instance as apiClient } from "apiClient";

const initialState = {
  inferenceResult: {},
  blockerPossibility: 0,
  inferenceMyResult: [],
};

export default {
  state: initialState,
  reducers: {
    setInferenceResult(state, payload) {
      state.inferenceResult = payload;
    },
    setBlockerPossibility(state, payload) {
      state.blockerPossibility = payload;
    },
    setInferenceMyResult(state, payload) {
      state.inferenceMyResult = payload;
    },
  },
  effects: {
    async inferenceRoadImg(payload) {
      try {
        // const img = Buffer.from(payload).toString("base64");
        const body = {
          image: payload.image,
          accident: payload.vehicle.accident,
          vehicleId: payload.vehicle.id,
          speed: payload.speed,
          latitude: payload.latitude,
          longitude: payload.longitude,
          heading: payload.heading,
          inferenceAt: new Date().getTime(),
        };
        const res = await apiClient.post(`/api/v1/inference`, body);
        return res.data;
      } catch (err) {
        return err;
      }
    },
  },
};
