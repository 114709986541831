const initialState = {
  vehicleButtonVisible: true,
  videoVisible: false,
};

export default {
  state: initialState,
  reducers: {
    setVehicleButtonVisible(state, payload) {
      state.vehicleButtonVisible = payload;
    },
    setVideoVisible(state, device) {
      state.videoVisible = device;
    },
  },
  effects: dispatch => ({
    getVideoVisible(_, state) {
      return state.controller.videoVisible;
    },
  }),
};
