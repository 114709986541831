import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";

export default function AlertDialog({
  open,
  onAgree,
  onClose,
  title,
  description,
}) {
  const classes = useStyles();
  const handleClickAgree = e => {
    e.preventDefault();
    onAgree();
    onClose();
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box className={classes.container}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={handleClickAgree}
            variant="outlined"
            color="secondary"
            autoFocus
          >
            확인
          </Button>
          <Button onClick={onClose} variant="outlined" color="default">
            취소
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
const useStyles = makeStyles({
  container: {
    margin: "1rem",
  },
  actions: {
    paddingTop: "1rem",
    justifyContent: "center",
  },
});
