import {
  noop,
  accidentCategoryList,
  blockerTypeList,
  vehicleTypeList,
  markerType as mType,
  mapConfig,
  geolocationConfig,
} from "constants";
import { koreanToData } from "./dataSetting";

/* eslint-disable no-use-before-define */
export const getInitPositionWithCurrentPosition = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => resolve(position),
      err => reject(err),
      geolocationConfig,
    );
  });
};

/**
 * 네이버 맵 생성
 * @param {{ map: any; options?: { center: any; zoom: number; zoomControl: boolean; zoomControlOptions: { position: any; }; }; }} {
  map,
  options = {
    center: new window.naver.maps.LatLng(goal.lat, goal.lng),
    zoom: 17,
    zoomControl: true,
    zoomControlOptions: {
      position: window.naver.maps.Position.RIGHT_CENTER,
    },
  },
}
 * @returns {window.naver.maps.Map}
 */
export async function initMap({
  map,
  accidentLocation,
  options = {
    // center: new window.naver.maps.LatLng(goal.lat, goal.lng),
    ...mapConfig,
    zoomControlOptions: {
      position: window.naver.maps.Position.RIGHT_CENTER,
    },
  },
}) {
  try {
    const center = await getInitPositionWithCurrentPosition();
    options.center = new window.naver.maps.LatLng(
      center.coords.latitude,
      center.coords.longitude,
    );
    return new window.naver.maps.Map(map, options);
  } catch (err) {
    console.log(err);
    if (err.code === 3) {
      // timeout occurred
      options.center = accidentLocation;
      return new window.naver.maps.Map(map, options);
    }
  }
}

/**
 * 네이버 지도 위치 생성
 *
 * @param {Number} lat
 * @param {Number} lng
 * @returns {window.naver.map.LatLng}
 */
export function getPosition(lat, lng) {
  return new window.naver.maps.LatLng(lat, lng);
}

/**
 * 네이버 지도 마커 생성
 *
 * @param {{ position: any; map: any; icon?: string; iconSize?: number; }} { position, map, icon = false, iconSize = 50 }
 * @returns {window.naver.maps.Marker}
 */
export function createMarker({
  position,
  map,
  markerType,
  icon = false,
  iconSize = 50,
  options = {},
  callback = () => {},
  rightClickContent = "",
  callbackRightClick = () => {},
  focused = false,
}) {
  const iconType = iconTypeFor(markerType, icon);
  const speedContent =
    markerType === "vehicle"
      ? `<div style="position: absolute; top: ${
          iconSize / 2
        }px; left: 100%; transform: translateX(-50%); white-space: nowrap;">0</div>`
      : "";
  const marker = new window.naver.maps.Marker({
    position,
    map,
    ...(icon && {
      icon: {
        content:
          `<img src="/assets/marker/${iconType}.png" alt="" ` +
          `style="margin:0px;padding:0px;border:0px solid transparent;display:block;max-width:none;max-height:none; ` +
          `-webkit-user-select: none; position: absolute; width: ${iconSize}px; height:${iconSize}px; left: ${
            -iconSize / 2
          }px; top: ${-iconSize / 2}px;">
          ${speedContent}`,
      },
    }),
    ...options,
  });

  addListener(marker, "click", () => {
    setCenterEffect(map, marker);
    callback();
  });

  if (rightClickContent) {
    addListener(marker, "rightclick", () => {
      createPanel(rightClickContent, map, position, value => {
        callbackRightClick(value);
      });
    });
  }

  // focus가 true 일 경우만 생성시 center focus 적용
  if (focused) {
    setCenterEffect(map, marker);
  }
  return marker;
}

/**
 *
 * @param {object} markerType 마커 타입
 * @param {string} icon 아이콘 이름
 * @returns {string}
 */
export function iconTypeFor(markerType, icon) {
  let iconType = icon;
  switch (markerType) {
    case mType.vehicle:
      iconType = koreanToData(icon, vehicleTypeList);
      if (!iconType) {
        iconType = "firetruck";
      }
      break;
    case mType.accident:
      iconType = "extra";
      // iconType = koreanToData(icon, accidentCategoryList);
      // if (!iconType) {
      //   iconType = "extra";
      // }
      break;
    case mType.blocker:
      iconType = koreanToData(icon, blockerTypeList);
      if (!iconType) {
        iconType = "etc";
      }
      break;
    default:
      break;
  }
  return iconType;
}

export function deleteMarker(marker) {
  clearListeners(marker);
  marker.setMap(null);
}

/**
 * 네이버 맵 이벤트 리스너 추가
 *
 * @param {object} target 맵 객체
 * @param {string} eventName 이벤트 이름
 * @param {() => void} [listener=() => {}] 리스너 함수
 * @returns {window.naver.maps.Event.addListener}
 */
export function addListener(
  target,
  eventName,
  listener = () => {},
  listenerOptions = [],
) {
  return window.naver.maps.Event.addListener(target, eventName, event =>
    listener(event, ...listenerOptions),
  );
}

/**
 * 네이버 맵 이벤트 리스너 제거
 *
 * @param {object || array} listeners 리스너 목록 or 리스너
 * @returns {window.naver.maps.Event.removeListener}
 */
export function removeListener(listeners) {
  return window.naver.maps.Event.removeListener(listeners);
}

/**
 * 네이버 맵 객체의 리스너 제거
 *
 * @param {object} object
 * @returns {window.naver.maps.Event.clearListener}
 */
export function clearListeners(object) {
  return window.naver.maps.Event.clearListeners(object);
}

/**
 * 네이버 맵 객체 listener 한번만 실행
 *
 * @param {object} target 이벤트 등록할 객체
 * @param {string} eventName 이빈트 이름
 * @param {object} listener 리스너
 * @returns {window.naver.maps.Event.once}
 */
export function once(target, eventName, listener) {
  return window.naver.maps.Event.once(target, eventName, listener);
}

export function getGeocode(
  location,
  stateKey = "",
  callback = noop,
  errorCallback = noop,
) {
  function checkGeoCode() {
    if (window.naver?.maps?.Service?.geocode) {
      window.naver.maps.Service.geocode(
        {
          address: location?.address,
        },
        (status, response) => {
          if (
            status === window.naver.maps.Service.Status.OK &&
            response.result.items.length > 0
          ) {
            const { point } = response.result.items[0];

            const newLocation = {
              address: location.address,
              latitude: point.y || location.latitude,
              longitude: point.x || location.longitude,
              verified: true,
            };
            callback(stateKey, newLocation);
          } else {
            // not found geocode
            errorCallback();
          }
        },
      );
    } else {
      setTimeout(checkGeoCode, 100);
    }
  }
  checkGeoCode();
}

export function reverseGeocode(
  latitude,
  longitude,
  callback = () => {},
  callbackOptions = [],
) {
  function checkReverseGeoCode() {
    if (window.naver?.maps?.Service?.reverseGeocode) {
      window.naver.maps.Service.reverseGeocode(
        {
          location: getPosition(latitude, longitude),
        },
        (status, response) => {
          if (status !== window.naver.maps.Service.Status.OK) {
            return;
          }
          if (response.result.items.length > 0) {
            callback({ ...response, latitude, longitude }, callbackOptions);
          }
        },
      );
    } else {
      setTimeout(checkReverseGeoCode, 100);
    }
  }
  checkReverseGeoCode();
}

/**
 * Description placeholder
 *
 * @param {String} path
 * @param {window.naver.map} map
 * @param {Object} options https://navermaps.github.io/maps.js.ncp/docs/naver.maps.Polyline.html#toc25__anchor
 * @returns {window.naver,maps.Polyline}
 */
export function DrawPolyline(
  path,
  map,
  polylineOptions = {},
  borderlineOptions = polylineOptions,
) {
  return {
    borderline: new window.naver.maps.Polyline({
      map,
      path,
      strokeWeight: 10,
      strokeStyle: "solid",
      strokeColor: "black",
      strokeLineCap: "round",
      strokeLineJoin: "round",
      strokeOpacity: 0.2,
      ...polylineOptions,
    }),
    polyline: new window.naver.maps.Polyline({
      map,
      path,
      strokeWeight: 8,
      strokeStyle: "solid",
      strokeColor: "#007aff",
      strokeLineCap: "round",
      strokeLineJoin: "round",
      strokeOpacity: 0.2,
      ...borderlineOptions,
    }),
  };
}

// 경로를 그릴때 전체 경로에서 guide 별로 구간을 구분하고,
// guide의 시간, 거리 정보를 이용하여 평균속도를 구하고 속도에 맞게 구간을 색상 정보로 시각화 합니다.
// return 값은 각 guide polyline의 리스트 입니다.
export function DrawPolylineList(
  path,
  map,
  polylineOptions = {},
  borderlineOptions = polylineOptions,
) {
  const totalPath = path.path;
  const guides = path.guide;
  const polylines = guides.map((guide, index) => {
    const strokeColor = getColor(guide);
    let sectionPath;
    // index 가 마지막 guide 인경우
    if (index === guides.length - 1) {
      if (index === 0) {
        sectionPath = totalPath;
      } else {
        sectionPath = totalPath.slice(guide.pointIndex, totalPath.length);
      }
    } else if (index === 0) {
      // guide index 0 일 경우 다음 guide의 시작 포인트 이전까지 sectionPath 에 대입
      sectionPath = totalPath.slice(0, guides[1].pointIndex + 1);
    } else {
      sectionPath = totalPath.slice(
        guide.pointIndex,
        guides[index + 1].pointIndex + 1,
      );
    }

    const result = {
      borderline: new window.naver.maps.Polyline({
        map,
        path: sectionPath,
        strokeWeight: 10,
        strokeStyle: "solid",
        strokeColor: "black",
        strokeLineCap: "round",
        strokeLineJoin: "round",
        zIndex: 1,
        ...borderlineOptions,
      }),
      polyline: new window.naver.maps.Polyline({
        map,
        path: sectionPath,
        strokeWeight: 8,
        strokeStyle: "solid",
        strokeColor,
        strokeLineCap: "round",
        strokeLineJoin: "round",
        zIndex: 2,
        ...polylineOptions,
      }),

      color: strokeColor,
    };
    return result;
  });
  return polylines;
}

export function focusPath(polylines, map) {
  const bounds = new window.naver.maps.LatLngBounds();
  if (!(polylines.length > 0)) {
    return null;
  }
  polylines.forEach(item => {
    const path = item.polyline.getPath();
    path.forEach(latlng => {
      bounds.extend(latlng);
    });
  });

  map.panToBounds(bounds, { duration: 500 });
  return polylines;
}

function getColor(guide) {
  const velocity = guide.distance / 1000 / (guide.duration / 3600000);
  if (velocity > 40) return "green";
  if (velocity > 20) return "yellow";
  return "red";
}

export function getDistance(distance) {
  if (distance < 1000) {
    return `${distance}m`;
  }
  return `${parseFloat(distance / 1000).toFixed(1)}km`;
}

export function getTime(duration) {
  const second = parseInt(duration / 1000, 10);
  if (second > 3600) {
    return `${parseInt(second / 3600, 10)}시간 ${parseInt(
      (second % 3600) / 60,
      10,
    )}분`;
  }
  return `${parseInt((second % 3600) / 60, 10)}분`;
}

export function getArrivalTime(duration) {
  const currentTime = new Date();
  const arrivalTime = new Date(currentTime.getTime() + duration);
  const hours = String(arrivalTime.getHours()).padStart(2, "0");
  const minutes = String(arrivalTime.getMinutes()).padStart(2, "0");

  return `${hours}:${minutes}`;
}

export function getLatLng(data) {
  const tm128 = new window.naver.maps.Point(data.mapx, data.mapy);
  const latLng = window.naver.maps.TransCoord.fromTM128ToLatLng(tm128);
  return latLng;
}

export function createInfoWindowContent(number, duration) {
  return (
    `<div style="color: white; text-align: center; padding: 10px;` +
    `display: flex; flow-dirction: row; justify-content: center;">` +
    `<span style="width: 10px; height: 10px; background-color: white;` +
    `color: #007AFF; border-radius: 50%; padding: 5px; margin-right: 10px;` +
    `display: flex; justify-content: center; align-items: center;">` +
    `${number}</span>${getTime(duration)}</div>`
  );
}

// 경로를 선택할 때, 선택된 경로와 소요 시간을 정보창으로 보여줍니다.
export function getInfoWindow(path, map, number, info) {
  const pos = path.path[parseInt(path.path.length / 2, 10)];
  const centerPosition = getPosition(pos[1], pos[0]);
  const infoWindow = new window.naver.maps.InfoWindow({
    content: createInfoWindowContent(number, path.summary.duration),
    position: centerPosition,
    backgroundColor: "#007AFF",
    anchorColor: "#007AFF",
    borderColor: "#007AFF",
  });
  if (info) {
    infoWindow.open(map);
  }
  return infoWindow;
}

export function removePathList(polylines) {
  if (polylines) {
    polylines.forEach(item => {
      item.polyline.setMap(null);
      item.borderline.setMap(null);
    });
  }
}

// unused function
export function DrawingManager({
  map,
  polygonOptions = {
    fillColor: "#ffc300",
    fillOpacity: 0.5,
    strokeWeight: 3,
    strokeColor: "red",
    draggable: true,
  },
}) {
  return new window.naver.maps.drawing.DrawingManager({ map, polygonOptions });
}

function getDestinationLatLong(latitude, longitude, bearing, distanceCounts) {
  const R = 6371; // 지구 반지름
  const d = 0.071 * distanceCounts; // 거리 50m
  const brng = bearing * (Math.PI / 180); // 방향을 라디안으로 변환
  const lat1 = latitude * (Math.PI / 180);
  const lon1 = longitude * (Math.PI / 180);

  const lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(d / R) +
      Math.cos(lat1) * Math.sin(d / R) * Math.cos(brng),
  );

  const lon2 =
    lon1 +
    Math.atan2(
      Math.sin(brng) * Math.sin(d / R) * Math.cos(lat1),
      Math.cos(d / R) - Math.sin(lat1) * Math.sin(lat2),
    );

  return {
    latitude: lat2 * (180 / Math.PI),
    longitude: lon2 * (180 / Math.PI),
  };
}

function getAngle(p1, p2) {
  const dx = p2[0] - p1[0];
  const dy = p2[1] - p1[1];
  const rad = Math.atan2(dy, dx);
  const angle = rad * (180 / Math.PI);
  return angle;
}

export function checkOverlap(path, marker) {
  if (!path || !marker) return false;
  const markerPosition = [marker.position.x, marker.position.y];
  let minVal = Number.MAX_SAFE_INTEGER;
  for (let i = 0; i < path.length - 1; i += 1) {
    const sectionStart = path[i];
    const sectionEnd = path[i + 1];

    const distanceToSegment = distanceFromPointToLine(markerPosition, [
      sectionStart,
      sectionEnd,
    ]);
    minVal = Math.min(minVal, distanceToSegment);
    if (distanceToSegment < 0.0001) {
      return { sectionStart, sectionEnd };
    }
  }
  return false;
}

export function getRediscoverWaypoints(sectionStart, sectionEnd, count = 1) {
  let angle = getAngle(sectionStart, sectionEnd);
  if (angle >= 0) {
    angle += 180;
  }
  const waypoint1 = getDestinationLatLong(
    sectionStart[1], // latitude
    sectionStart[0], // longitude
    angle + 45,
    count,
  );
  const waypoint2 = getDestinationLatLong(
    sectionStart[1], // latitude
    sectionStart[0], // longitude
    angle - 45,
    count,
  );
  const waypoint3 = getDestinationLatLong(
    sectionStart[1], // latitude
    sectionStart[0], // longitude
    angle + 225,
    count,
  );
  const waypoint4 = getDestinationLatLong(
    sectionStart[1], // latitude
    sectionStart[0], // longitude
    angle - 225,
    count,
  );
  const waypoint5 = getDestinationLatLong(
    sectionStart[1], // latitude
    sectionStart[0], // longitude
    angle,
    count,
  );
  const waypoint6 = getDestinationLatLong(
    sectionStart[1], // latitude
    sectionStart[0], // longitude
    angle + 90,
    count,
  );
  const waypoint7 = getDestinationLatLong(
    sectionStart[1], // latitude
    sectionStart[0], // longitude
    angle - 90,
    count,
  );
  const waypoint8 = getDestinationLatLong(
    sectionStart[1], // latitude
    sectionStart[0], // longitude
    angle - 180,
    count,
  );
  return {
    waypoint1,
    waypoint2,
    waypoint3,
    waypoint4,
    waypoint5,
    waypoint6,
    waypoint7,
    waypoint8,
  };
}

function distanceFromPointToLine(point, line) {
  const [px, py] = point;
  const [[x1, y1], [x2, y2]] = line;

  const A = px - x1;
  const B = py - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const lineLength = C * C + D * D;
  let param = -1;
  if (lineLength !== 0) {
    param = dot / lineLength;
  }

  let xx;
  let yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = px - xx;
  const dy = py - yy;

  return Math.sqrt(dx * dx + dy * dy);
}

// FIXME: custom control
// const locationBtnHtml = "<div></div>";

// const customControl = new window.naver.maps.CustomControl(locationBtnHtml, {
//   position: window.naver.maps.Position.TOP_LEFT,
// });
// let controlMap = null;
// addEventListener(map, "rightclick", () => {
//   controlMap = !controlMap ? map : null;
//   customControl.setMap(controlMap);
// });

let identifyMarker = null;
let focusMarker = null;
let focusInterval;

function makeHelperMarker(map, options) {
  return new window.naver.maps.Marker({
    position: getPosition(0, 0),
    map,
    options,
  });
}

function makeIdentifyMarker(map, iconSize = 100) {
  const content =
    `<img src="/assets/marker/identify.png" alt="" ` +
    `style="margin:0px;padding:0px;border:0px solid transparent;display:block;max-width:none;max-height:none; ` +
    `-webkit-user-select: none; position: absolute; width: ${iconSize}px; height:${iconSize}px; left: ${
      -iconSize / 2
    }px; top: ${-iconSize / 2}px;">`;
  const options = {
    clickable: false,
    icon: {
      content,
      anchor: new window.naver.maps.Point(0, 30),
    },
  };
  return makeHelperMarker(map, options);
}

// make ripple effect circle
function makeFocusEffect(map, iconSize = 100) {
  const content = `<div id="focusCircle" class="circle" 
  style="margin:0px;padding:0px;position: absolute;
        width:${iconSize}px;height:${iconSize}px;border-radius:50%;
        background-color:rgba(0,0,255,0.5);z-index:0;
        top:${-iconSize / 2}px;left:${-iconSize / 2}px;">
</div>`;
  return makeHelperMarker(map, { icon: { content } });
}

// set position and ripple effect
export function setCenterEffect(map, marker) {
  if (!marker?.position) return;

  // const zoom = map.getZoom();
  const { zoom } = mapConfig;
  map?.setZoom(zoom);
  map?.panTo(marker.position, { duration: 10 });

  focusMarker = focusMarker || makeFocusEffect(map);
  const circle = focusMarker.getElement().querySelector("#focusCircle");

  circle.style.transform = `scale(0)`;
  circle.style.opacity = 1;
  circle.style.display = `block`;

  let scale = 0;

  if (focusInterval) {
    clearInterval(focusInterval);
  }
  focusInterval = setInterval(() => {
    if (!marker.getMap()) {
      circle.style.display = `none`;
      clearInterval(focusInterval);
    }
    focusMarker.setPosition(marker.position);
    if (scale > 1.1) {
      scale = 0;
    } else {
      scale += 0.05;
      circle.style.transform = `scale(${scale})`;
      circle.style.opacity = 1 - scale;
      circle.style.zIndex = -1;
    }
  }, 50);
}

export function identifyCircle(map, marker) {
  if (!marker?.position) return;
  const zoom = map.getZoom();
  map.setZoom(zoom);
  identifyMarker = identifyMarker || makeIdentifyMarker(map, 50);
  const position = getPosition(marker.position.lat(), marker.position.lng());
  identifyMarker.setPosition(position);
  identifyMarker.getElement().style.zIndex = -10;
}

export function removeIdentifyCircle() {
  if (identifyMarker) identifyMarker.setMap(null);
  identifyMarker = null;
}

let infoWindow = null;

// content is HTMLElement
export function createPanel(content, map, position, callback = () => {}) {
  if (!infoWindow) {
    infoWindow = new window.naver.maps.InfoWindow();
  }

  infoWindow.setContent(content);
  infoWindow.setPosition(position);

  document.addEventListener("mousedown", () => {
    infoWindow.close();
  });

  infoWindow.open(map);

  const rightClickPanel = document.querySelector("#rightClickPanel");
  const infoWindowContainer = rightClickPanel.parentNode
    .closest("div")
    .parentNode.closest("div")
    .parentNode.closest("div");

  const containerHeight = infoWindowContainer.offsetHeight;

  infoWindow.setOptions({
    pixelOffset: new window.naver.maps.Point(110, containerHeight - 10),
  });

  const infoWindowItem = infoWindowContainer.children;

  infoWindowContainer.style.borderRadius = "8px";
  infoWindowContainer.style.backgroundColor = "#f5f5f5";
  infoWindowContainer.style.border = "1px solid black";

  const anchorBlack = infoWindowItem[1];
  anchorBlack.style.bottom = `${containerHeight - 47}px`;
  anchorBlack.style.left = "-23px";
  anchorBlack.style.borderWidth = "10px 24px 10px 0px";
  anchorBlack.style.borderColor =
    "transparent rgb(52, 51, 51) transparent transparent";

  const anchorWhite = infoWindowItem[2];
  anchorWhite.style.bottom = `${containerHeight - 47}px`;
  anchorWhite.style.left = "-20px";
  anchorWhite.style.borderWidth = "10px 24px 10px 0px";
  anchorWhite.style.borderColor = "transparent #f5f5f5 transparent transparent";

  const itemList = document.querySelectorAll("ul > li");
  itemList.forEach(item => {
    item.addEventListener(
      "click",
      () => {
        const value = item.querySelector("div").getAttribute("value");
        const pos = { x: position.x, y: position.y };
        callback(value, pos);
        infoWindow.close();
      },
      { once: true },
    );
  });
}

export function checkOnMap(map, marker) {
  const mapBounds = map.getBounds();
  const markerPosition = marker.getPosition();
  if (mapBounds.hasLatLng(markerPosition)) {
    return true;
  }
  return false;
}

export function moveMarkerOnEdge(map, marker) {
  const mapBounds = map.getBounds();
  const markerPosition = marker.getPosition();
  const mapBoundsNE = mapBounds.getNE();
  const mapBoundsSW = mapBounds.getSW();

  const newMarkerPosition = new window.naver.maps.LatLng(
    Math.max(
      mapBoundsSW.lat(),
      Math.min(mapBoundsNE.lat(), markerPosition.lat()),
    ),
    Math.max(
      mapBoundsSW.lng(),
      Math.min(mapBoundsNE.lng(), markerPosition.lng()),
    ),
  );
  marker.setPosition(newMarkerPosition);
}

export function playWarningSound() {
  const audio = new Audio("/assets/sound/warning.wav");
  audio.play();
}

export function focusTimer(timer, isFocusVehicleRef) {
  if (timer) {
    clearTimeout(timer);
  }
  return setTimeout(() => {
    isFocusVehicleRef.current = true;
  }, 3000);
}

export function paintBlueSelectedVehicle(path) {
  path?.paths?.polylines?.forEach(poly => {
    poly.polyline.setOptions({
      strokeOpacity: 1,
      zIndex: 3,
      strokeColor: "#007aff",
    });
    poly.borderline.setOptions({
      strokeOpacity: 1,
      zIndex: 2,
      strokeColor: "black",
    });
  });
}

export function paintGrayUnSelectedVehicle(path) {
  path?.paths?.polylines?.forEach(poly => {
    poly.polyline.setOptions({
      strokeOpacity: 0.2,
      zIndex: 1,
      strokeColor: "gray",
    });
    poly.borderline.setOptions({
      strokeOpacity: 0.2,
      zIndex: 1,
      strokeColor: "gray",
    });
  });
}

export function paintAllVehiclePath(paths, focusId, vehicle) {
  Object.values(paths).forEach(item => {
    if (item.id === focusId || (!focusId && item.id === vehicle.id)) {
      // if (!item.paths?.polylines) {
      //   return;
      // }
      paintBlueSelectedVehicle(item);
    } else {
      // if (!item.paths.polylines) {
      //   return;
      // }
      paintGrayUnSelectedVehicle(item);
    }
  });
}

export function isAdmin(vehicleId) {
  return vehicleId === "admin";
}

export function createWaypointMarker(map, waypoint) {
  const position = new window.naver.maps.LatLng({
    lat: waypoint.latitude,
    lng: waypoint.longitude,
  });
  const marker = new window.naver.maps.Marker({
    position,
    map,
  });
  return marker;
}

export function deleteWaypointMarker(marker) {
  marker.setMap(null);
}
