/* eslint-disable no-empty-function */
/* eslint-disable no-unused-vars */
import { instance as apiClient } from "apiClient";

const initialState = {
  blockers: [],
  blocker: null,
};

export default {
  state: initialState,
  reducers: {
    setBlockers(state, payload) {
      state.blockers = payload;
    },
    setBlocker(state, payload) {
      state.blocker = payload;
    },
  },
  effects: dispatch => ({
    async getBlockers(payload) {
      const { data } = await apiClient.get(
        `/api/v1/blockers/${payload.accidentId}`,
      );
      this.setBlockers(data);
    },
    async getBlocker(payload) {
      const { data } = await apiClient.get(
        `/api/v1/blockers/${payload.accidentId}/${payload.blockerId}`,
      );
      this.setBlocker(data);
      return data;
    },
    async createBlocker(payload) {
      const response = await apiClient.post(
        `/api/v1/blockers/${payload.accidentId}`,
        payload,
      );
      await this.getBlockers({ accidentId: payload.accidentId });
      return response;
    },
    async updateBlocker(payload) {
      const { data } = await apiClient.put(
        `/api/v1/blockers/${payload.accidentId}`,
        payload.item,
      );
      this.getBlockers({ accidentId: payload.accidentId });
      this.getBlocker({
        accidentId: payload.accidentId,
        blockerId: payload.item.id,
      });
    },
    async deleteBlocker(payload) {
      const deletedBlocker = { ...payload.blocker };
      deletedBlocker.deleteVehicleNumber = payload.vehicleId;
      deletedBlocker.deletedAt = new Date().getTime();
      await apiClient.put(
        `/api/v1/blockers/${payload.accidentId}`,
        deletedBlocker,
      );
      dispatch.location.deleteMarker(payload.blocker.id);
      this.getBlockers({ accidentId: payload.accidentId });
    },
  }),
};
