import React from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import HotKeysHelper from "./HotKeysHelper";

function Tab() {
  const classes = useStyles();
  const location = useLocation();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { isOpen: helperOpen, hotkeys } = useSelector(state => state.hotkey);
  const { isDepart } = useSelector(state => state.vehicle);

  const { pathname } = location;
  const currentPath = pathname.replace("/", "").split("/")[0];

  const getClassName = type =>
    `${classes.menuItem} ${currentPath === type && classes.menuSelected}`;

  const handleClickMenu = path => {
    if (isDepart) {
      navigator(path);
    }
  };

  // hotkeys
  useHotkeys("1", () => handleClickMenu("/participant"), {
    scopes: ["mainPageTabs"],
  });

  useHotkeys("2", () => handleClickMenu("/directions"), {
    scopes: ["mainPageTabs"],
  });

  useHotkeys("3", () => handleClickMenu("/videostream"), {
    scopes: ["mainPageTabs"],
  });

  useHotkeys(
    "h",
    () => {
      if (helperOpen) {
        return dispatch.hotkey.setIsOpen(false);
      }
      return dispatch.hotkey.setIsOpen(true);
    },
    {
      scopes: ["mainPageTabs"],
    },
  );

  return (
    <Box className={classes.tabs}>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.menu}
      >
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            className={getClassName("participant")}
            onClick={() => handleClickMenu("/participant")}
          >
            재난 정보
          </Button>
        </Grid>
        {/* <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            className={getClassName("directions")}
            onClick={() => handleClickMenu("/directions")}
          >
            길찾기
          </Button>
        </Grid> */}
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            className={getClassName("videostream")}
            onClick={() => handleClickMenu("/videostream")}
          >
            차량 영상
          </Button>
        </Grid>
      </Grid>
      <HotKeysHelper
        isOpen={helperOpen}
        onClose={() => dispatch.hotkey.setIsOpen(false)}
        keyMaps={hotkeys}
      />
    </Box>
  );
}

const useStyles = makeStyles({
  tabs: {
    borderLeft: "1px solid #ddd",
    position: "absolute",
    left: "0",
    flexDirection: "column",
    width: "24.5rem",
    height: "3.25rem",
    backgroundColor: "#7388FF",
    boxSizing: "border-box",
  },
  menu: {
    textAlign: "center",
    height: "100%",
  },
  menuItem: {
    width: "95%",
    height: "100%",
    fontSize: "1rem",
    color: "white",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  menuSelected: {
    backgroundColor: "#3f51b5",
  },
});

export default Tab;
